import React, { Component } from 'react';
import $ from 'jquery';
import './Design.css';
// import {NavLink} from 'react-router-dom';
import { Link } from 'react-router-dom';
// import Recommendation from 'components/recommendation/recommendation';
import EnquiryForm from 'components/forms/EnquiryForm/EnquiryForm';



class Design extends Component {
  constructor(props){
    super(props);
    document.title = "Dwight | Design Service";
  }

  componentDidMount(){
   $('#lightSlider').lightSlider({
      gallery: true,
      item: 1,
      loop:true,
      slideMargin: 0,
      thumbItem: 9
  });
  }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
        <section>
   <div className="container mt-4 mb-3">
      <div className="row">
         <nav aria-label="breadcrumb ">
            <ol className="breadcrumb bg-transparent">
               <li className="breadcrumb-item"><Link to="/">Home</Link></li>
               <li className="breadcrumb-item active" aria-current="page">Enquire Details</li>
            </ol>
         </nav>
      </div>
      <div className="row no-gutters">
         <div className="col-md-5 pr-2">
            <div className="card">
               <div className="demo">
                  <div className="lSSlideOuter ">
                     <div className="lSSlideWrapper usingCss">
                        <ul id="lightSlider" className="lightSlider lsGrab lSSlide lsGrabElementEffc">
                           <li data-thumb="https://i.imgur.com/c9uUysL.jpg" className="clone left sliderImage"> <img src="https://i.imgur.com/c9uUysL.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/KZpuufK.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/KZpuufK.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/GwiUmQA.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/GwiUmQA.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/DhKkTrG.jpg" className="lslide active sliderImage"> <img src="https://i.imgur.com/DhKkTrG.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/kYWqL7k.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/kYWqL7k.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/c9uUysL.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/c9uUysL.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/KZpuufK.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/KZpuufK.jpg" className="w-100"  alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/GwiUmQA.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/GwiUmQA.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/DhKkTrG.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/DhKkTrG.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/kYWqL7k.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/kYWqL7k.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/c9uUysL.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/c9uUysL.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/KZpuufK.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/KZpuufK.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/GwiUmQA.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/GwiUmQA.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/DhKkTrG.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/DhKkTrG.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/kYWqL7k.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/kYWqL7k.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/c9uUysL.jpg" className="lslide sliderImage"> <img src="https://i.imgur.com/c9uUysL.jpg" className="w-100" alt="img" /> </li>
                           <li data-thumb="https://i.imgur.com/KZpuufK.jpg" className="clone right sliderImage"> <img src="https://i.imgur.com/KZpuufK.jpg" className="w-100" alt="img" /> </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="col-md-7">
            <div className="card p-4">
               <div className="about d-flex justify-content-between">
               <span className="font-weight-bold">Metal Graphite Blank</span>
               <span className="font-weight-bold">$700</span>
               </div>
               <hr />
               <div className="product-description">
                  <div className="mt-2">
                     <span className="font-weight-bold">Features</span>
                     <p>The minimalist collaboration features chairs, lightening, Shelves, Sofas, Desks and various home acod!</p>
                  </div>
                  <div className="mt-4 mb-2"> <span className="font-weight-bold">Enter Inquiry Details</span> </div>
               </div>
               <EnquiryForm/>

            </div>
         </div>
      </div>
      {/* <Recommendation/> */}
   </div>
</section>
    );
  }
}

export default Design;