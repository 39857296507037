import config from "./config";

export const contactService = {
    contact
};

function contact(contact) {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(contact),
  };

  return fetch(`${config.apiUrl}/website/contact-enquiry/`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem("user", JSON.stringify(user));

      return response;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        window.location.reload(true);
      }

      if (response.status === 400) {
        // auto logout if 401 response returned from api
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
