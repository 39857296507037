import { cartConstants, shopConstants } from './../constants';
import { cartService, shopService } from './../../services';
export const shopActions = {
    getCategory,
    getAllCategories,
    getCategoryProducts,
    addProductToCart
}

function getCategory(type) {
    return (dispatch) => {
        dispatch(request(type));
        shopService.getCategory(type).then(
            (categories) => {
                dispatch(success(categories));
            },
            (error) => {
                dispatch(failure(error.toString()));
            })
    }
    function request() { return { type: shopConstants.SHOP_SINGLE_CATEGORY_REQUEST } }
    function success(categories) { return { type: shopConstants.SHOP_SINGLE_CATEGORY_SUCCESS, categories } }
    function failure(error) { return { type: shopConstants.SHOP_SINGLE_CATEGORY_FAILURE, error } }
}

function getAllCategories() {
    return (dispatch) => {
        dispatch(request());
        shopService.getAllCategories().then(
            (categories) => {
                dispatch(success(categories));
            },
            (error) => {
                dispatch(failure(error.toString()));
            })
    }
    function request() { return { type: shopConstants.CATEGORY_REQUEST } }
    function success(categories) { return { type: shopConstants.CATEGORY_SUCCESS, categories } }
    function failure(error) { return { type: shopConstants.CATEGORY_FAILURE, error } }
}

function getCategoryProducts(categoryId) {
    return (dispatch) => {
        dispatch(request({ categoryId }));
        shopService.getCustomerProducts(categoryId)
            .then((categoryProducts) => {
                dispatch(success(categoryProducts, categoryId));
            }, (error) => {
                dispatch(failure(error.toString()));
            })

    }
    function request(categoryId) { return { type: shopConstants.SHOP_CATEGORY_PRODUCTS_REQUEST, categoryId } }
    function success(categoryProducts, categoryId) {

        return { type: shopConstants.SHOP_CATEGORY_PRODUCTS_SUCCESS, categoryProducts: categoryProducts, categoryId: categoryId }
    }
    function failure(error) { return { type: shopConstants.SHOP_CATEGORY_PRODUCTS_FAILURE, error } }
}


function addProductToCart(slug, variations, quantity = 1) {
    return (dispatch) => {
        dispatch(request({ slug, variations, quantity }));

        cartService.addToCart(slug, variations, quantity)
            // shopService.getCustomerProducts(categoryId)
            .then((categoryProducts) => {
                dispatch(success(categoryProducts));
            }, (error) => {
                dispatch(failure(error.toString()));
            })


    }
    function request(categoryId) { return { type: cartConstants.ADD_TO_CART_REQUEST, categoryId } }
    function success(categoryProducts) { return { type: cartConstants.ADD_TO_CART_SUCCESS, categoryProducts } }
    function failure(error) { return { type: cartConstants.ADD_TO_CART_FAILURE, error } }
}