import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { forwardRef, useImperativeHandle } from 'react';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const MyBackdrop = forwardRef((props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    // const handleToggle = () => {
    //     setOpen(!open);
    // };

    useImperativeHandle(ref, () => ({

        handleToggle() {
            setOpen(!open);
        }
    }));

    return (
        <div>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit" />
                <p><strong>Initiating Payment...</strong></p>
            </Backdrop>
        </div>
    );
})

export default MyBackdrop;