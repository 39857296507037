import React, { Component } from "react";
import * as $ from "jquery";
import 'jquery-ui-bundle';
import "./ProductDetails.css";
// import Recommendation from "components/recommendation/recommendation";
import { productActions } from "store/actions";
import { connect } from "react-redux";
import EnquiryForm from "components/forms/EnquiryForm/EnquiryForm";
import { Link } from 'react-router-dom';

import InquiryModal from "./../Modal/InquiryModal";
import config from "services/config";
import { Modal, Tab, Tabs } from "react-bootstrap";
import AppContext from "contexts/AppContext";
import Loader from "layout/Loader/Loader";
import CustomSlider from "pages/CustomSlider/CustomSlider";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    document.title = "Realtime-7 | Design Service";

    this.state = {
      show: false,
      pageData: this.props.productDetails,
      selectOptions: [],
      selectedItem: {
        price: 0,
        material: '',
        materialId: '',
        size: '',
        sizeId: ''
      }
    };
    this.calledFor = "default";
    this.variant = []
    this.materialVariant = [];
    this.sizeVariant = [];
    this.defaultSelectValues = [];
    this.elements = [];
    this.productId = this.props.match.params.pid;
    this.categoryId = this.props.match.params.cid;
    
    this.setBreadcrumb();

    //console.log(this.props)

    this.pid = this.props.productDetails.id;

    this.props.dispatch(productActions.getProductDetails(this.productId));
  }
  setBreadcrumb = () => {
    let breadcrumb = {
      level1: 'Home',
      level3: this.categoryId.charAt(0).toUpperCase() + this.categoryId.toLowerCase().slice(1),
      level4: this.props.productDetails?.items?.name,
    }
  
    const cacheBuster = Math.floor(Math.random() * 100000); // generate random number
    const breadcrumbKey = `breadcrumb_${cacheBuster}`; // append random number to breadcrumb key
    localStorage.setItem(breadcrumbKey, JSON.stringify(breadcrumb)); // set breadcrumb with cache buster key
  }
  

  animateToCartItem = () => {
    var cart = $(".itemsCount");
    var imgtodrag = $(".lsGrabElementEffc").find("img").eq(0);
    // console.log(cart)
    // console.log(imgtodrag)

    if (imgtodrag) {
      //console.log(imgtodrag.offset());
      var imgclone = imgtodrag
        .clone()
        .offset({
          top: imgtodrag.offset() ? imgtodrag.offset().top : 0,
          left: imgtodrag.offset() ? imgtodrag.offset().left : 0,
        })
        .css({
          opacity: "0.5",
          position: "absolute",
          "max-height": "150px",
          "max-width": "150px",
          "z-index": "100",
        })
        .appendTo($("body"))
        .animate(
          {
            top: cart.offset().top + 10,
            left: cart.offset().left + 10,
            "max-width": "75px",
            "max-height": "75px",
          },
          1000,
          "easeInOutExpo"
        );

      setTimeout(function () {
        cart.effect(
          "shake",
          {
            times: 2,
          },
          200
        );
      }, 1500);

      imgclone.animate(
        {
          width: 0,
          "min-height": 0,
        },
        function () {
          $(this).detach();
        }
      );
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    const thisRef = this;
    $(".btn-addToCart").on("click", function () {
      thisRef.animateToCartItem();
    });
    this.handleInitialStateForSelectedItem();
  }

  handleInitialStateForSelectedItem = () => {
    if (this.props.productDetails && this.props.productDetails.items && this.calledFor !== "cart") {

      this.showProductPrice(this.props.productDetails);
    }
    // if (this.props.productDetails && this.props.productDetails.items && this.calledFor !== "cart") {

    //   //alert(this.props.productDetails.items.unitPrice)
    //   this.setState({
    //     selectedItem: {
    //       ...this.state.selectedItem,
    //       price: this.props.productDetails.items.unitPrice,
    //     }
    //   })
    //   if (this.defaultSelectValues.length > 0) {
    //     //alert(this.defaultSelectValues[0].price)
    //     this.setState({
    //       selectedItem: {
    //         ...this.state.selectedItem,
    //         price: this.defaultSelectValues[0].price,
    //         material: this.defaultSelectValues[0].material.material_name,
    //         materialId: this.defaultSelectValues[0].material.id,
    //         size: this.defaultSelectValues[0].size.size_name,
    //         sizeId: this.defaultSelectValues[0].size.id,
    //       }
    //     })
    //   }
    // }
  }

  showModal = (e) => {
    this.setState({
      show: !this.state.show,
    });
  };

  renderSize = (productDetails) => {
    const sizes = [...new Set(productDetails.items.variations)].map((item, index) => {
      return (<>
        <span key={index} className="size btn-info p-2 mr-2">{item.size}</span>
      </>)
    })
    return (<>{sizes}</>);
  }

  forceDownload = (blob, filename) => {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
  
  // Current blob size limit is around 500MB for browsers
  downloadResource = (e, url, filename) => {
    e.preventDefault();
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url, {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
      })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, filename);
      })
      .catch(e => console.error(e));
  }

  productDescriptions = (productDetails) => {
    if (productDetails.items.name) {
      this.setBreadcrumb();
      return (
        <>
          {/* <div className="productDescription pl-3 my-4">
            <ul className="p-0">
              <li>
                Stock{" "}
                <span className="inStock">
                  {productDetails.items.unitsInStock !== 0 && `In stock`}
                  {productDetails.items.unitsInStock === 0 && `Out of stock`}
                </span>
              </li>
              {productDetails.items.variations && productDetails.items.variations.length > 0 && <li><span> Material : {productDetails.items.variations[0].material}</span></li>}
              {productDetails.items.variations && productDetails.items.variations.length > 0 && <li className="mt-2"><span> Size : {this.renderSize(productDetails)}</span></li>}
            </ul>
          </div> */}
          <Tabs defaultActiveKey="description" id="uncontrolled-tab-example" className="mt-3">
            <Tab eventKey="description" title="Description">
              <div className="py-3">
                <div dangerouslySetInnerHTML={{ __html: productDetails.items.description }} />
              </div>
            </Tab>
              {productDetails.items?.videos?.some(v => v.youtube_video_url) && (
              <Tab eventKey="videos" title="Videos">
              <div className="py-3">
               {productDetails.items.videos.map(v => (
                v.youtube_video_url && (
             <iframe width="420" height="315" className="ml-3" src={v.youtube_video_url}></iframe>
              )
            ))}
           </div>
          </Tab>
           )}


{productDetails.items?.files && productDetails.items.files.length > 0 && (
  <Tab eventKey="file_library" title="File Library">
    <div className="row py-3">
      {productDetails.items.files.map(a => (
        <div className="col-lg-3">
          <div className="inner border p-2">
            <div className="docPreview">
              {a.file_path.endsWith('.pdf') && (
                <img 
                  height="250"
                  src="https://dummyimage.com/400x400/e6e8ed/414147.jpg&text=PDF"
                  alt="img" 
                  className="w-100"
                />
              )}
              {a.file_path.endsWith('.png') && (
                <img 
                  height="250"
                  src={config.apiUrl + a.file_path} 
                  alt="img" 
                  className="w-100"
                />
              )}
              {a.file_path.endsWith('.doc') && (
                <img 
                  height="250"
                  src="https://dummyimage.com/400x400/e6e8ed/414147.jpg&text=DOC"
                  alt="img" 
                  className="w-100"
                />
              )}
               {!a.file_path.endsWith('.pdf') && !a.file_path.endsWith('.png') && !a.file_path.endsWith('.doc') && (
              <img 
                height="250"
                src="https://dummyimage.com/400x400/e6e8ed/414147.jpg&text=FILE"
                alt="img" 
                className="w-100"
              />
            )}
              <div className="dxfFileName">{a.name ?? 'filename.dxf'}</div>
            </div>
            <div className="downloadView mt-2">
              <a className="btn btn-secondary w-100" href="#" onClick={e => this.downloadResource(e, config.apiUrl + a.file_path, a.name ?? 'file')}>
                <i className="fa fa-download mr-2" />
                Download
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  </Tab>
)}



            {/* {productDetails.items.documents && productDetails.items.documents.length > 0 &&
              <Tab eventKey="documentation" title="Documentation">
                <div className="row py-3">
                  {productDetails.items.documents.map(a => (
                    <div className="col-lg-3">
                      <div className="inner border p-2">
                        <div className="docPreview">
                          <img
                            height="250"
                            src="https://dummyimage.com/400x400/e6e8ed/414147.jpg&text=PDF"
                            alt="img"
                            className="w-100"
                          />
                          <div className="dxfFileName">{a.name ?? 'File'}</div>
                        </div>
                        <div className="downloadView mt-2">
                          <a className="btn btn-secondary w-100" href="#" onClick={e => this.downloadResource(e, config.apiUrl + a.file_path, a.name ?? 'document')}>
                            <i className="fa fa-download mr-2" />
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab>
            } */}
            {productDetails.items.documents && productDetails.items.documents.length > 0 &&
  <Tab eventKey="documentation" title="Documentation">
    <div className="row py-3">
      {productDetails.items.documents.map(a => (
        <div className="col-lg-3">
          <div className="inner border p-2">
            <div className="docPreview">
              {a.file_path.endsWith('.pdf') && (
                <img 
                  height="250"
                  src="https://dummyimage.com/400x400/e6e8ed/414147.jpg&text=PDF"
                  alt="img" 
                  className="w-100"
                />
              )}
              {a.file_path.endsWith('.png') && (
                <img 
                  height="250"
                  src={config.apiUrl + a.file_path} 
                  alt="img" 
                  className="w-100"
                />
              )}
              {a.file_path.endsWith('.doc') && (
                <img 
                  height="250"
                  src="https://dummyimage.com/400x400/e6e8ed/414147.jpg&text=DOC"
                  alt="img" 
                  className="w-100"
                />
              )}
              {!a.file_path.endsWith('.pdf') && !a.file_path.endsWith('.png') && !a.file_path.endsWith('.doc') && (
              <img 
                height="250"
                src="https://dummyimage.com/400x400/e6e8ed/414147.jpg&text=FILE"
                alt="img" 
                className="w-100"
              />
            )}
              <div className="dxfFileName">{a.name ?? 'filename.dxf'}</div>
            </div>
            <div className="downloadView mt-2">
              <a className="btn btn-secondary w-100" href="#" onClick={e => this.downloadResource(e, config.apiUrl + a.file_path, a.name ?? 'file')}>
                <i className="fa fa-download mr-2" />
                Download
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  </Tab>
    }





          </Tabs>
        </>
      );
    }
  };

  materialSizePrice(productDetails) {
    this.pid = productDetails.items.id;
    if (productDetails.items.variations && productDetails.items.variations.length > 0) {

      productDetails.items.variations.forEach(item => {

        this.variant.push(item);
        this.materialVariant.push({
          id: item.material.id,
          name: item.material.material_name,
        });
        this.sizeVariant.push({
          id: item.size.id,
          name: item.size.size_name,
        });
        if (item.default_material) {
          this.defaultSelectValues.push(item);
        }
      });

      //Remove duplicate materials if any
      this.materialVariant = this.materialVariant.filter((material, index) => {
        const _material = JSON.stringify(material);
        return index === this.materialVariant.findIndex(obj => {
          return JSON.stringify(obj) === _material;
        });
      });

      //Remove duplicate sizes if any
      this.sizeVariant = this.sizeVariant.filter((size, index) => {
        const _size = JSON.stringify(size);
        return index === this.sizeVariant.findIndex(obj => {
          return JSON.stringify(obj) === _size;
        });
      });

    }
  }

  flyToCart = () => {
    if (this.calledFor === 'default') {
      this.animateToCartItem();
    }
    this.calledFor = "cart";
    this.componentDidMount();
  }

  onItemSelect = (e) => {
    const { name, value } = e.currentTarget;
    console.log(`name ${name} : ${value}`);
    let splitArray = value.split(",");
    const material = splitArray[0];
    const size = splitArray[1];
    const price = splitArray[2];

    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        price,
        material,
        materialId,
        size,
        sizeId
      }
    })
  }

  handleSizeChange = (event) => {

    let splitArray = event.target.value.split(",");
    const size_id = splitArray[0];
    const size_name = splitArray[1];

    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        size: size_name,
        sizeId: size_id
      }
    })
    // alert(`id :- ${size_id} name:- ${size_name} productId:${productId}`);
    this.getProductAvailability(this.pid, size_id, this.state.selectedItem.materialId);
  }

  handleMaterialChange = (event) => {
    let splitArray = event.target.value.split(",");
    const material_id = splitArray[0];
    const material_name = splitArray[1];

    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        material: material_name,
        materialId: material_id
      }
    })
    //alert(`id :- ${material_id} name:- ${material_name} productId:${productId}`);
    this.getProductAvailability(this.pid, this.state.selectedItem.sizeId, material_id)
  }

  getProductAvailability = async (pid, sizeId, materialId) => {
    const url = `${config.apiUrl}/customer/get_product_variation/${pid}/?size_id=${sizeId}&material_id=${materialId}`;

    const result = await fetch(url);
    const response = await result.json();
    if (response.length > 0 && response[0].variant_Stock > 0) {
      this.setState({
        selectedItem: {
          ...this.state.selectedItem,
          price: response[0].price,
        }
      })
    }
  }

  closeModal = () => {
    this.setState({ show: false })
  }

  showProductPrice = (productDetails) => {
    if (productDetails.items.variation_stat === true) {
      let default_material = productDetails.items.variations.filter((item, index) => {
        if (item.default_material === true) {
          return item;
        }
      })
      this.setState({
        selectedItem: {
          ...this.state.selectedItem,
          material: default_material? default_material[0].material.material_name: "",
          materialId: default_material? default_material[0].material.id: "",
          size: default_material? default_material[0].size.size_name: "",
          sizeId: default_material? default_material[0].size.id: "",
          price: default_material? default_material[0].price: ""
        }
      })
    }
    else {
      this.setState({
        selectedItem: {
          ...this.state.selectedItem,
          price: productDetails.items.unitPrice
        }
      })
    }
  }


  render() {
    if (this.props.productDetails.loading) return (<Loader />)
    const { productDetails } = this.props;

    // if(productDetails.loading === false && productDetails.items && productDetails.items.variations && productDetails.items.variations.length>0){
    //   this.setState({selectOptions:productDetails.items.variations})

    // }
    return (
      <AppContext.Consumer>
        {({ cartItems, setCartItems }) => (
          <section>
            <div className="container mt-4 mb-3">
              {productDetails.items && <>

                <div className="row">
                  {this.materialSizePrice(productDetails)}
                  <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb bg-transparent">

                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>

                      {JSON.parse(localStorage.getItem('breadcrumb')).hasOwnProperty('level2') ?
                        <li className="breadcrumb-item">
                          <Link to={`/shop/${JSON.parse(localStorage.getItem('breadcrumb')).level2.replace(/\s+/g, '-').toLowerCase()}`}>
                            {JSON.parse(localStorage.getItem('breadcrumb')).level2}
                          </Link>
                        </li> : ''}

                      <li className="breadcrumb-item">
                        <Link to={`/shop/${JSON.parse(localStorage.getItem('breadcrumb')).level3.replace(/\s+/g, '-').toLowerCase()}`}>
                          {JSON.parse(localStorage.getItem('breadcrumb')).level3}
                        </Link>
                      </li>


                      {/* <li className="breadcrumb-item">
                        <Link to={`/shop/${JSON.parse(localStorage.getItem('breadcrumb')).parent.replace(/\s+/g, '-').toLowerCase()}`}>
                          {JSON.parse(localStorage.getItem('breadcrumb')).parent}
                        </Link>
                      </li>

                      {JSON.parse(localStorage.getItem('breadcrumb')).hasOwnProperty('level2') ?
                        <li className="breadcrumb-item">
                          <Link to={`/shop/${JSON.parse(localStorage.getItem('breadcrumb')).child.replace(/\s+/g, '-').toLowerCase()}`}>
                            {JSON.parse(localStorage.getItem('breadcrumb')).child}
                          </Link>
                        </li> : ''} */}

                      <li className="breadcrumb-item active" aria-current="page">
                        {JSON.parse(localStorage.getItem('breadcrumb')).level4}
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="row no-gutters">
                  <CustomSlider productData={productDetails} />
                  <div className="col-md-7">
                    <div className="card p-4">
                      <div className="about d-flex justify-content-between">
                        <span className="font-weight-bold">
                          {productDetails.items.name}
                        </span>
                      </div>
                      <hr />

                      <div className="product-description">
                        <div className="prduct-content">
                          {/* <h5 className="mb-3">Features: </h5> */}
                          <h4 className="mb-2">Description</h4>
                          <div dangerouslySetInnerHTML={{ __html: productDetails.items.description }} />
                        </div>
                        <div className="productQtyCartBuy mt-5 row align-items-center">
                          <div className="col-md-4">
                            {/* <input
                              type="number"
                              min="1"
                              className="from-control w-100 qty"
                              placeholder="QTY"
                              defaultValue="1"
                            /> */}

                            {
                              productDetails.items.variations && productDetails.items.variations.length > 0 && this.sizeVariant && this.sizeVariant.length > 0 &&
                              <div className="form-group mb-4">
                                <label>Size</label>

                                <select onChange={this.handleSizeChange} className="form-control" >
                                  {[... new Set(this.sizeVariant)].map((item) =>
                                    <>
                                      <option key={item.size_name} value={`${item.id},${item.name}`}>{item.name}</option>
                                    </>
                                  )}
                                </select>
                              </div>
                            }

                            {
                              productDetails.items.variations && productDetails.items.variations.length > 0 && this.materialVariant && this.materialVariant.length > 0 &&
                              <div className="form-group mb-4">
                                <label>Material</label>
                                <select onChange={this.handleMaterialChange} className="form-control">

                                  {[... new Set(this.materialVariant)].map((item) =>
                                    <option key={item.material_name} value={`${item.id},${item.name}`}>{item.name}</option>
                                  )}
                                </select>
                              </div>
                            }

                            {/* {
                              productDetails.items.variations && productDetails.items.variations.length > 0 && this.materialVariant && this.materialVariant.length > 0 &&

                              <div>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>Material</th>
                                      <th>Size</th>
                                      <th>Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {productDetails.items.variations.map((item) =>
                                      <tr key={item.id} >
                                        <td>
                                          <input type="radio"
                                            value={`${item.material},${item.size},${item.price}`}
                                            name="prod_data"
                                            onChange={this.onItemSelect} />
                                        </td>
                                        <td>{item.material}</td>
                                        <td>{item.size}</td>
                                        <td>{item.price}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                            } */}

                          </div>
                          <div className="col-md-8">
                            <div className="stock-availablity-box d-flex">
                              <div className="stock-price-box">
                                <span className="inStock">
                                  {/* console.log({productDetails.items})
                                  {productDetails.items.unitsInStock !== 0 && `In stock`}
                                  {productDetails.items.unitsInStock === 0 && `Out of stock`} */}

                                
                                {console.log(productDetails.items.videos[1])}
                                {productDetails.items.enquiry_text === "NA" && ``}
                                  {productDetails.items.enquiry_text !== "NA" && productDetails.items.unitsInStock !== 0 && `In stock`}
                                  {productDetails.items.enquiry_text !== "NA" && productDetails.items.unitsInStock === 0 && `Out of stock`}
                                 
                                </span>

                                {productDetails.items.unitPrice ?
                                  <p>Price: <span className="font-weight-bold">
                                    {/* $ {productDetails.items.unitPrice} */}

                                    {/* $ {this.state.selectedItem.price} */}

                                    $ {this.state.selectedItem.price === 0 || productDetails.items.variation_stat === false ? productDetails.items.unitPrice : this.state.selectedItem.price}
                                    <span style={{ display: 'none' }}>
                                      {this.state.selectedItem.price === 0 && this.defaultSelectValues.length > 0 ?
                                        (this.setState({
                                          selectedItem: {
                                            ...this.state.selectedItem,
                                            price: this.defaultSelectValues[0].price,
                                            material: this.defaultSelectValues[0].material.material_name,
                                            materialId: this.defaultSelectValues[0].material.id,
                                            size: this.defaultSelectValues[0].size.size_name,
                                            sizeId: this.defaultSelectValues[0].size.id,
                                          }
                                        })) : ''
                                      }
                                    </span>
                                  </span>
                                  </p> : null}
                              </div>
                              <div className="cart-btn-box">
                                {productDetails.items && productDetails.items.is_shop && productDetails.items.unitPrice &&
                                  <div className="cart-btm">

                                    <button
                                      p-id={productDetails.items.id}
                                      className="btn btn-addToCart btn-info cart"
                                      onClick={(e) => {
                                        this.flyToCart()
                                        let productId = e.target.getAttribute('p-id');
                                        let productExists = false;
                                        let arrIndex = null;
                                        let subTotal = 0;
                                        let total = 0;

                                        // if (cartItems.items && cartItems.items.length > 0) {
                                        //   for (var i = 0; i < cartItems.items.length; i++) {
                                        //     console.log(cartItems.items[i])
                                        //     if (parseInt(cartItems.items[i].item.id) === parseInt(productId)) {
                                        //       productExists = true;
                                        //       arrIndex = i;
                                        //       break;
                                        //     }
                                        //   }
                                        // }

                                        let myItem = {
                                          item: productDetails.items,
                                          count: 1,
                                          productPrice: 0,
                                          isVariation: false,
                                          selectedItem: this.state.selectedItem,
                                          addedFrom: 'productpage'
                                        }

                                        { productDetails.items.variation_stat === true ? myItem.addedFrom = 'productpage' : myItem.addedFrom = '' }

                                        { myItem.addedFrom === '' ? myItem.selectedItem = '' : myItem.selectedItem }

                                        if (cartItems.items && cartItems.items.length > 0) {
                                          for (var i = 0; i < cartItems.items.length; i++) {

                                            let condition1 = ((parseInt(cartItems.items[i].item.id) === parseInt(productId) &&
                                              cartItems.items[i].item.variation_stat === true && cartItems.items[i].selectedItem &&
                                              cartItems.items[i].selectedItem.material === this.state.selectedItem.material &&
                                              cartItems.items[i].selectedItem.size === this.state.selectedItem.size) ? true : false);

                                            let condition2 = ((myItem.addedFrom === '' && (parseInt(cartItems.items[i].item.id) === parseInt(productId)) ? true : false));

                                            if (condition1 || condition2) {
                                              productExists = true;
                                              arrIndex = i;
                                              break;
                                            }
                                          }
                                        }

                                        if (productDetails.items.variation_stat === true && productDetails.items.variations.length > 0 &&
                                          myItem.selectedItem !== '' &&
                                          myItem.selectedItem.material === '' || myItem.selectedItem.size === '' ||
                                          myItem.selectedItem.material === undefined || myItem.selectedItem.size === undefined) {

                                          let default_material = productDetails.items.variations.filter((item, index) => {
                                            if (item.default_material === true) {
                                              return item;
                                            }
                                          })
                                          if (default_material !== undefined && default_material.length > 0) {
                                            myItem.selectedItem.material = default_material[0].material.material_name;
                                            myItem.selectedItem.materialId = default_material[0].material.id;
                                            myItem.selectedItem.size = default_material[0].size.size_name;
                                            myItem.selectedItem.sizeId = default_material[0].size.id;
                                            myItem.selectedItem.price = default_material[0].price;
                                            myItem.productPrice = default_material[0].price;
                                          }
                                        }
                                        if (productDetails.items.variation_stat === true) {
                                          myItem.isVariation = true;
                                        }
                                        if (myItem.productPrice === 0) {
                                          myItem.productPrice = productDetails.items.unitPrice;
                                        }
                                        if (myItem.selectedItem !== '' && myItem.isVariation === true && myItem.selectedItem.price !== 0) {
                                          myItem.productPrice = myItem.selectedItem.price;
                                          //alert(myItem.productPrice);
                                        }

                                        if (cartItems.items && cartItems.items.length > 0) {
                                          if (productExists) {
                                            //cartItems.items.push(myItem)
                                            cartItems.items[arrIndex].count++;
                                          } else {
                                            cartItems.items.push(myItem)
                                          }
                                        } else {
                                          cartItems.items = [];
                                          cartItems.subTotal = 0;
                                          cartItems.total = 0;
                                          cartItems.items.push(myItem)
                                        }

                                        for (var i = 0; i < cartItems.items.length; i++) {

                                          //subTotal += cartItems.items[i].count * cartItems.items[i].item.unitPrice;
                                          // if (cartItems.items[i].addedFrom !== '' && cartItems.items[i].selectedItem)
                                          //   subTotal += cartItems.items[i].count * cartItems.items[i].selectedItem.price;
                                          // else
                                          //   subTotal += cartItems.items[i].count * cartItems.items[i].item.unitPrice;
                                          subTotal += cartItems.items[i].count * cartItems.items[i].item.productPrice;
                                          total = subTotal;
                                          cartItems.subTotal = subTotal;
                                          cartItems.total = total;
                                        }

                                        localStorage.setItem(
                                          "cartItems",
                                          JSON.stringify(cartItems)
                                        );
                                        //console.log(JSON.stringify(cartItems))
                                        //console.log(JSON.stringify(cartItems.items))
                                        setCartItems(cartItems)
                                      }}
                                    >
                                      <i className="fa fa-shopping" /> Add to Cart
                                    </button>
                                  </div>
                                }
                                {productDetails.items && productDetails.items.is_enquiry &&
                                  <div className="inqury-btn mt-2">
                                    <button
                                      className="btn btn-primary btn-long btnBuyNow w-100"
                                      onClick={(e) => {
                                        this.showModal(e);
                                      }}
                                    >
                                      Make Inquiry
                                    </button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <Modal size="lg" centered show={this.state.show} onHide={this.showModal}>
                          <InquiryModal headerText="Enter Inquiry Details" onClose={this.showModal} show={this.state.show}>
                            {/* <div className="mb-3">
                              <h4 className="font-weight-bold">
                                Enter Inquiry Details
                            </h4>
                            </div> */}
                            <EnquiryForm close={this.closeModal} />
                          </InquiryModal>
                        </Modal>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="other-details">
                      {this.productDescriptions(productDetails)}
                    </div>
                  </div>
                </div>
                {/* <Recommendation /> */}
              </>}
            </div>
          </section>
        )
        }
      </AppContext.Consumer>
    );
  }
}

// export default ProductDetails;

const mapStateToProps = (state) => {
  return { productDetails: state.productDetails };
};

export default connect(mapStateToProps)(ProductDetails);