import validator from 'validator';

class ValidateFields {
    /*
     * A method that takes in the email
     * Validates it
     * Returns the response either error or false if there is no error
     */
    validateEmail(email) {
        if (validator.isEmpty(email)) {
            return 'Email is required';
        } else if (!validator.isEmail(email)) {
            return 'Invalid Email';
        }
        return false;
    }

    validatePassword(password) {
        if (validator.isEmpty(password)) {
            return 'Password is required';
        } else if (!validator.isLength(password, { min: 8 })) {
            return 'Password should be minimum 8 characters';
        }
        return false;
    }

    validateName(name) {
        if (validator.isEmpty(name)) {
            return 'Name is required';
        } else if (!validator.isLength(name, { min: 3 })) {
            return 'Name should be minimum 3 characters';
        }
        return false;
    }

    validatePhone(phone) {
        if (validator.isEmpty(phone)) {
            return 'Phone is required';
        } else if (!validator.isLength(phone, { min: 10 })) {
            return 'Phone should be minimum 10 digits';
        } else if (!validator.isLength(phone, { max: 10 })) {
            return 'Phone should not exceed 10 digits';
        }
        return false;
    }

    validateSubject(subject) {
        if (validator.isEmpty(subject)) {
            return 'Subject is required';
        } else if (!validator.isLength(subject, { min: 5 })) {
            return 'Subject should be minimum 5 characters';
        }
        return false;
    }

    validateMessage(message) {
        if (validator.isEmpty(message)) {
            return 'Message is required';
        } else if (!validator.isLength(message, { max: 500 })) {
            return 'Message should not exceed 500 characters';
        }
        return false;
    }
}

const validateFields = new ValidateFields();

// export the class instance, so we can import and use it anywhere
export { validateFields };