import React, { useCallback } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { checkoutAPI } from 'services/checkout.service';

export default function PayPal(props) {
    const [paid, setPaid] = useState(false);
    const [error, setError] = useState(null);

    const paypalRef = React.useRef();

    const getField = useCallback((fieldName) => props.shippingInfo.isBillingAndShippingSame ? props.shippingInfo[fieldName] : props.shippingInfo['billing_'+fieldName], [props.shippingInfo]);

    useEffect(() => {
        const { total, shippingInfo, productInfo } = props;
        const { first_name, last_name, address1, address2, city, country_name, state, zip_code, email, phone_number } = shippingInfo;
        window.paypal
            .Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchase_units: [
                            {
                                description: "Total amount to be paid is only ",
                                amount: {
                                    currency_code: "CAD",
                                    value: props.total,
                                },
                            },
                        ],
                        payer: {
                            name: {
                                given_name: getField('first_name'),
                                surname: getField('last_name'),
                            },
                            address: {
                                address_line_1: getField('address1'),
                                address_line_2: getField('address2'),
                                admin_area_2: getField('city'),
                                admin_area_1: getField('state'),
                                postal_code: getField('zip_code'),
                                country_code: getField('country_name').toLowerCase() === 'canada' ? 'CA' : 'US',
                            },
                            email_address: getField('email'),
                            phone: {
                                phone_type: "MOBILE",
                                phone_number: {
                                    national_number: getField('phone_number')
                                }
                            }
                        }
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log(order);
                    productInfo.paypalTransId = order.id;
                    productInfo.captureId = order.purchase_units?.[0]?.payments?.captures?.[0]?.id ?? '';
                    productInfo.currency = order.purchase_units?.[0]?.payments?.captures?.[0]?.amount?.currency_code ?? '';
                    updateTranction(productInfo);
                },
                onError: (err) => {
                    //localStorage.setItem('paypalResponse', JSON.stringify(err));
                    //localStorage.setItem('paypalTransactionId', 'NA');
                    localStorage.setItem('transaction_id','NA');
                    setError(err),
                        console.error(err);
                },
            })
            .render(paypalRef.current);
    }, []);

    // If the payment has been made
    if (paid) {
        console.log("Payment Completed Successfully.")
        localStorage.removeItem("cartItems");
        window.location.href = "/thankyou";

        // return (
        //     <div>
        //         <h2>Thanks for your order.</h2>
        //         <h4>Your payment of ${props.total} is complete.</h4>
        //     </div>);
    }

    // If any error occurs
    if (error) {
        return <div>Error Occurred in processing payment.! Please try again.</div>;
    }

    const updateTranction = (productInfo) => {
        checkoutAPI.submitPayment(productInfo)
            .then((response) => {
                console.log(response);
                localStorage.setItem('transaction_id',response.transaction_id);
                setPaid(true);
            })
    }

    return (
        <div>
            <h3>REVIEW ORDER</h3>
            <hr />
            <p>You're almost done!</p>
            <p>Total Amount in CAD. : ${props.total} /-</p>
            <div ref={paypalRef} />
        </div>
    )
}