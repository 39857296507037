import config from "./config";
import {authHeader} from './../store/helpers';

export const cartService = {
  addToCart,
  getCartItems,
};

function addToCart(slug,variations,quantity=1) {

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ slug,variations,quantity}),
  };
console.log(requestOptions);
  return fetch(`${config.apiUrl}/customer/add-to-cart/`, requestOptions)
    .then(handleResponse)
    .then((cartId) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // this.getCartItems()

      return cartId;
    });
}

function getCartItems(userId){
    const requestOptions = {
        method: "GET",
        headers : {"Content-Type" : "application/json"},
        body : {userId}
    }

    return fetch(`${config.apiUrl}/customer/cart`,requestOptions)
    .then(handleResponse)
    .then((cart)=>{
        localStorage.setItem("cart",JSON.stringify(cart));
        return cart;
    })
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
console.log('handleeee',data);
    return data;
  });
}
