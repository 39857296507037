import { homeConstants } from './../constants';

export function logo(state = {}, action) {
    switch (action.type) {
        case homeConstants.LOGO_REQUEST:
            return {
                loading: true
            }
        case homeConstants.LOGO_SUCCESS:
            return {
                loading: false,
                items: action.logo.results
            }

        case homeConstants.LOGO__FAILURE:
            return {
                error: action.error
            }
        default:
            return state;
    }

}