import { productConstants } from "../constants";
let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loading:true,loggedIn: true, user } : {};
export function productDetails(state = initialState, action) {
  switch (action.type) {
    case productConstants.PRODUCT_DETAIL_REQUEST:
      return {
        loading: false,
        items: action.products,
      };

      case productConstants.PRODUCT_DETAIL_SUCCESS:
      return {
        loading: false,
        items: action.productData,
      };

      case productConstants.PRODUCT_DETAIL_FAILURE:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
