export const shopConstants = {
    CATEGORY_REQUEST: 'CATEGORY_REQUEST',
    CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',
    CATEGORY_FAILURE: 'CATEGORY_FAILURE',

    SHOP_CATEGORY_PRODUCTS_REQUEST:'SHOP_CATEGORY_PRODUCTS_REQUEST',
    SHOP_CATEGORY_PRODUCTS_SUCCESS:'SHOP_CATEGORY_PRODUCTS_SUCCESS',
    SHOP_CATEGORY_PRODUCTS_FAILURE:'SHOP_CATEGORY_PRODUCTS_FAILURE',

    SHOP_SINGLE_CATEGORY_REQUEST:'SHOP_SINGLE_CATEGORY_REQUEST',
    SHOP_SINGLE_CATEGORY_SUCCESS:'SHOP_SINGLE_CATEGORY_SUCCESS',
    SHOP_SINGLE_CATEGORY_FAILURE:'SHOP_SINGLE_CATEGORY_FAILURE',
};
