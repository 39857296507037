const config = {
     //apiUrl: 'http://django.optimaldevelopments.com',
    // apiUrl: 'http://localhost:8080',
    apiUrl: 'https://admin.realtime-7.com',
    CATEGORY_PARTNERS: 'PS',
    CATEGORY_ENTERPRISE_PRODUCT: 'EP',
    CATEGORY_NON_ENTERPRISE_PRODUCT: 'CP',
    CATEGORY_DESIGN_PRODUCT: 'DS',
    CATEGORY_PARTNERS_TEXT: 'Partners',
    ENTERPRISE_PRODUCT_TEXT: 'Enterprise Products',
    NON_ENTERPRISE_PRODUCT_TEXT: 'Consumer Products',
    DESIGN_PRODUCT_TEXT: 'Services',
    CONTACT_US_SLUG: 'contact-us',
    ABOUT_US_SLUG: 'about-us',
    CONTACT_PAGE_URL: '/contact',
    FOOTER_TYPE_QUICK_LINKS: 'quick_links',
    FOOTER_TYPE_EXPLORE_LINKS: 'explore_links',
    //img_url: 'http://django.optimaldevelopments.com/media/',
    img_url: 'https://admin.realtime-7.com/media/',
    //img_url: 'http://localhost:8080/media/',
    
    cartItem: [
        {
            items: [],
            subTotal: null,
            total: null
        }
    ],
    PAYPAL_SANDBOX:'http://www.paypal.com/sdk/js?client-id=Afk3JDQQ6Njce7LLoJJTBpSOPEdGpP9YaT0cMM7QzV7TZJlQtI-7uqa_hY5qH5D_hnvwV-uIf4OhYBtn&currency=CAD',
    PAYPAL_LIVE:'http://www.paypal.com/sdk/js?client-id=AYQVEcTix-gYzVKdr08JgfAIXPrnYyh5ynzDa8YnyGNldEWDfy6wu7nyT2OXcfI5tfVpm5cVbmVRiDvz&currency=CAD',
    GOOGLE_ANALYTICS: 'UA-199294540-1',
    //GOOGLE_ANALYTICS: 'G-910FZPBFVH',
    PAYPAL: {
        RETURN_URL: 'https://admin.realtime-7.com/thankyou',
        CANCEL_URL: 'https://admin.realtime-7.com/thankyou'
    },

}

export default config;
