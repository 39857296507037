import React, { Component } from "react";
//Component import

//import ImageUploader from 'react-images-upload';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: -50px 0px 0px  110px;
  border-color: red;
`;


import "./Forgotpassword.css";
import { connect } from "react-redux";
import { userActions } from "../../store/actions";
import {FACEBOOK_APP_ID,GOOGLE_APP_ID} from './../../store/constants/index'
class Forgotpassword extends Component {
  constructor(props) {
    super(props);
    document.title = "Realtime-7 | Forgotpassword";
    
    this.state = {
       
          name: "",
          email: "",
          company: "",
          phone_number: "",
          street: "",
          city:"",
          password: "",
          rePassword:"",
          passwordResetSent: false,
       
        
        submitted: false,
      };
    this.nodes = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { email } = this.state;
  
    if (email) {
      this.props.dispatch(userActions.forgotpassword(email));
      this.setState({ passwordResetSent: true });
           toast("Please follow the instructions, we have sent a password reset link to your email.", { position: "top-center" });
           this.props.close();
   
    }
  
    
  }
  
 


  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node;
    }
  }





  render() {
   
    const { email, submitted } = this.state;
    const { payload } = this.props;

    return (
      <section>
      
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-lg-6 border p-4">
              <div className="inner contact-right">
                <h4 className="mb-2">Forgot Password    !</h4>
                <p className="card-text">
                  Enter your email address below and we'll send you a link to reset your password.
                </p>
                <form action="#" method="post" onSubmit={this.handleSubmit} className="signin-form">
                  <div className="input-grids">
               
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email*"
                        className="contact-input"
                        required=""
                        onChange={(e) => this.handleChange(e)}
                      />
                      {submitted && !email &&
                    <div className="help-block">Please enter a valid email address</div>
                        }
                       {payload && <div className="alert alert-success">{payload}</div>}


                    </div>
                  
                  </div>
                 

                 
                    
                  <div className="text-center">
                    <button
                      className="btn btn-primary theme-button"
                      onClick={this.handleSubmit}
                    >
                      Reset Link
                    </button>
                  </div>
                 


                </form>
             
        
      
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner ">
                <img
                  src="assets/images/signup-bg.png"
                  alt=""
                  className="w-100"
                />
                <hr />
               
                        
                  
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps=(state)=>{
  return { user:state.user ,
    forgotPasswordResult: state.user.forgotPasswordResult,};
   

}

export default connect(mapStateToProps)(Forgotpassword);
