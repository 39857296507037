import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { Component } from 'react';
import Countries from './Countries';
import ReactGA from 'react-ga';
class Shipping extends Component {

    constructor(props) {
        super(props);

        this.sumbitHandler = this.sumbitHandler.bind(this);
    }

    sumbitHandler = (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'User',
            action: 'Clicked on "Continue to Shipping" button'
          });
        this.props.next(e);
    }

    render() {
        return (
            <>
                {/* <div className="form-card">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label class="col-md-4 col-form-label">First Name</label>
                                <div className="col-md-8">
                                    <input type="text" id="fname" placeholder="John*" className="form-control" value={this.props.fname}
                                        onChange={this.props.onChange} required />
                                    <span className="pull-right text-danger"><small>{this.props.fnameError}</small></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label class="col-md-4 col-form-label">Last Name</label>
                                <div className="col-md-8">
                                    <input type="text" id="lname" placeholder="M. Doe*" className="form-control" value={this.props.lname}
                                        onChange={this.props.onChange} required />
                                    <span className="pull-right text-danger"><small>{this.props.lnameError}</small></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label class="col-md-2 col-form-label">Address</label>
                        <div className="col-md-10">
                            <input type="text" placeholder="542 W. 15th Street" className="form-control" id="address" value={this.props.address}
                                onChange={this.props.onChange} required />
                            <span className="pull-right text-danger"><small>{this.props.addressError}</small></span>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label class="col-md-2 col-form-label">Country</label>
                        <div className="col-md-10">
                            <Countries />
                            <span className="pull-right text-danger"><small>{this.props.countryError}</small></span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label class="col-md-3 col-form-label">State</label>
                                <div className="col-md-9">
                                    <input type="text" id="state" placeholder="California" className="form-control" value={this.props.state}
                                        onChange={this.props.onChange} required />
                                    <span className="pull-right text-danger"><small>{this.props.stateError}</small></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group row">
                                <label class="col-md-3 col-form-label">City</label>
                                <div className="col-md-9">
                                    <input type="text" id="city" placeholder="City*" className="form-control" value={this.props.city}
                                        onChange={this.props.onChange} required />
                                    <span className="pull-right text-danger"><small>{this.props.cityError}</small></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group row">
                                <label class="col-md-3 col-form-label">Zip</label>
                                <div className="col-md-9">
                                    <input type="text" placeholder="10001" className="form-control" id="zip" value={this.props.zip}
                                        onChange={this.props.onChange} required />
                                    <span className="pull-right text-danger"><small>{this.props.zipError}</small></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary pull-right"
                            onClick={this.props.next}>Next</button>
                    </div>
                </div> */}


                <div className="shipping-detail-box">

                    <form onSubmit={this.sumbitHandler} className="needs-validation" novalidate>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label >Country/Region *</label>
                                <select onChange={this.props.onCountryChange} className="custom-select d-block w-100" id={`${this.props.prefix+"country"}`} required>
                                    <option selected value="" disabled>Please Select</option>
                                    {[... new Set(this.props.countries)].map((country) =>
                                        <>
                                            <option key={country.id} value={country.name}>{country.name}</option>
                                        </>
                                    )}
                                </select>
                                <div className="invalid-feedback">
                                    Please select a valid country.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label >Order Type *</label>
                                <select onChange={this.props.onOrderTypeChange} id={`${this.props.prefix+"order"}`} className="custom-select d-block w-100" required>
                                    <option selected value="Company">Company</option>
                                    <option value="Personal">Personal</option>
                                </select>
                                <div className="invalid-feedback">
                                    Please select a valid OrderType.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>First Name *</label>
                                <input type="text" id={`${this.props.prefix+"fname"}`} placeholder=" " className="form-control" value={this.props.fname}
                                    onChange={this.props.onChange} required />
                                <div className="invalid-feedback">
                                    Valid first name is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label >Last Name *</label>
                                <input type="text" id={`${this.props.prefix+"lname"}`} placeholder=" " className="form-control" value={this.props.lname}
                                    onChange={this.props.onChange} required />
                                <div className="invalid-feedback">
                                    Valid last name is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label >Company Name *</label>
                                <input type="text" id={`${this.props.prefix+"companyName"}`} placeholder=" " className="form-control" value={this.props.companyName}
                                    onChange={this.props.onChange} />
                                <div className="invalid-feedback">
                                    Valid last name is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label >Mail Stop</label>
                                <input type="text" id={`${this.props.prefix+"mailStop"}`} placeholder="Eg. Room #101" className="form-control" value={this.props.mailStop}
                                    onChange={this.props.onChange} />
                                {/* <div className="invalid-feedback">
                                    Valid Mail Stop is required.
                                </div> */}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label >Address Line 1 *</label>
                                <input type="text" placeholder=" " className="form-control" id={`${this.props.prefix+"address1"}`} value={this.props.address1}
                                    onChange={this.props.onChange} required />
                                <div className="invalid-feedback">
                                    Please enter your address.
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <label >Address Line 2<span className="text-muted">(Optional)</span></label>
                                <input type="text" placeholder=" " className="form-control" id={`${this.props.prefix+"address2"}`} value={this.props.address2}
                                    onChange={this.props.onChange} />
                                <div className="invalid-feedback">
                                    Please enter your address.
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label >City *</label>
                                <input type="text" placeholder=" " className="form-control" id={`${this.props.prefix+"city"}`} value={this.props.city}
                                    onChange={this.props.onChange} required />
                                <div className="invalid-feedback">
                                    City is required.
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label >State/Province *</label>
                                {/*<select onChange={this.props.onStateChange} className="custom-select d-block w-100" id="state" required>
                                    <option selected value="" disabled>Please Select</option>
                                    {[... new Set(this.props.states)].map((state) =>
                                        <>
                                            <option key={state.id} value={state.name}>{state.name}</option>
                                        </>
                                    )}
                                    </select>*/}
                                   <input type="text" placeholder=" " className="form-control" id={`${this.props.prefix+"state"}`} value={this.props.state}
                                    onChange={this.props.onChange} required />
                                
                                <div className="invalid-feedback">
                                    Please provide a valid state or province.
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label >Postal Code *</label>
                                <input type="text" placeholder=" " className="form-control" id={`${this.props.prefix+"zip"}`} value={this.props.zip}
                                    onChange={this.props.onChange} required />
                                <div className="invalid-feedback">
                                    ZipCode code required.
                                </div>
                            </div>
                        </div>
                        <div className="form-steps">
                            <h4 className="form-step-list">Your Contact Information</h4>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12 mb-3">
                                <label >Email * </label>
                                <input type="email" onChange={this.props.onChange} className="form-control" value={this.props.email} id={`${this.props.prefix+"email"}`} placeholder="you@example.com" required />
                                <div className="invalid-feedback">
                                    Please enter a valid email address for shipping updates.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label >Website</label>
                                <input type="text" onChange={this.props.onChange} value={this.props.website} className="form-control" id={`${this.props.prefix+"website"}`} placeholder="www.xyz.com" />
                                <div className="invalid-feedback">
                                    Please enter a valid URL.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label >Phone Number *</label>
                                <input type="text" placeholder=" " className="form-control" id={`${this.props.prefix+"phone"}`} value={this.props.phone} maxLength={10} pattern="\d*"
                                    onChange={this.props.onChange} required />
                                <div className="invalid-feedback">
                                    Phone Number is required.
                                </div>
                            </div>
                        </div>
                        <hr className="mb-4" />
                        <div className="custom-checkbox">
                            {/* <input type="checkbox" value={this.state.isShipping} onChange={this.handleChange} className="custom-control-input"  />
                            <label className="custom-control-label">Shipping address is the same as my billing address</label> */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={this.props.onSelectCheckbox}
                                        color="primary"
                                        checked={this.props.isSameAddress}
                                        name="isSameAddress"
                                    />
                                }
                                label="My Billing address is the same as my shipping"
                            />
                        </div>
                        <hr className="mb-4" />

                        <div className="form-steps">
                            <h4 className="form-step-list">Covid-19</h4>
                            <p>Due to covid-19, there may be some delays in shipping times.</p>
                        </div>
                        {/* <div className="export-detail-box">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio"
                            name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                            <label className="form-check-label" for="inlineRadio1">1</label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio"
                            name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                            <label className="form-check-label" for="inlineRadio2">2</label>
                            </div>
                        </div> */}

                        <div className="text-right  continue-btn">
                            <button className="btn btn-primary" type="submit">Continue to Shipping</button>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

export default Shipping;