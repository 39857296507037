import { homeConstants } from "store/constants";
let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loading:true,loggedIn: true, user } : {};
export function menu(state = initialState, action) {
    switch (action.type) {
        case homeConstants.FOOTER_REQUEST:
            return {
              loggedIn: true,
              loading:true
            };
          case homeConstants.FOOTER_SUCCESS:
            return {
              loggedIn: true,
              loading:false,
              menu: action.menu.results
            };
          case homeConstants.FOOTER_FAILURE:
            if (user) {
              return {
                loggedIn: true,
                loading:false
              };
            } else {
              return {
                loggedIn: false,
                loading:false
              };
            }
        default:
            return state;
    }
}
  