import React from "react";
import "./InquiryModal.css";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";


export default class InquiryModal extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      // <div class="modal" id="modal">
      // <div class="modal-header">
      //   <h5 class="modal-title">Modal title</h5>
      //   <button type="button" class="close" onClick={this.onClose} data-dismiss="modal" aria-label="Close">
      //     <span aria-hidden="true">&times;</span>
      //   </button>
      // </div>

      //   <div class="content">{this.props.children}</div>

      // </div>

      <div>
        <div className="custom-modal">
          <Modal.Header closeButton>
            <Modal.Title>{this.props.headerText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.children}
          </Modal.Body>
        </div>
      </div>
    );
  }
}
InquiryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};