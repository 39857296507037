//
import { productConstants } from 'store/constants';
import { enquiryService } from '../../services';

export const enquiryActions = {
    postEnquiry
}

function postEnquiry(enquiryData) {
    //console.log(enquiry);
    return dispatch => {
        // dispatch(request(enquiry));
        // enquiryService.postEnquiry(enquiry).then(enquiry => {
        //     dispatch(success(enquiry));
        // },
        //     error => {
        //         dispatch(failure(error.toString()));
        //     })

        return new Promise((resolve, reject) => {
            dispatch(request(enquiryData));
            enquiryService.postEnquiry(enquiryData).then(enquiryResult => {
                dispatch(success(enquiryResult));
                resolve(enquiryResult);
            },
                error => {
                    dispatch(failure(error.toString()));
                })
        });
    }

    function request() {
        return {
            type: productConstants.ENQUIRY_POST_REQUEST,
        }
    }

    function success(enquiry) {
        return {
            type: productConstants.ENQUIRY_POST_SUCCESS,
            enquiry
        }
    }

    function failure(error) {
        return {
            type: productConstants.ENQUIRY_POST_FAILURE,
            error
        }
    }
}