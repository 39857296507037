import React, { Component } from 'react';
import './Shipingaddress.css';

class Shipingaddress extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
        <div className="col-lg-5">
        <div className="card mb-4">
          <div className="card-body">    
            <h5 className="mb-3">Shipping Address</h5>    
            <div className="form-group">
              <label htmlFor="form16">Postcode / ZIP</label>
              <input type="text" id="form16" className="form-control" />                     
            </div>                   
            <div className="form-group">
              <label htmlFor="form17">Country</label>
              <select name id className="form-control">
                <option value>United States</option>
                <option value>Canada</option>
              </select>                      
            </div>
            <button type="button" className="btn btn-primary btn-block waves-effect waves-light">Update Details</button>
          </div>
        </div>
      
        <div className="card mb-4">
          <div className="card-body">    
            <a className="dark-grey-text d-flex justify-content-between" data-toggle="collapse" href="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
              Add a discount code (optional)
              <span><i className="fa fa-chevron-down pt-1" /></span>
            </a>    
            <div className="collapse show" id="collapseExample" style={{}}>
              <div className="mt-3">
                <div className="mb-0">
                  <input type="text" id="discount-code" className="form-control font-weight-light" placeholder="Enter discount code" />
                </div>
              </div>
            </div>
          </div>
        </div>                  
      </div>
    );
  }
}

export default Shipingaddress;