import React, { Component } from "react";
import { connect } from "react-redux";
import { enquiryActions } from "store/actions/enquiry.actions";
//import ImageUploader from 'react-images-upload';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: -50px 0px 0px  110px;
  border-color: red;
`;

class EnquiryForm extends Component {
   constructor(props) {
      super(props);
      this.form = React.createRef();
      this.state = {
         name: "",
         number: "",
         email: "",
         file: null,
         message: "",
         loading: false,
         isFormError: false
      };
   }

   getBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
         reader.onerror = error => reject(error);
      });
   }

   // submitHandler = (e) => {
   //    e.preventDefault();

   //    // e.target.reset();
   //    e.target.className += ' was-validated';

   //    // console.log(this.state);

   //    if (this.state.file) {

   //       let fileB64 = null;
   //       this.setState({ isFormError: false })
   //       this.getBase64(this.state.file).then(
   //          data => {
   //             fileB64 = data;
   //             const formData = new FormData(this.form.current);
   //             formData.append('file', fileB64);
   //             var data = {};
   //             for (var pair of formData.entries()) {
   //                data[pair[0]] = pair[1];
   //             }
   //             this.setState({ loading: true });
   //             this.props.dispatch(enquiryActions.postEnquiry(data)).then((response) => {
   //                if (response) {
   //                   //console.log(response)
   //                   //alert("Inquiry Form has been submitted successfully");
   //                   toast("Inquiry Form has been submitted successfully", { position: "top-center" });
   //                   this.props.close();
   //                }
   //             })
   //          }
   //       );
   //    }
   //    else {
   //       this.setState({ isFormError: true })
   //    }
   // };




   submitHandler = (e) => {
      e.preventDefault();
   
      // Add the 'was-validated' class to the form element to trigger validation
      e.target.className += ' was-validated';
   
      // Check if the form is valid
      if (e.target.checkValidity()) {
         // Create a new FormData object containing the form data
         const formData = new FormData(e.target);
         
         // Convert the FormData object to a plain JavaScript object
         const data = {};
         for (const pair of formData.entries()) {
            data[pair[0]] = pair[1];
         }
   
         // Set the loading state to true to indicate that the form is being submitted
         this.setState({ loading: true });
   
         // Dispatch an action to post the form data to the server using Redux
         this.props.dispatch(enquiryActions.postEnquiry(data)).then((response) => {
            if (response) {
               // Display a success message to the user and close the modal
               toast("Inquiry Form has been submitted successfully", { position: "top-center" });
               this.props.close();
            }
         })
         .finally(() => {
            // Set the loading state to false when the form submission is complete
            this.setState({ loading: false });
         });
      } else {
         // Set the isFormError state to true to indicate that the form is invalid
         this.setState({ isFormError: true });
      }
   }
   


   onFileChange = (e) => {
      this.setState({ file: e.target.files[0] });
      this.setState({ isFormError: false })
   }

   changeHandler = e => {
      this.setState({ [e.target.name]: e.target.value });
   };

   render() {

      return (
         <form className="needs-validation" ref={this.form} onSubmit={(e) => this.submitHandler(e)}
            noValidate >
            <div className="row">
               <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom01">Name</label>
                  <input type="text" className="form-control" value={this.state.name} name='name' id="validationCustom01" placeholder="Name" onChange={this.changeHandler} required />
                  <div className="invalid-feedback">
                     Please enter name
              </div>
               </div>
               <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom02">Phone Number</label>
                  <input type="text" className="form-control" value={this.state.number} id="validationCustom02" name="number" placeholder="Phone Number" onChange={this.changeHandler} required />
                  <div className="invalid-feedback">
                     Please enter valid phone number.
                 </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom03">Email</label>
                  <input type="email" name="email" className="form-control" value={this.state.email} id="validationCustom03" placeholder="Email" onChange={this.changeHandler} required />
                  <div className="invalid-feedback">
                     Please enter valid email address.
                 </div>
               </div>
               <div className="col-md-6 mb-3">
                  {/* <label htmlFor="validationCustom04">Upload File</label> */}
                  {/* <div className="custom-file">
                     <input type="file" id="exampleInputFile"
                        className="form-control"
                        onChange={this.onFileChange}
                        accept=".png,.jpg,.jpeg,.gif,.docx,.doc,.xlsx,.xlx,.xls"
                     />
                     <label class="custom-file-label" onChange={this.onFileChange} for="exampleInputFile">Browse</label>
                  </div> */}

                  {/* <div className="custom-file">
                     <input className="form-control" type="file" onChange={this.onFileChange} />
                  </div> */}
                  {/* {this.state.isFormError ?
                     <div>
                        Please select a file.
                 </div> : null} */}
               </div>
            </div>
            <div className="row">
               <div className="col-md-12">
                  <label htmlFor="message">Message </label>
                  <textarea className="form-control" placeholder="Enter your message" name="message" id="" cols="30" rows="5" onChange={this.changeHandler} required></textarea>
                  <div className="invalid-feedback">
                     Please enter message.
                 </div>
               </div>
            </div>
            <button className="btn btn-success btn-lg mt-3" type="submit">Submit</button>
            <RingLoader loading={this.state.loading} css={override} size={50} />
         </form>
      );
   }
}

const mapStateToProps = (state) => {
   return state;
}
export default connect(mapStateToProps)(EnquiryForm);