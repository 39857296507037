import Loader from "layout/Loader/Loader";
import React, { Component } from "react";
import { connect } from "react-redux";
//import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import config from "services/config";
import { homeActions } from "store/actions";
//import { history } from "store/helpers";
import "./Footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(homeActions.getLogo());
    this.props.dispatch(homeActions.getFooter());
    this.exploreMore = this.quickLinks = this.footerContent = null;
  }

  // componentWillMount(){}
  getFooterMenuItemsFromAll = () => {
    var footerContentFilter = [config.FOOTER_TYPE_EXPLORE_LINKS];//Is a footer menu item
    var footerContentArray = this.props.menuItems.filter(function (itm) {
      return footerContentFilter.indexOf(itm.footer_type) > -1;
    });
    footerContentArray = { content: footerContentArray };
    this.exploreMore = footerContentArray.content;

    footerContentFilter = [config.FOOTER_TYPE_QUICK_LINKS];//Is a footer menu item
    footerContentArray = this.props.menuItems.filter(function (itm) {
      return footerContentFilter.indexOf(itm.footer_type) > -1;
    });
    footerContentArray = { content: footerContentArray };
    this.quickLinks = footerContentArray.content;

    footerContentFilter = ['footer-text-content'];//Is a footer menu item
    footerContentArray = this.props.menuItems.filter(function (itm) {
      return footerContentFilter.indexOf(itm.page_slug) > -1;
    });
    footerContentArray = { content: footerContentArray };
    this.footerContent = footerContentArray.content;
  }

  render() {
    if (this.props.footerMenu.loading || this.props.logo.loading) return (<Loader />);
    return (
      <footer className="w3l-footer-29-main" id="footer">
        {this.props.menuItems && this.getFooterMenuItemsFromAll()}
        <div className="footer-29 py-5">
          <div className="container pb-lg-3">
            <div className="row footer-top-29">
              <div className="col-lg-6 col-md-6 footer-list-29 footer-1 mt-md-4">
                <h1 className="footer-logo">
                  <a className="footer-logo mb-md-3 mb-2" href="/">
                    {this.props.logo && this.props.logo.items && <img
                      src={this.props.logo.items[0].logo}
                      width="178px" alt="logo"
                    />}{" "}


                  </a>
                </h1>
                {this.props.menuItems && (this.footerContent.map((item) => (
                  <p key={item.page_slug} dangerouslySetInnerHTML={{ __html: item.content }} />
                )))}

              </div>
              <div className="col-lg-3 col-md-6 footer-list-29 footer-2 mt-5">
                <h6 className="footer-title-29">Explore More</h6>

                <ul>

                  {this.props.menuItems && (this.exploreMore.map((item, index) => (
                    <React.Fragment key={item.page_slug + index}>
                      {item.is_footer &&
                        <li>
                          <Link to={`${item.page_slug === config.CONTACT_US_SLUG ? config.CONTACT_PAGE_URL :'/page/'+item.page_slug}`}>{item.menu_title}</Link>
                        </li>
                      }
                    </React.Fragment>
                  )))}
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-list-29 footer-4 mt-5">
                <h6 className="footer-title-29">Quick Links</h6>
                <ul>
                  {this.props.menuItems && (this.quickLinks.map((item, index) => (
                    <React.Fragment key={item.page_slug + index}>
                      {item.is_footer &&
                        <li>
                        <Link to={`${item.page_slug === config.CONTACT_US_SLUG ? config.CONTACT_PAGE_URL :'/page/'+item.page_slug}`}>{item.menu_title}</Link>
                        </li>
                      }
                    </React.Fragment>
                  )))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="footers14-block" className="py-3">
          <div className="container">
            <div className="footers14-bottom text-center">
              <div className="social">
                <a href="#facebook" className="facebook">
                  <span className="fa fa-facebook" aria-hidden="true"></span>
                </a>
                <a href="#google" className="google">
                  <span className="fa fa-google-plus" aria-hidden="true"></span>
                </a>
                <a href="#twitter" className="twitter">
                  <span className="fa fa-twitter" aria-hidden="true"></span>
                </a>
                <a href="#instagram" className="instagram">
                  <span className="fa fa-instagram" aria-hidden="true"></span>
                </a>
                <a href="#youtube" className="youtube">
                  <span className="fa fa-youtube" aria-hidden="true"></span>
                </a>
              </div>
              <div className="copyright mt-1">
             <p>&copy; {new Date().getFullYear()} RealTime7 Inc. All Rights Reserved</p>
              </div>

            </div>
          </div>
        </div>
        <ScrollToTop smooth />
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return { logo: state.logo, footerMenu: state.footerMenu, menuItems: state.footerMenu && state.footerMenu.menu ? state.footerMenu.menu : null }
}
export default connect(mapStateToProps)(Footer);
