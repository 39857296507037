import React, {Suspense, Component } from 'react';
import './StlLoader.css';
//import StlViewer from "../../components/Features/Features";
//import StlTabs from "../../components/Slider/Slider";

//import React, { Suspense } from 'react';

const StlViewer = React.lazy(() => import('../../components/StlViewer/StlViewer'));
const StlTabs = React.lazy(() => import('../../components/StlTabs/StlTabs'));
const StlItems = React.lazy(() => import('../../components/StlItems/StlItems'));
class StlLoader extends Component {
  constructor(props){
    super(props);
    document.title = "Realtime-7 | StlLoader";
  }  
  // componentWillUnmount(){}
  // componentDidMount(){} 
  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <StlViewer/>
          <StlItems/>
          <StlTabs />  
        </Suspense>
      </div>
    );
  }
}

export default StlLoader;