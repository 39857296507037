export const cartConstants = {
    GET_CART_REQUEST:'GET_CART_REQUEST',
    GET_CART_SUCCESS:'GET_CART_SUCCESS',
    GET_CART_FAILURE:'GET_CART_FAILURE',

    ADD_TO_CART_REQUEST:'ADD_TO_CART_REQUEST',
    ADD_TO_CART_SUCCESS:'ADD_TO_CART_SUCCESS',
    ADD_TO_CART_FAILURE:'ADD_TO_CART_FAILURE',

    DECREASE_ITEM_CART_REQUEST:'DECREASE_ITEM_CART_REQUEST',
    DECREASE_ITEM_CART_SUCCESS:'DECREASE_ITEM_CART_SUCCESS',
    DECREASE_ITEM_CART_FAILURE:'DECREASE_ITEM_CART_FAILURE',
}