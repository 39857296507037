import React, { Component } from "react";
//Component import
import GoogleButton from "components/SocialButton/GoogleButton";
import FacebookButton from "components/SocialButton/FacebookButton";
import "./Registration.css";
import { connect } from "react-redux";
import { userActions } from "../../store/actions";
import {FACEBOOK_APP_ID,GOOGLE_APP_ID} from './../../store/constants/index'
class Registration extends Component {
  constructor(props) {
    super(props);
    document.title = "Dwight | Registration";
    this.state = {
      user: {
        name: "",
        email: "",
        company: "",
        phone_number: "",
        street: "",
        city:"",
        password: "",
        rePassword:"",role:"customer"
      },
      submitted: false,
    };
    this.nodes = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user } = this.state;
    if (user.name && user.email && user.company && user.phone_number  && user.password && user.rePassword) {
      if (user.password !== user.rePassword) {
        return 0;
      }else{
        this.props.dispatch(userActions.register(user));
      }
    }
  }

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node;
    }
  }

  handleSocialLogin = (user) => {
    console.log(user);
    //todo : MAke api call and store data
  };

  handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  render() {

    const { user, submitted } = this.state;

    return (
      <section>
      
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-lg-6 border p-4">
              <div className="inner contact-right">
                <h4 className="mb-2">Create an Account!</h4>
                <form action="#" method="post" onSubmit={this.handleSubmit} className="signin-form">
                  <div className="input-grids">
                    <div className={'form-group' + (submitted && !user.name ? ' has-error' : '')}>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Your Name*"
                        className="contact-input"
                        onChange={(e) => this.handleChange(e)}
                      />
                      {submitted && !user.name &&
                            <div className="help-block">Name is required</div>
                        }
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email*"
                        className="contact-input"
                        required=""
                        onChange={(e) => this.handleChange(e)}
                      />
                      {submitted && !user.email &&
                            <div className="help-block">Email is required</div>
                        }
                    </div>
                    <div className="form-group ">
                      <input
                        type="text"
                        name="company"
                        id="company"
                        placeholder="Company*"
                        className="contact-input mr-2"
                        required=""
                        onChange={(e) => this.handleChange(e)}
                      />
                      {submitted && !user.company &&
                            <div className="help-block">Company is required</div>
                        }
                      <input
                        type="tel"
                        name="phone_number"
                        id="phone"
                        placeholder="Phone*"
                        className="contact-input"
                        onChange={(e) => this.handleChange(e)}
                      />
                      {submitted && !user.phone_number &&
                            <div className="help-block">Phone number is required</div>
                        }
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="street"
                      id="street"
                      placeholder="Street"
                      className="contact-input"
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City"
                      className="contact-input"
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="form-group">
                  <div className="row">

                  <div className="col">

                  <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      className="contact-input mr-2"
                      onChange={(e) => this.handleChange(e)}
                    />
                    {submitted && !user.password &&
                            <div className="help-block">Password is required</div>
                        }
                  </div>
                  <div className="col-12">
                    <input
                      type="re-password"
                      name="rePassword"
                      id="rePassword"
                      placeholder="Re enter your password"
                      className="contact-input"
                      onChange={(e) => this.handleChange(e)}
                    />
                    {submitted && !user.rePassword &&
                            <div className="help-block">Re entering your password is required</div>
                        }
                        {submitted &&(user.rePassword !==user.password) &&
                            <div className="help-block">Passwords do not match. Please try again</div>
                        }
                  </div>
                  </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="termsConditions"
                      className="terms-conditions"
                    >
                      <input
                        type="checkbox"
                        name="termConditions"
                        id="termsConditions"
                        className="contact-input"
                        onChange={(e) => this.handleChange(e)}
                      />
                      Accept terms & conditions
                    </label>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-primary theme-button"
                      onClick={this.handleSubmit}
                    >
                      Register Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner ">
                <img
                  src="assets/images/signup-bg.png"
                  alt=""
                  className="w-100"
                />
                <hr />
                <GoogleButton
                    provider="google"
                    appId={GOOGLE_APP_ID}
                    onLoginSuccess={this.handleSocialLogin}
                    onLoginFailure={this.handleSocialLoginFailure}
                    getInstance={this.setNodeRef.bind(this, "google")}
                    key={"google"}
                  >
                    <i className="fa fa-google fa-fw"></i> Login with Google
                  </GoogleButton>

                  <FacebookButton
                    provider="facebook"
                    appId={FACEBOOK_APP_ID}
                    onLoginSuccess={this.handleSocialLogin}
                    onLoginFailure={this.handleSocialLoginFailure}
                    getInstance={this.setNodeRef.bind(this, "facebook")}
                    key={"facebook"}
                    onInternetFailure={() => {
                      return true;
                    }}
                  >
                    <i className="fa fa-facebook fa-fw"></i> Login with Facebook
                  </FacebookButton>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps=(state)=>{
  return { user:state.user };
}

export default connect(mapStateToProps)(Registration);
