import React, { Component } from 'react';
import './CategoryAside.css';
import { Nav/*, MenuItem,  Tabs, ButtonToolbar, Button, Table, ButtonGroup, Row, Col, Grid, Panel, FormGroup, FormControl*/ } from 'react-bootstrap';
/*import {
    NavLink
} from "react-router-dom";*/
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import config from 'services/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
//import { shopActions } from 'store/actions/shop.actions';
let urlElements = window.location.href.split('/');
// class CategoryAside extends Component {
//     constructor(props) {
//         super(props);
//         document.title = "Realtime-7 | Shop";
//         //Get URL and obtain category
//         this.urlCategory = urlElements[4];
        
        
//     }
class CategoryAside extends Component {
    constructor(props) {
      super(props);
      document.title = "Realtime-7 | Shop";
      this.urlCategory = urlElements[4];
      this.state = {
        activeIndex: null
      };
    }
  
    handleClick = (index) => {
      this.setState({
        activeIndex: index
      });
    }
    
    customerMenu = () => {
        // if (this.urlCategory === config.CATEGORY_NON_ENTERPRISE_PRODUCT ||
        //     (this.props.shopProducts && this.props.shopProducts.shopProducts &&
        //         this.props.shopProducts.shopProducts.length > 0 &&
        //         parseInt(this.props.shopProducts.shopProducts[0].category.id) === parseInt(this.urlCategory) &&
        //         this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_NON_ENTERPRISE_PRODUCT)) {

        if (this.urlCategory === config.CATEGORY_NON_ENTERPRISE_PRODUCT ||
            (this.props.shopProducts && this.props.shopProducts.shopProducts &&
                this.props.shopProducts.shopProducts.length > 0 &&
                this.props.shopProducts.categoryId === this.urlCategory &&
                this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_NON_ENTERPRISE_PRODUCT)) {

            if (this.props.categories !== undefined) {
                if (this.props.categories.categories !== undefined) {
                    // const customerMenuItems = this.props.categories.categories.results.map((category, index) => {
                        const customerMenuItems = this.props.categories.categories.map((category, index) => {
                            const isActive = category.categoryName.toLowerCase() === this.urlCategory.replace(/-/g, ' ').toLowerCase();
                        //     return (
                        //         <React.Fragment key={index}>
                        //             { category.category_items === config.CATEGORY_NON_ENTERPRISE_PRODUCT ?
                        //                 <Nav.Item as="li" className={`${category.child && category.child.length > 0 ? 'subcategory' : ''}`} key={index}>
                        //                     <Link to={`/shop/${category.slug}`} className={`${category.child && category.child.length > 0 ? 'has-submenu' : ''}`}>{category.categoryName}</Link>
                        //                     {category.child && category.child.length > 0 && <FontAwesomeIcon icon={faAngleRight} />}
                        //                     <ul className="submenu-list">
                        //                         {category.child && category.child.length > 0 && category.child.map((childCategory, index) => {
                        //                             return (
                        //                                 <Nav.Item as="li" className="" key={index}>
                        //                                     <Link to={`/shop/${childCategory.categoryName.replace(/\s+/g, '-').toLowerCase()}`}>{childCategory.categoryName}</Link>
                        //                                 </Nav.Item>
                        //                             )
                        //                         })}
                        //                     </ul>
                        //                 </Nav.Item> : ''}
                        //         </React.Fragment>
                        //     )
                        // })
                        return (
                            <React.Fragment key={index}>
                              { category.category_items === config.CATEGORY_NON_ENTERPRISE_PRODUCT ? 
                                <Nav.Item as="li" className={isActive ? 'active' : ''}>
                                  <Link to={`/designservice/${category.slug}`}>{category.categoryName}</Link>
                                </Nav.Item> 
                              : ''}
                            </React.Fragment>
                          );
                        });
                        
                    return (
                        <div className="side-menu-list pt-4">
                            <Link to={`/category/${config.CATEGORY_NON_ENTERPRISE_PRODUCT}`}><h6 className="p-1 border-bottom">Products</h6></Link>
                            <div className="productlist">
                                <ul className="menu menu-list">
                                    {customerMenuItems}
                                </ul>
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    designMenu = () => {
        //if (this.urlCategory === config.CATEGORY_DESIGN_PRODUCT || (this.props.shopProducts && this.props.shopProducts.shopProducts && this.props.shopProducts.shopProducts.length > 0 && parseInt(this.props.shopProducts.shopProducts[0].category.id) === parseInt(this.urlCategory) && this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_DESIGN_PRODUCT)) {
            if (this.urlCategory === config.CATEGORY_DESIGN_PRODUCT || (this.props.shopProducts && this.props.shopProducts.shopProducts && this.props.shopProducts.shopProducts.length > 0 && this.props.shopProducts.categoryId === this.urlCategory && this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_DESIGN_PRODUCT)) {
            if (this.props.categories !== undefined) {
                if (this.props.categories.categories !== undefined) {
                    //const designMenuItems = this.props.categories.categories.results.map((category, index) => {
                        const designMenuItems = this.props.categories.categories.map((category, index) => {
                            const isActive = category.categoryName.toLowerCase() === this.urlCategory.replace(/-/g, ' ').toLowerCase();
                            return (
                              <React.Fragment key={index}>
                                { category.category_items === config.CATEGORY_DESIGN_PRODUCT ? 
                                  <Nav.Item as="li" className={isActive ? 'active' : ''}>
                                    <Link to={`/designservice/${category.slug}`}>{category.categoryName}</Link>
                                  </Nav.Item> 
                                : ''}
                              </React.Fragment>
                            );
                          });
                          
                    return (
                        <div className="side-menu-list pt-4">
                            <Link to={`/category/${config.CATEGORY_DESIGN_PRODUCT}`}><h6 className="p-212 border-bottom">{config.DESIGN_PRODUCT_TEXT}</h6></Link>
                            <ul className="productlist">
                               
                                    <ul className="menu menu-list">
                                        {designMenuItems}
                                    </ul>
                                
                            </ul>
                        </div>
                    )
                }
            }
        }
    }



    partnersMenu = () => {
        //if (this.urlCategory === config.CATEGORY_DESIGN_PRODUCT || (this.props.shopProducts && this.props.shopProducts.shopProducts && this.props.shopProducts.shopProducts.length > 0 && parseInt(this.props.shopProducts.shopProducts[0].category.id) === parseInt(this.urlCategory) && this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_DESIGN_PRODUCT)) {
            if (this.urlCategory === config.CATEGORY_PARTNERS || (this.props.shopProducts && this.props.shopProducts.shopProducts && this.props.shopProducts.shopProducts.length > 0 && this.props.shopProducts.categoryId === this.urlCategory && this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_PARTNERS)) {
            if (this.props.categories !== undefined) {
                if (this.props.categories.categories !== undefined) {
                    //const designMenuItems = this.props.categories.categories.results.map((category, index) => {
                    // const partnersMenuItems = this.props.categories.categories.map((category, index) => {
                    //     return (
                    //         <React.Fragment key={index}>
                    //             { category.category_items === config.CATEGORY_PARTNERS ? <Nav.Item as="li">
                    //                 <Link to={`/designservice/${category.slug}`} className={this.urlCategory  ? 'active' : ''}>{category.categoryName}</Link>
                    //             </Nav.Item> : ''}
                    //         </React.Fragment>)
                    // })

                    const partnersMenuItems = this.props.categories.categories.map((category, index) => {
                        const isActive = category.categoryName.toLowerCase() === this.urlCategory.replace(/-/g, ' ').toLowerCase();
                        return (
                            <React.Fragment key={index}>
                                { category.category_items === config.CATEGORY_PARTNERS ?
                                    <Nav.Item as="li" className={isActive ? 'active' : ''}>
                                        <Link to={`/designservice/${category.slug}`}>{category.categoryName}</Link>
                                    </Nav.Item> : ''
                                }
                            </React.Fragment>
                        );
                    });
                    return (
                       
                        <div className="side-menu-list pt-4">
                            <Link to={`/category/${config.CATEGORY_PARTNERS}`}><h6 className="p-213 border-bottom">{config.CATEGORY_PARTNERS_TEXT}</h6></Link>
                            <ul className="productlist">
                               
                                    <ul className="menu menu-list">
                                        {partnersMenuItems}
                                    </ul>
                                   
                            </ul>
                        </div>
                    )
                }
            }
        }   
    }

    // partnersMenu = () => {
    //     if (this.urlCategory === config.CATEGORY_PARTNERS || (this.props.shopProducts && this.props.shopProducts.shopProducts && this.props.shopProducts.shopProducts.length > 0 && this.props.shopProducts.categoryId === this.urlCategory && this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_PARTNERS)) {
    //       if (this.props.categories !== undefined) {
    //         if (this.props.categories.categories !== undefined) {
    //           const partnersMenuItems = this.props.categories.categories.map((category, index) => {
    //             const isActive = this.state.activeIndex === index;
    
    //             return (
    //               <React.Fragment key={index}>
    //                 { category.category_items === config.CATEGORY_PARTNERS ?
    //                   <Nav.Item as="li" onClick={() => this.handleClick(index)}>
    //                     <Link to={`/designservice/${category.slug}`} className={isActive ? 'active' : ''}>{category.categoryName}</Link>
    //                   </Nav.Item> : ''}
    //               </React.Fragment>
    //             )
    //           })
    
    //           return (
    //             <div className="side-menu-list pt-4">
    //               <Link to={`/category/${config.CATEGORY_PARTNERS}`}><h6 className="p-213 border-bottom">{config.CATEGORY_PARTNERS_TEXT}</h6></Link>
    //               <ul className="productlist">
    //                 <ul className="menu menu-list">
    //                   {partnersMenuItems}
    //                 </ul>
    //               </ul> 
    //             </div>
    //           )
    //         }
    //       }
    //     }
    //   }
    

    
    // enterpriseMenu = () => {
    //     if (this.urlCategory === config.CATEGORY_ENTERPRISE_PRODUCT || (this.props.shopProducts && this.props.shopProducts.shopProducts && this.props.shopProducts.shopProducts.length > 0 && this.props.shopProducts.categoryId === this.urlCategory && this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT)) {
    //         if (this.props.categories !== undefined) {
    //             if (this.props.categories.categories !== undefined) {
    //                 enterpriseMenuItems = this.props.categories.categories.map((category, index) => {
    //                     const isActive = this.state.activeIndex === index;
                    
    //                     return (
    //                         <React.Fragment key={index}>
    //                             { category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT ? 
    //                                 <Nav.Item as="li" className={`${category.child && category.child.length > 0 ? 'subcategory' : ''}`} key={index}>
    //                                     <Link 
    //                                         to={`/shop/${category.slug}`}
    //                                         onClick={() => this.handleMenuClick(index)}
    //                                         className={isActive ? 'active' : ''}
    //                                     >
    //                                         {category.categoryName}
    //                                     </Link>
    //                                     {category.child && category.child.length > 0 && <FontAwesomeIcon icon={faAngleRight} />}
    //                                     <ul className="submenu-list">
    //                                         {category.child && category.child.length > 0 && category.child.map((childCategory, index) => {
    //                                             return (
    //                                                 <Nav.Item as="li" className="" key={index}>
    //                                                     <Link to={`/shop/${childCategory.categoryName.replace(/\s+/g, '-').toLowerCase()}`}>{childCategory.categoryName}</Link>
    //                                                 </Nav.Item>
    //                                             )
    //                                         })}
    //                                     </ul>
    //                                 </Nav.Item> 
    //                             : ''}
    //                         </React.Fragment>
    //                     )
    //                 })
                    
    //                 return (
    //                     <div className="side-menu-list">
    //                         <Link to={`/category/${config.CATEGORY_ENTERPRISE_PRODUCT}`}><h6 className="p-212 border-bottom">Shop</h6></Link>
    //                         <div className="productlist">
    //                             <ul className="menu menu-list">
    //                                 {enterpriseMenuItems}
    //                             </ul>
    //                         </div>
    //                     </div>
    //                 )
    //             }
    //         }
    //     }
    // }
    

    enterpriseMenu = () => {
        //if (this.urlCategory === config.CATEGORY_ENTERPRISE_PRODUCT || (this.props.shopProducts && this.props.shopProducts.shopProducts && this.props.shopProducts.shopProducts.length > 0 && parseInt(this.props.shopProducts.shopProducts[0].category.id) === parseInt(this.urlCategory) && this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT)) {
        if (this.urlCategory === config.CATEGORY_ENTERPRISE_PRODUCT || (this.props.shopProducts && this.props.shopProducts.shopProducts && this.props.shopProducts.shopProducts.length > 0 && this.props.shopProducts.categoryId === this.urlCategory && this.props.shopProducts.shopProducts[0].category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT)) {
            if (this.props.categories !== undefined) {
                if (this.props.categories.categories !== undefined) {
                    //const enterpriseMenuItems = this.props.categories.categories.results.map((category, index) => {
                    const enterpriseMenuItems = this.props.categories.categories.map((category, index) => {
                        const isActive = category.categoryName.toLowerCase() === this.urlCategory.replace(/-/g, ' ').toLowerCase();
                    //     return (<React.Fragment key={index}>
                    //         { category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT ? <Nav.Item as="li" className={`${category.child && category.child.length > 0 ? 'subcategory' : ''}`} key={index}>
                    //             <Link to={`/shop/${category.slug}`}>{category.categoryName}</Link>
                    //             {category.child && category.child.length > 0 && <FontAwesomeIcon icon={faAngleRight} />}
                    //             <ul className="submenu-list">
                    //                 {category.child && category.child.length > 0 && category.child.map((childCategory, index) => {
                    //                     //console.log(childCategory)
                    //                     return (
                    //                         <Nav.Item as="li" className="" key={index}>
                    //                             <Link to={`/shop/${childCategory.categoryName.replace(/\s+/g, '-').toLowerCase()}`}>{childCategory.categoryName}</Link>
                    //                         </Nav.Item>
                    //                     )
                    //                 })}
                    //             </ul>
                    //         </Nav.Item> : ''}
                    //     </React.Fragment>)
                    // })
                    return (
                        <React.Fragment key={index}>
                          { category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT  ? 
                            <Nav.Item as="li" className={isActive ? 'active' : ''}>
                              <Link to={`/shop/${category.slug}`}>{category.categoryName}</Link>
                            </Nav.Item> 
                          : ''}
                        </React.Fragment>
                      );
                    });
                    return (
                        <div className="side-menu-list">
                            {/* <Link to={`/category/${config.CATEGORY_ENTERPRISE_PRODUCT}`}><h6 className="p-1 border-bottom">{config.ENTERPRISE_PRODUCT_TEXT}</h6></Link> */}
                             <Link to={`/category/${config.CATEGORY_ENTERPRISE_PRODUCT}`}><h6 className="p-212 border-bottom">Shop</h6></Link>
                            <div className="productlist">
                                <ul className="menu menu-list">
                                    {enterpriseMenuItems}
                                </ul>
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    dynamicMenu = () => {
        if (this.props.categories !== undefined) {
            if (this.props.categories.categories !== undefined) {
                return (
                    <>
                        <div>
                            {this.enterpriseMenu()}
                        </div>
                        <div>
                            {this.customerMenu()}
                        </div>
                        <div>
                            {this.designMenu()}
                        </div>
                        <div>
                            {this.partnersMenu()}
                        </div>
                    </>
                )
            }
        }
    }

    render() {
        //console.log('this.props.shopProducts.shopProducts', this.props.shopProducts.shopProducts)
        urlElements = window.location.href.split('/');
        this.urlCategory = urlElements[4];
        //console.log(this.urlCategory)
        return (
            <div className="col-md-12">
                <div className="inner bg-white">
                    <div className="productListView">
                        {this.dynamicMenu()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps)(CategoryAside);
