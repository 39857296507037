import React, { Component } from "react";
import { connect } from "react-redux";
//import config from "services/config";

import PropTypes from 'prop-types';
import "./Sluggable.css";
import { homeActions } from "store/actions";
import Loader from "layout/Loader/Loader";
import { history } from "store/helpers";

class Sluggable extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.props.dispatch(homeActions.getFooter());
    this.slug = this.props.match.params.slug;
    this.pageContent = null;
  }

  getPageContentFromSlug = () => {
    var itemFilterBy = [this.slug];//Is a footer menu item
    var pageDataArray = this.props.menuItems.filter(function (itm) {
      return itemFilterBy.indexOf(itm.page_slug) > -1;
    });
    pageDataArray = { content: pageDataArray };
    this.pageContent = pageDataArray.content;
  }

  render() {
    if (this.props.footerMenu.loading) return (<Loader />);

    const { match } = this.props;

    this.slug = match.params.slug;  
    return (
      <>
        {this.props.menuItems && this.getPageContentFromSlug()}
        {this.props.menuItems && (this.pageContent.map((item) => (
          <React.Fragment key={item.page_slug}>
          <div className="shopBanner">
            <img src="http://localhost:8000/media/AU-1.JPG"
              className="w-100" alt="img"
            />
            <h1>{item.menu_title}</h1>
          </div>
          <div className="container-fluid mb-3">
            <div className="row no-gutters">
              <div className="col-md-12 bg-light pt-md-5">
                <div className="serviceDetailsInfo pl-md-4 pr-md-4">
                  <h3>{item.menu_title}</h3>
                  <div className="serviceDescripition" dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              </div>
            </div>
          </div>
          </React.Fragment>
        )))}
      </>
    );
  }
}

const mapStateToProps = (state) => {

  return { state, footerMenu:state.footerMenu,menuItems: state.footerMenu && state.footerMenu.menu ? state.footerMenu.menu : null };

}
export default connect(mapStateToProps)(Sluggable);
