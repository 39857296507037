import React, { Component } from "react";
import "./StlTabs.css";
import { Tabs, Tab } from "react-bootstrap";
class StlTabs extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "file_library",
    };
  }

  render() {
    return (
      <section className="mt-5">
        <div className="inner">
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.key}
            onSelect={(key) => this.setState({ key })}
          >
            <Tab eventKey="file_library" title="File Library">
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner mt-3 mb-3 border-bottom pb-3 topStripFileLibrary">
                        <h6 className="text-muted">
                          Note : Accepted format{" "}
                          <strong className="text-dark text-uppercase">
                            .stl
                          </strong>
                          only
                        </h6>
                        <input
                          type="text"
                          className="form-control w-25"
                          placeholder="Search here"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                      <div className="col-lg-3">
                        <div className="inner border p-2">
                          <div className="docPreview">
                            <img src="https://dummyimage.com/200x100/c4cfcc/fff" alt="img" className="w-100"/>
                            <div className="dxfFileName">temprory.dxf</div>
                          </div>
                          <div className="downloadView mt-2">
                            <button className="btn btn-secondary w-100">
                              <i className="fa fa-eye mr-2" />
                              Load DXF File
                            </button>
                          </div>
                        </div>
                      </div>  
                      <div className="col-lg-3">
                        <div className="inner border p-2">
                          <div className="docPreview">
                            <img src="https://dummyimage.com/200x100/c4cfcc/fff" alt="img" className="w-100"/>
                            <div className="dxfFileName">temprory.dxf</div>
                          </div>
                          <div className="downloadView mt-2">
                            <button className="btn btn-secondary w-100">
                              <i className="fa fa-eye mr-2" />
                              Load DXF File
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="inner border p-2">
                          <div className="docPreview">
                            <img src="https://dummyimage.com/200x100/c4cfcc/fff" alt="img" className="w-100"/>
                            <div className="dxfFileName">temprory.dxf</div>
                          </div>
                          <div className="downloadView mt-2">
                            <button className="btn btn-secondary w-100">
                              <i className="fa fa-eye mr-2" />
                              Load DXF File
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="inner border p-2">
                          <div className="docPreview">
                            <img src="https://dummyimage.com/200x100/c4cfcc/fff" alt="img" className="w-100"/>
                            <div className="dxfFileName">temprory.dxf</div>
                          </div>
                          <div className="downloadView mt-2">
                            <button className="btn btn-secondary w-100">
                              <i className="fa fa-eye mr-2" />
                              Load DXF File
                            </button>
                          </div>
                        </div>
                      </div> 
                                    
                  </div>                
                   <div className="row mb-5">
                    <div className="col-lg-12">
                      <div className="inner">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                Previous
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                3
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="information" title="Information">
              <div className="container">
                <div className="inner">                  
                  <div className="row mt-5">
                    <div className="col-lg-12">
                      <div className="cinfo">                        
                        <div className="divTable hoverEffect">
                              <div className="divRow divCaption">
                                  <h4 >Material Info</h4>
                              </div>
                              <div className="divRow">
                                <div className="divCol"><b>File Name</b></div>
                                <div className="divCol">usa-flag-2021.dxf</div>
                                <div className="divCol"><b>Material</b></div>
                                <div className="divCol">Copper</div>
                              </div>                                                           
                              <div className="divRow divCaption">
                                  <h4 >Modal Info</h4>
                              </div> 
                              <div className="divRow">
                                <div className="divCol"><b>Size</b></div>
                                <div className="divCol">Copper</div>
                                <div className="divCol"><b>Volume (mm <sup>3</sup>)</b></div>
                                <div className="divCol">Copper</div>
                              </div>
                              <div className="divRow">
                                <div className="divCol"><b>File Units</b></div>
                                <div className="divCol">Copper</div>
                                <div className="divCol"><b>Triangles</b></div>
                                <div className="divCol">Copper</div>
                              </div>                            
                              <div className="divRow divCaption">
                                  <h4 >Options</h4>
                              </div>
                              <div className="divRow">
                                <div className="divCol"><b>Display</b></div>
                                <div className="divCol">Flat Shading</div>
                                <div className="divCol"><b>Orientation</b></div>
                                <div className="divCol">Front</div>
                              </div>                             
                              <div className="divRow">
                                <div className="divCol"><b>Edges</b></div>
                                <div className="divCol">Copper</div>
                                <div className="divCol"><b>Auto- Rotation</b></div>
                                <div className="divCol">Fixed</div>
                              </div>                              
                        </div>                   
                      </div>
                    </div>
                   </div>
                  </div>
              </div>
            </Tab>
            <Tab eventKey="documentation" title="Documentation">
              <div className="container">
                <div className="row p-3">
                  <div className="col-lg-3">
                    <div className="inner border p-2">
                      <div className="docPreview">
                        <img
                          src="https://dummyimage.com/'200x100/000/fff&text=Doc"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="downloadView mt-2">
                        <button className="btn btn-secondary w-100">
                          <i className="fa fa-download mr-2" />
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="inner border p-2">
                      <div className="docPreview">
                        <img
                          src="https://dummyimage.com/'200x100/000/fff&text=Doc"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="downloadView mt-2">
                        <button className="btn btn-secondary w-100">
                          <i className="fa fa-download mr-2" />
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="inner border p-2">
                      <div className="docPreview">
                        <img
                          src="https://dummyimage.com/'200x100/000/fff&text=Doc"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="downloadView mt-2">
                        <button className="btn btn-secondary w-100">
                          <i className="fa fa-download mr-2" />
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="inner border p-2">
                      <div className="docPreview">
                        <img
                          src="https://dummyimage.com/'200x100/000/fff&text=Doc"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="downloadView mt-2">
                        <button className="btn btn-secondary w-100">
                          <i className="fa fa-download mr-2" />
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="inner border p-2">
                      <div className="docPreview">
                        <img
                          src="https://dummyimage.com/'200x100/000/fff&text=Doc"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="downloadView mt-2">
                        <button className="btn btn-secondary w-100">
                          <i className="fa fa-download mr-2" />
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="inner border p-2">
                      <div className="docPreview">
                        <img
                          src="https://dummyimage.com/'200x100/000/fff&text=Doc"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="downloadView mt-2">
                        <button className="btn btn-secondary w-100">
                          <i className="fa fa-download mr-2" />
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="inner border p-2">
                      <div className="docPreview">
                        <img
                          src="https://dummyimage.com/'200x100/000/fff&text=Doc"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="downloadView mt-2">
                        <button className="btn btn-secondary w-100">
                          <i className="fa fa-download mr-2" />
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="inner border p-2">
                      <div className="docPreview">
                        <img
                          src="https://dummyimage.com/'200x100/000/fff&text=Doc"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="downloadView mt-2">
                        <button className="btn btn-secondary w-100">
                          <i className="fa fa-download mr-2" />
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row p-3 m-0">
                  <div className="col-lg-12 border-top m-0 "></div>
                </div>
                <div className="row p-3">
                  <div className="col-lg-6">
                    <div className="inner border p-2 d-flex">
                      <input type="file" className="form-control" />
                      <button className="btn btn-secondary w-100 ml-5">
                        <i className="fa fa-upload mr-2" />
                        Upload Docs
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-5">
                    <div className="col-lg-12">
                      <div className="inner">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                Previous
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                3
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="/#">
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
              </div>
            </Tab>
            <Tab eventKey="video" title="Video">
              <div className="container">
                <div className="row p-3">
                  <div className="col-lg-4">
                    <div className="inner border p-1 mt-2">
                      <iframe
                        title="video-1"
                        width="100%"
                        height="auto"
                        src="https://www.youtube.com/embed/fMMWMnMOPHE"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner border p-1 mt-2">
                      <iframe
                        title="video-2"
                        width="100%"
                        height="auto"
                        src="https://www.youtube.com/embed/fMMWMnMOPHE"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner border p-1 mt-2">
                      <iframe
                        title="video-3"
                        width="100%"
                        height="auto"
                        src="https://www.youtube.com/embed/fMMWMnMOPHE"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner border p-1 mt-2">
                      <iframe
                        title="video-4"
                        width="100%"
                        height="auto"
                        src="https://www.youtube.com/embed/fMMWMnMOPHE"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner border p-1 mt-2">
                      <iframe
                        title="video-5"
                        width="100%"
                        height="auto"
                        src="https://www.youtube.com/embed/fMMWMnMOPHE"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner border p-1 mt-2">
                      <iframe
                        title="video-6"
                        width="100%"
                        height="auto"
                        src="https://www.youtube.com/embed/fMMWMnMOPHE"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
    );
  }
}

export default StlTabs;
