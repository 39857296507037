import { homeConstants } from './../constants';

export function currencies(state = {}, action) {
    switch (action.type) {
        case homeConstants.CURRENCY_REQUEST:
            return {
                loading: true
            }
        case homeConstants.CURRENCY_SUCCESS:
            return {
                loading: false,
                currencies: action.currencies
            }

        case homeConstants.CURRENCY_FAILURE:
            return {
                error: action.error
            }
        default:
            return state;
    }

}