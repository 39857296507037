import { fetchWithDelay, postWithDelay } from './fetch';
import config from 'services/config';
const url = 'https://jsonplaceholder.typicode.com/posts';
const shippingURL = `${config.apiUrl}/admin/shipping/`
const paymentUrl = `${config.apiUrl}/website/payment/`

const fetchPosts = () => fetchWithDelay(url)
  .then((posts) => posts.slice(0, 10));

const submitData = (requestBody) => postWithDelay(shippingURL, requestBody).then((response) => {
  //console.log(response)
  return response;
})

const submitPayment = (requestBody) => postWithDelay(paymentUrl, requestBody).then((response) => {
  return response;
})

export const checkoutAPI = {
  fetchPosts,
  submitData,
  submitPayment
};