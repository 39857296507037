import { userConstants } from "../constants";
import { userService } from "../../services";



export const userActions = {
  login,
  logout,
  register,
  googleLogin,
  facebookLogin,
  userProfile,getAll,_delete,
  forgotpassword,
  resetpassword,

};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };


  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    window.location.href = "/";
    return { type: userConstants.LOGIN_SUCCESS, user };
    
  }
  function failure(error) {
    
    return { type: userConstants.LOGIN_FAILURE, error };
    
  }
}


function forgotpassword(email) {
  return (dispatch) => {
   
    dispatch(request(email));
    userService.forgotpassword(email).then(
      (user) => {
        console.log(user);
        dispatch(success(user));
       
      },
      (error) => {
        dispatch(failure(error.toString()));
       
      }
    );
  };
  function request(user) {
    return { type: userConstants.FORGOTPASSWORD_REQUEST, user };
  }
  function success(user) {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000); // Wait for 6 seconds (6000 milliseconds) before redirecting
    return { type: userConstants.FORGOTPASSWORD_SUCCESS, user };
  }
  
  function failure(error) {
    return { type: userConstants.FORGOTPASSWORD_FAILURE, error };
  }
}

function resetpassword() {
  return (dispatch) => {
   
    dispatch(request());
    userService.resetpassword().then(
      () => {
        dispatch(success());
       
      },
      (error) => {
        dispatch(failure(error.toString()));
       
      }
    );
  };
  function request() {
    return { type: userConstants.FORGOTPASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.FORGOTPASSWORD_SUCCESS };
  }
  function failure() {
    return { type: userConstants.FORGOTPASSWORD_FAILURE };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));
    userService.register(user).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };


  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}










function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

function googleLogin(accessToken) {
  return (dispatch) => {
    dispatch(request({ accessToken }));

    userService.googleLogin(accessToken).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.GOOGLE_SIGNIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GOOGLE_SIGNIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GOOGLE_SIGNIN_FAILURE, error };
  }
}

function facebookLogin(accessToken) {
  return (dispatch) => {
    dispatch(request({ accessToken }));

    userService.facebookLogin(accessToken).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.FACEBOOK_SIGNIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.FACEBOOK_SIGNIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FACEBOOK_SIGNIN_FAILURE, error };
  }
}

function userProfile() {
  return (dispatch) => {
    dispatch(request({ }));

    userService.getProfile().then(
      (profileData) => {
        dispatch(success(profileData));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.PROFILE_REQUEST, user };
  }
  function success(profileData) {
    return { type: userConstants.PROFILE_SUCCESS, profileData };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_SUCCESS, error };
  }
}
