import React, { Component } from 'react';

class Payment extends Component {

    render() {
        return (
            <div className="form-card">
                <div className="row mt-2">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label class="col-md-4 col-form-label">Name on card</label>
                            <div className="col-md-8">
                                <input type="text" id="fname" placeholder="Name on card*" className="form-control" value={this.props.fname}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.fnameError}</small></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label class="col-md-4 col-form-label">Card Number</label>
                            <div className="col-md-8">
                                <input type="text" id="lname" placeholder="**********" className="form-control" value={this.props.lname}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.lnameError}</small></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label class="col-md-4 col-form-label">Valid Through</label>
                            <div className="col-md-8">
                                <input type="text" id="fname" placeholder="03/21" className="form-control" value={this.props.fname}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.fnameError}</small></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label class="col-md-4 col-form-label">CVV Code</label>
                            <div className="col-md-8">
                                <input type="text" id="lname" placeholder="435" className="form-control" value={this.props.lname}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.lnameError}</small></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-md-6">
                        <button className="btn btn-secondary"
                            onClick={this.props.prev}>Prev</button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-success pull-right"
                            onClick={this.props.next}>Make Payment</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Payment;
