import React, { Component } from 'react';
import './Ordersummary.css';

class Ordersummary extends Component {
    // constructor(props){
    // super(props);
    // this.state = {};
    // }

    // componentWillMount(){}
    // componentDidMount(){}
    // componentWillUnmount(){}

    // componentWillReceiveProps(){}
    // shouldComponentUpdate(){}
    // componentWillUpdate(){}
    // componentDidUpdate(){}

    render() {
        return (
            <div className="col-md-7">
                <div className="right border p-3">
                    <div className="header">Order Summary</div>
                    <p>2 items</p>
                    <div className="row item mb-1 pb-1 border-bottom">
                        <div className="col-2 align-self-center">
                            <img className="img-fluid" src="https://dummyimage.com/100x100/000/fff&text=PREVIEW" alt="img"/></div>
                        <div className="col-9">
                            <div className="row text-muted">Product Name</div>
                            <div className="row"><b>$ 26.99</b></div>
                            <div className="row">Qty:
                                                <select name id className="p-1 ml-1">
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={2}>3</option>
                                    <option value={2}>4</option>
                                    <option value={2}>5</option>
                                    <option value={2}>6</option>
                                    <option value={2}>7</option>
                                    <option value={2}>8</option>
                                    <option value={2}>9</option>
                                    <option value={2}>10</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <div className="deletAction">
                                <i className="fa fa-trash" />
                            </div>
                        </div>
                    </div>
                    <div className="row item">
                        <div className="col-2 align-self-center">
                            <img className="img-fluid" src="https://dummyimage.com/100x100/000/fff&text=PREVIEW" alt="img"/></div>
                        <div className="col-9">
                            <div className="row text-muted">Product Name</div>
                            <div className="row"><b>$ 19.99</b></div>
                            <div className="row">Qty:
                                                    <select name id className="p-1 ml-1">
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={2}>3</option>
                                    <option value={2}>4</option>
                                    <option value={2}>5</option>
                                    <option value={2}>6</option>
                                    <option value={2}>7</option>
                                    <option value={2}>8</option>
                                    <option value={2}>9</option>
                                    <option value={2}>10</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <div className="deletAction">
                                <i className="fa fa-trash" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row lower">
                        <div className="col text-left">Subtotal</div>
                        <div className="col text-right">$ 46.98</div>
                    </div>
                    <div className="row lower">
                        <div className="col text-left">Taxes</div>
                        <div className="col text-right">0</div>
                    </div>
                    <div className="row lower">
                        <div className="col text-left">Discount</div>
                        <div className="col text-right">0</div>
                    </div>
                    <div className="row lower">
                        <div className="col text-left">Shipping</div>
                        <div className="col text-right">Free</div>
                    </div>
                    <div className="row lower  border-top border-bottom mb-3">
                        <div className="col text-left"><b>Total to pay</b></div>
                        <div className="col text-right"><b>$ 46.98</b></div>
                    </div>
                    <button type="button" className="btn btn-primary btn-block waves-effect waves-light mb-1">Proceed to Payment</button>
                    <p className="text-muted text-center">Complimentary Shipping &amp; Returns</p>
                </div>
            </div>
        );
    }
}

export default Ordersummary;