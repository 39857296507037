import { homeConstants } from "store/constants";
let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};
export function contact(state = initialState, action) {
  switch (action.type) {
    case homeConstants.CONTACT_REQUEST:
      return {
        loggedIn: true,
      };
    case homeConstants.CONTACT_SUCCESS:
      console.log(action);
      return {
        loggedIn: true,
        contact: action.contact
      };
    case homeConstants.CONTACT_FAILURE:
      if (user) {
        return {
          loggedIn: true,
          // user: action.user
        };
      } else {
        return {
          loggedIn: false
        };
      }
    default:
      return state;
  }
}
