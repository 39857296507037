import config from './config';
// import {authHeader} from './../store/helpers';

export const homeService = {
    getSliders,
    getServices,
    getBanners,
    getLogo,
    getFooter,
    newsletter,
    getCurrencies
}

function getSliders() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' }
    }

    return fetch(`${config.apiUrl}/website/home-slider`, requestOptions)
        .then(handleResponse).then(sliders => {
            return sliders;
        });
}

function getServices() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' }
    }

    return fetch(`${config.apiUrl}/website/service/`, requestOptions)
        .then(handleResponse).then(services => {
            return services;
        });
}

function getBanners() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' }
    }

    return fetch(`${config.apiUrl}/website/banners/`, requestOptions)
        .then(handleResponse).then(banners => {
            return banners;
        });
}

function getLogo() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' }
    }

    return fetch(`${config.apiUrl}/website/logo/`, requestOptions)
        .then(handleResponse).then(logo => {
            return logo;
        });
}

function getFooter() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' }
    }

    return fetch(`${config.apiUrl}/website/page-manager/`, requestOptions)
        .then(handleResponse).then(menu => {
            return menu;
        });
}

function newsletter(email,is_active) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({email,is_active})
    }
    return fetch(`${config.apiUrl}/website/email-subscriber/`, requestOptions)
        .then(handleResponse).then(data => {
            return data;
        });
}

function getCurrencies() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' }
    }

    return fetch(`${config.apiUrl}/website/currency/`, requestOptions)
        .then(handleResponse).then(currencies => {
            return currencies;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
