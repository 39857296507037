import config from "./config";

export const enquiryService = {
  postEnquiry
};

function postEnquiry(enquiry) {
  //console.log('enquiry', enquiry);
  var data = {
    "file": enquiry.file,
    "number": enquiry.number,
    "email": enquiry.email,
    "message": enquiry.message,
    "name": enquiry.name
  }
console.log(enquiry);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    //   headers: {
    //     // 'Content-Type': 'multipart/form-data'
    //     'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
    // },
    //   headers: {
    //     'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
    body: JSON.stringify(enquiry)
  };
 
  //console.log('requestOptions', requestOptions);


  return fetch(`${config.apiUrl}/website/product-enquiry/`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem("user", JSON.stringify(user));

      return response;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        window.location.reload(true);
      }

      if (response.status === 400) {
        // auto logout if 401 response returned from api
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
