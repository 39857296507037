import React, { Component } from 'react';
import Payment from 'components/forms/PaymentForm/Paymentform';
import Personal from 'components/forms/Personalform/Personalform';
import Shipping from 'components/forms/Shipping/Shipping';

import Steps from './Steps';
import Confirmation from './Confirmation';
import './Multistepform.css';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const countriesList = [
  { id: 0, name: "Canada" },
  { id: 1, name: "United States" },
  /*{ id: 2, name: "India" }*/
];
const stateList = [
  { id: 0, name: "California" },
  { id: 1, name: "Washington" }
]

class Mutistepform extends Component {

  constructor() {
    super()
    this.state = {
      step: 1,
      fname: '',
      billing_fname: '',
      fnameError: '',
      lname: '',
      billing_lname: '',
      lnameError: '',
      email: '',
      billing_email: '',
      emailError: '',
      address1: '',
      billing_address1: '',
      address2: '',
      billing_address2: '',
      addressError: '',
      country: '',
      billing_country: '',
      countryError: '',
      state: '',
      billing_state: '',
      stateError: '',
      city: '',
      billing_city: '',
      cityError: '',
      zip: '',
      billing_zip: '',
      zipError: '',
      phone: '',
      billing_phone: '',
      phoneError: '',
      isSameAddress: true,
      isExpanded: 'shipping',
      orderType: 'Company',
      billing_orderType: 'Company',
      companyName: '',
      billing_companyName: '',
      mailStop: '',
      billing_mailStop: '',
      website: '',
      billing_website: '',

      password: '',
      passwordError: '',
      confirm: '',
      confirmError: '',
      forename: '',
      forenameError: '',
      surname: '',
      surnameError: '',
      username: '',
      usernameError: '',
      postcode: '',
      postcodeError: '',
      terms: false,
      termsError: ''
    }
  }

  validate = () => {
    let isError = false;
    const errors = {
      fnameError: '',
      lnameError: '',
      emailError: '',
      addressError: '',
      countryError: '',
      stateError: '',
      cityError: '',
      zipError: '',
      phoneError: '',

      passwordError: '',
      confirmError: '',
      forenameError: '',
      surnameError: '',
      usernameError: '',
      termsError: ''
    }

    if (this.state.fname.length < 3) {
      isError = true;
      errors.fnameError = 'Please enter a valid first name';
    }

    if (this.state.lname.length < 3) {
      isError = true;
      errors.lnameError = 'Please enter a valid last name';
    }
    if (this.state.email.length < 3) {
      isError = true;
      errors.emailError = 'Please enter a valid email';
    }
    if (this.state.address1.length < 3) {
      isError = true;
      errors.addressError = 'Please enter a valid address';
    }
    if (this.state.country.length < 1) {
      isError = true;
      errors.countryError = 'Please enter a valid country';
    }
    if (this.state.state.length < 3) {
      isError = true;
      errors.stateError = 'Please enter a valid state';
    }
    if (this.state.city.length < 3) {
      isError = true;
      errors.cityError = 'Please enter a valid city';
    }
    if (this.state.zip.length < 3) {
      isError = true;
      errors.zipError = 'Please enter a valid zip';
    }
    if (this.state.phone.length < 10) {
      isError = true;
      errors.phoneError = 'Please enter a valid phone no';
    }

    // if (this.state.step > 1) {
    //   // if (this.state.password.length < 4) {
    //   //   isError = true;
    //   //   errors.passwordError = 'Password must be at least 4 characters long';
    //   // }

    //   // if (this.state.password !== this.state.confirm) {
    //   //   isError = true;
    //   //   errors.confirmError = 'Passwords must match';
    //   // }
    //   if (this.state.fname.length < 3) {
    //     isError = true;
    //     errors.fnameError = 'Please enter a valid first name';
    //   }

    //   if (this.state.lname.length < 3) {
    //     isError = true;
    //     errors.lnameError = 'Please enter a valid last name';
    //   }
    //   if (this.state.address.length < 3) {
    //     isError = true;
    //     errors.addressError = 'Please enter a valid address';
    //   }
    //   if (this.state.country.length < 1) {
    //     isError = true;
    //     errors.countryError = 'Please enter a valid country';
    //   }
    //   if (this.state.state.length < 3) {
    //     isError = true;
    //     errors.stateError = 'Please enter a valid state';
    //   }
    //   if (this.state.city.length < 3) {
    //     isError = true;
    //     errors.cityError = 'Please enter a valid city';
    //   }
    //   if (this.state.zip.length < 3) {
    //     isError = true;
    //     errors.zipError = 'Please enter a valid zip';
    //   }
    // }

    // if (this.state.step > 2) {
    //   if (this.state.forename.length < 1) {
    //     isError = true;
    //     errors.forenameError = 'Forename cannot be blank';
    //   }

    //   if (this.state.surname.length < 1) {
    //     isError = true;
    //     errors.surnameError = 'Surname cannot be blank';
    //   }

    //   if (this.state.username.length < 1) {
    //     isError = true;
    //     errors.usernameError = 'Username cannot be blank';
    //   }
    // }

    // if (this.state.step > 3) {
    //   if (this.state.terms === false) {
    //     isError = true;
    //     errors.termsError = 'Please accept the Terms and Conditions by ticking the checkbox';
    //   }
    // }

    this.setState({
      ...this.state,
      ...errors
    })

    return isError;
  }

  next(event) {
    event.preventDefault();

    const shippingDetails = {
      order_type: this.state.orderType,
      first_name: this.state.fname,
      last_name: this.state.lname,
      company_name: this.state.companyName,
      mail_stop: this.state.mailStop,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip_code: this.state.zip,
      country_name: this.state.country,
      email: this.state.email,
      phone_number: this.state.phone,
      billing_order_type: this.state.billing_orderType,
      billing_first_name: this.state.billing_fname,
      billing_last_name: this.state.billing_lname,
      billing_company_name: this.state.billing_companyName,
      billing_mail_stop: this.state.billing_mailStop,
      billing_address1: this.state.billing_address1,
      billing_address2: this.state.billing_address2,
      billing_city: this.state.billing_city,
      billing_state: this.state.billing_state,
      billing_zip_code: this.state.billing_zip,
      billing_country_name: this.state.billing_country,
      billing_email: this.state.billing_email,
      billing_phone_number: this.state.billing_phone,
      billing_website: this.state.billing_website,
      payment_status: false,
      is_deleted: false,
      website: this.state.website,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      isBillingAndShippingSame: this.state.isSameAddress
    };

    //console.log(shippingDetails);

    this.props.toggleShipping(shippingDetails);

    //const err = this.validate()
    // if (!err) {
    //   this.setState({
    //     //step: this.state.step + 1,
    //     fnameError: '',
    //     lnameError: '',
    //     addressError: '',
    //     countryError: '',
    //     stateError: '',
    //     cityError: '',
    //     zipError: '',
    //     passwordError: '',
    //     confirmError: '',
    //     forenameError: '',
    //     surnameError: '',
    //     usernameError: '',
    //     termsError: ''
    //   })
    // }
  }

  prev() {
    const err = this.validate()
    if (!err) {
      this.setState({ step: this.state.step - 1 })
    }
  }

  handleOnChange(e) {
    this.setState({ [e.target.id]: e.target.value })
  }

  handleOnCheck(e) {
    this.setState({ [e.target.id]: !this.state.terms })
  }

  handleCountryChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  handleStateChange = (e) => {
    this.setState({ state: e.target.value })
  }

  handleCheckboxChange = (e) => {
    this.setState({ isSameAddress: e.target.checked })
  }

  changeOrderType = (e) => {
    this.setState({ orderType: e.target.value })
  }

  handleChange = (panel) => (event, isExpanded) => {
    isExpanded ?
      this.setState({ isExpanded: panel }) : this.setState({ isExpanded: false })
  };

  loadShipping = (prefix='') => {
    const {
      [prefix+'fname']: fname,
      [prefix+'lname']: lname,
      [prefix+'email']: email,
      [prefix+'address1']: address1,
      [prefix+'address2']: address2,
      [prefix+'country']: country,
      [prefix+'state']: state,
      [prefix+'city']: city,
      [prefix+'zip']: zip,
      [prefix+'phone']: phone,
      [prefix+'companyName']: companyName,
      [prefix+'mailStop']: mailStop,
      [prefix+'orderType']: orderType,
      [prefix+'website']: website,
      isSameAddress,
      fnameError, lnameError, addressError, countryError,
      stateError, cityError, zipError, phoneError

    } = this.state;

    return (
      <>
        {/* <Steps step={this.state.step} /> */}
        <Shipping
          fname={fname}
          lname={lname}
          email={email}
          address1={address1}
          address2={address2}
          country={country}
          state={state}
          city={city}
          zip={zip}
          phone={phone}
          isSameAddress={isSameAddress}
          countries={countriesList}
          states={stateList}
          companyName={companyName}
          mailStop={mailStop}
          orderType={orderType}
          website={website}

          fnameError={fnameError}
          lnameError={lnameError}
          addressError={addressError}
          countryError={countryError}
          stateError={stateError}
          cityError={cityError}
          zipError={zipError}
          phoneError={phoneError}

          onChange={this.handleOnChange.bind(this)}
          onCountryChange={this.handleCountryChange.bind(this)}
          onStateChange={this.handleStateChange.bind(this)}
          onOrderTypeChange={this.changeOrderType.bind(this)}
          onSelectCheckbox={this.handleCheckboxChange.bind(this)}

          next={this.next.bind(this)}

          prefix={prefix}
        />
      </>)
  }

  render() {
    return (
      <>
        {/* Shipping  */}
        <Accordion expanded={this.state.isExpanded === 'shipping'} onChange={this.handleChange('shipping')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ background: 'rgb(234, 222, 222)' }}
          >
            <Typography >Shipping Address</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {this.loadShipping()}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Billing */}
        <Accordion disabled={this.state.isSameAddress} expanded={this.state.isExpanded === 'billing' || !this.state.isSameAddress} onChange={this.handleChange('billing')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ background: 'rgb(234, 222, 222)' }}
          >
            <Typography >Billing Address</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {this.loadShipping('billing_')}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>)

    // switch (this.state.step) {
    //   case 1:
    //     return <div>
    //       <Steps step={this.state.step} />
    //       <Shipping
    //         fname={this.state.fname}
    //         lname={this.state.lname}
    //         address={this.state.address}
    //         country={this.state.country}
    //         state={this.state.state}
    //         city={this.state.city}
    //         zip={this.state.zip}
    //         fnameError={this.state.fnameError}
    //         lnameError={this.state.lnameError}
    //         addressError={this.state.addressError}
    //         countryError={this.state.countryError}
    //         stateError={this.state.stateError}
    //         cityError={this.state.cityError}
    //         zipError={this.state.zipError}
    //         onChange={this.handleOnChange.bind(this)}
    //         next={this.next.bind(this)}
    //       />
    //     </div>
    //   case 2:
    //     return <div>
    //       <Steps step={this.state.step} />
    //       <Personal
    //         fname={this.state.fname}
    //         lname={this.state.lname}
    //         address={this.state.address}
    //         country={this.state.country}
    //         state={this.state.state}
    //         city={this.state.city}
    //         zip={this.state.zip}
    //         fnameError={this.state.fnameError}
    //         lnameError={this.state.lnameError}
    //         addressError={this.state.addressError}
    //         countryError={this.state.countryError}
    //         stateError={this.state.stateError}
    //         cityError={this.state.cityError}
    //         zipError={this.state.zipError}
    //         onChange={this.handleOnChange.bind(this)}
    //         next={this.next.bind(this)}
    //         prev={this.prev.bind(this)}
    //       />

    //     </div>
    //   case 3:
    //     return <div>
    //       <Steps step={this.state.step} />
    //       <Payment
    //         forename={this.state.forename}
    //         surname={this.state.surname}
    //         username={this.state.username}
    //         forenameError={this.state.forenameError}
    //         surnameError={this.state.surnameError}
    //         usernameError={this.state.usernameError}
    //         onChange={this.handleOnChange.bind(this)}
    //         next={this.next.bind(this)}
    //         prev={this.prev.bind(this)} />
    //     </div>
    //   case 4:
    //     return <Confirmation />
    //   default:
    //     return null
    // }
  }
}

export default Mutistepform;
