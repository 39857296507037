import { userConstants } from '../constants';
let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      if (user) {
        return {
          loggedIn: true,
          user: action.user
        };
      } else {
        return {
          loggedIn: false
        };
      }
      case userConstants.REGISTER_REQUEST:
        return {
          loggedIn: true,
          user: action.user
        };
      case userConstants.REGISTER_SUCCESS:
        return {
          loggedIn: true,
          user: action.user
        };
      case userConstants.REGISTER_FAILURE:
        if(user){
          return {
            loggedIn: true,
            user: action.user
          };
        }else{
          return {
            loggedIn: false
          };
        }
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}