import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Thankyou.css";

class Thankyou extends Component {


    componentDidMount() {
        localStorage.removeItem("total");
        localStorage.removeItem("shipAmt");
    }

    render() {

        return (
            <div>
                <div className="jumbotron text-center">
                    <h1 className="display-3">Thank You!</h1>
                    <p className="lead"><strong>Your Order has been Placed.</strong> Please check your email.</p>
                    <p className="display-5">Order id: <span>{localStorage.getItem('transaction_id')} </span></p>
                    <hr />
                    <p>
                        {/* Having trouble? <a href="">Contact us</a> */}
                        Having trouble? <Link to="/contact">Contact Us</Link>
                    </p>
                    <br />
                    <p className="lead">
                        {/* <a className="btn btn-primary btn-sm" href="#" role="button">Continue to homepage</a> */}
                        <Link role="button" className="btn btn-primary btn-sm" to="/">Continue to homepage</Link>
                    </p>
                </div>
            </div>
        );
    }
}

export default Thankyou;
