import React, { Component } from "react";
import "./Profile.css";
import { connect } from "react-redux";
import { userActions } from "store/actions";
import * as $ from 'jquery';

class Profile extends Component {
    constructor(props) {
        super(props);
        document.title = "Dwight | Profile";

        this.state = {
            user: {
                email: this.props.email,
                name: this.props.name,
                company: this.props.company,
                phone: this.props.phone_number,
                street: this.props.street,
                city: this.props.city,
            },
            submitted: false,

            // profileImage:this.props.profileImage,
            // msg:this.props.msg,
            // uploadedFile:null
        }
        this.profileData = '';

        this.profileData = this.props;

        this.nodes = {};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(userActions.userProfile());
        //Form editing is disabled for now.
        $('#form1 input').attr('readonly', 'readonly');
    }
    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const { user } = this.state;
        if (user.name && user.email && user.company && user.phone && user.password && user.rePassword) {
            if (user.password !== user.rePassword) {
                return 0;
            } else {

                //Todo: Make API Call here.
                this.props.register(user);
            }
        }
    }

    setNodeRef(provider, node) {
        if (node) {
            this.nodes[provider] = node;
        }
    }

    render() {

        const { user, submitted } = this.state;

        return (
            <section>
                <div className="container  box-shadow">
                    <div className="row pt-5 pb-5">
                        <div className="col-lg-12">
                            <div className="title mb-3">
                                <h2>My Profile</h2>
                            </div>
                        </div>
                        <div className="col-lg-12 border p-4">
                            <div className="inner contact-right">
                                <form action="#" id="form1" method="post" onSubmit={this.handleSubmit} className="signin-form">
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div className={'form-group' + (submitted && !user.name ? ' has-error' : '')}>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Your Name*"
                                                    value={this.props.profile && this.props.profile.profile && this.props.profile.profile.name}
                                                    className="contact-input"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {submitted && !user.name &&
                                                    <div className="help-block">Name is required</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Your Email*"
                                                    className="contact-input"
                                                    required=""
                                                    value={this.props.profile && this.props.profile.profile && this.props.profile.profile.email}
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {submitted && !user.email &&
                                                    <div className="help-block">Email is required</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div className={'form-group' + (submitted && !user.name ? ' has-error' : '')}>
                                                <input
                                                    type="text"
                                                    name="company"
                                                    id="company"
                                                    placeholder="Company*"
                                                    className="contact-input mr-2"
                                                    required=""
                                                    value={this.props.profile && this.props.profile.profile && this.props.profile.profile.company}
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {submitted && !user.company &&
                                                    <div className="help-block">Company is required</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="Phone*"
                                                    value={this.props.profile && this.props.profile.profile && this.props.profile.profile.phone_number}
                                                    className="contact-input"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {submitted && !user.phone &&
                                                    <div className="help-block">Phone is required</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="street"
                                                    id="street"
                                                    placeholder="Street"
                                                    className="contact-input"
                                                    value={this.props.profile && this.props.profile.profile && this.props.profile.profile.street}
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    placeholder="City"
                                                    className="contact-input"
                                                    value={this.props.profile && this.props.profile.profile && this.props.profile.profile.city }
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
return state

    // if (state.profile && state.profile.profile) {
    //     return {
    //         name: state.profile.profile.name,
    //         email: state.profile.profile.email,
    //         company: state.profile.profile.company,
    //         phone: state.profile.profile.phone_number,
    //         street: state.profile.profile.street,
    //         city: state.profile.profile.city,
    //         // profileImage: state.profile.profileImage,
    //         // msg: 'state.pro.msg'
    //     }

    // }

}

export default connect(mapStateToProps)(Profile)
