import { shopConstants } from 'store/constants/shop.constants';

export function shopProducts(state = {}, action) {
    switch (action.type) {
        case shopConstants.SHOP_CATEGORY_PRODUCTS_REQUEST:
            return {
                loading: true
            }
        case shopConstants.SHOP_CATEGORY_PRODUCTS_SUCCESS:
            return {
                loading: false,
                shopProducts: action.categoryProducts.results,
                categoryId: action.categoryId
            }

        case shopConstants.SHOP_CATEGORY_PRODUCTS_FAILURE:
            return {
                error: action.error
            }
        default:
            return state;
    }
}