import config from "./config";
import { authHeader } from "../store/helpers";

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  googleLogin,
  facebookLogin,
  getProfile,
  forgotpassword,
  resetpassword,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${config.apiUrl}/auth/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    });
}



function forgotpassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email }),
  };

  return fetch(`${config.apiUrl}/auth/request-reset-email/`, requestOptions)
    .then(handleResponse)
    .then(() => {
      // email sent successfully
      // an error occurred while sending the email
      return "Password reset link sent successfully!";
    })
    .catch(() => {
      
      return false;
    });
}

function resetpassword(password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ password: password }),
  };
  return fetch(`${config.apiUrl}/auth/password-reset/<uidb64>/<token>/`, requestOptions)
    .then(handleResponse)
    .then(() => {
      // email sent successfully
      alert("Password reset link sent successfully!");
      return true;
    })
    .catch(() => {
      // an error occurred while sending the email
      return false;
    });
}


function logout() {
  // remove user from local storage to log user out
  localStorage.clear();
  return;
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/auth/signup`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    });
}


// function forgotpassword(email) {
//  const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(email),
//   };
//   console.log(email);
//   return fetch(`${config.apiUrl}/auth/request-reset-email`, requestOptions)
//     .then(handleResponse)
//     .then((user) => {
//       // store user details and jwt token in local storage to keep user logged in between page refreshes
//       localStorage.setItem("email", JSON.stringify(user));

//       return user;
//     });
// }









function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}


function googleLogin(auth_token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(auth_token)
  }

  return fetch(`${config.apiUrl}/social_auth/google/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));

      return user;
    });
}

function facebookLogin(accessToken) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(accessToken)
  }

  return fetch(`${config.apiUrl}/social_auth/facebook/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));

      return user;
    });
}

function getProfile() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/auth/profile`, requestOptions).then(handleResponse).then(profileData => {
    localStorage.setItem('profile', JSON.stringify(profileData.results))
    return profileData;
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      if (response.status === 400) {
        // auto logout if 401 response returned from api
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
