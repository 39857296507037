import { homeConstants } from '../constants';
export function serviceDetails(state = {}, action) {
    switch (action.type) {
        case homeConstants.SERVICE_DETAILS_REQUEST:
            return {
                loading: true
            }
        case homeConstants.SERVICE_DETAILS_SUCCESS:
            return {
                loading: false,
                items: action.service
            }

        case homeConstants.SERVICE_DETAILS_FAILURE:
            return {
                error: action.error
            }
            default:
                return state;
    }
}