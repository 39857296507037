import React, { Component } from 'react';
import $ from 'jquery';
import config from 'services/config';
import Zoom from 'react-img-zoom'

class CustomSlider extends Component {
    constructor(props) {
        super(props)
        document.title = "Realtime-7 | Custom Slider";
    }

    componentDidMount() {
        $('#lightSlider').lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            slideMargin: 0,
            thumbItem: 9
        });
    }

    renderImages = () => {
        this.pageData = this.props.productData.items;
        let imageSrc;
        this.pageData.images.unshift({ product: this.pageData.name, image: this.pageData.logo })
        const slider = this.pageData.images.map((item, index) => {
            { imageSrc = null }
            { imageSrc = (item.image && item.image.startsWith("http")) ? item.image : config.apiUrl + item.image }

            if (item.image !== null) {
                return (<li
                    key={item.product + index}
                    data-thumb={imageSrc}>
                    <Zoom
                        img={imageSrc}
                        zoomScale={2}
                        width={450}
                        height={450}
                        zoomStyle={{ width: '100%', height: '100%' }}
                        />
                </li>)
            }

            // return (
            //     // <li
            //     //     key={item.product + index}
            //     //     data-thumb={index === 0 ? item.image : config.apiUrl + item.image}
            //     //     className="clone sliderImage">
            //     //     <img src={index === 0 ? item.image : config.apiUrl + item.image}
            //     //         className="w-100" alt="img" />
            //     // </li>

        });

        // this.pageData.videos?.forEach((item, index) => {
        //     slider.push(
        //         <li key={item.product + index} data-thumb={'https://icons-for-free.com/iconfiles/png/512/play+button+play+record+record+icon-1320191267565063591.png'}>
        //             <iframe width="489" height="367"
        //                 src={item.video}>
        //             </iframe>
        //         </li>
        //     )
        // });

        return (
            <React.Fragment>
                { slider}
            </React.Fragment >
        )
    }

    render() {
        return (
            <div className="col-md-5 pr-2">
                <div className="card">
                    <div className="lSSlideOuter ">
                        <div className="lSSlideWrapper usingCss">
                            <ul id="lightSlider" className="lightSlider lsGrab lSSlide lsGrabElementEffc">
                                {this.renderImages()}
                                {/* <li data-thumb="https://i.imgur.com/KZpuufK.jpg" className="clone sliderImage"> <img src="https://i.imgur.com/KZpuufK.jpg" className="w-100" alt="img" /> </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default CustomSlider;