import { homeConstants } from './../constants';

export function subscribers(state = {}, action) {
    switch (action.type) {
        case homeConstants.NEWSLETTER_REQUEST:
            return {
                loading: true
            }
        case homeConstants.NEWSLETTER_SUCCESS:

        console.log('newslettter',action)
            return {
                loading: false,
                items: action.newsletter
            }

        case homeConstants.NEWSLETTER_FAILURE:
            return {
                error: action.error
            }
        default:
            return state;
    }

}