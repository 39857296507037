import DxfItems from 'components/DfxItems/DxfItems';
import StlTabs from 'components/StlTabs/StlTabs';
import React, {Suspense } from 'react';
import DXFViewer from '../../components/DXF/DXF';

class DXF extends React.Component {
  constructor(props){
    super(props);
    document.title = "Dwight | DXF Viewer";
  }

  render() {
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <DXFViewer/>
          <DxfItems/>
          <StlTabs/>
        </Suspense>
      </div>
    );
  }
}

export default DXF;