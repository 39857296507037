export const homeConstants = {
    SLIDER_REQUEST: "SLIDER_REQUEST",
    SLIDER_SUCCESS: "SLIDER_SUCCESS",
    SLIDER_FAILURE: "SLIDER_FAILURE",

    SERVICES_REQUEST: "SERVICES_REQUEST",
    SERVICES_SUCCESS: "SERVICES_SUCCESS",
    SERVICES_FAILURE: "SERVICES_FAILURE",

    BANNER_REQUEST: "BANNER_REQUEST",
    BANNER_SUCCESS: "BANNER_SUCCESS",
    BANNER_FAILURE: "BANNER_FAILURE",

    LOGO_REQUEST: "LOGO_REQUEST",
    LOGO_SUCCESS: "LOGO_SUCCESS",
    LOGO_FAILURE: "LOGO_FAILURE",

    FOOTER_REQUEST: "FOOTER_REQUEST",
    FOOTER_SUCCESS: "FOOTER_SUCCESS",
    FOOTER_FAILURE: "FOOTER_FAILURE",

    NEWSLETTER_REQUEST:"NEWSLETTER_REQUEST",
    NEWSLETTER_SUCCESS:"NEWSLETTER_SUCCESS",
    NEWSLETTER_FAILURE:"NEWSLETTER_FAILURE",

    CONTACT_REQUEST:"CONTACT_REQUEST",
    CONTACT_SUCCESS:"CONTACT_SUCCESS",
    CONTACT_FAILURE:"CONTACT_FAILURE",

    SERVICE_DETAILS_REQUEST:"SERVICE_DETAILS_REQUEST",
    SERVICE_DETAILS_SUCCESS:"SERVICE_DETAILS_SUCCESS",
    SERVICE_DETAILS_FAILURE:"SERVICE_DETAILS_FAILURE",

    CURRENCY_REQUEST:'CURRENCY_REQUEST',
    CURRENCY_SUCCESS:'CURRENCY_SUCCESS',
    CURRENCY_FAILURE:'CURRENCY_FAILURE',

};
