import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import { connect } from "react-redux";
import "js/common";
import { history } from "./store/helpers";
import "./assets/css/stylestarter.css";
import Home from "./pages/Home/Home";
//import Loader from "./layout/Loader/Loader";
import Topbar from "./layout/Topbar/Topbar";
import Footer from "./layout/Footer/Footer";
//Load product slider js & cs after jquery is loaded
import "./assets/css/lightslider.css";
import "./assets/js/lightslider";
import FabricationService from "./pages/Fabrication/Fabrication";
import DesignService from "./pages/Design/Design";
import ProductDetails from "./pages/Product/ProductDetails/ProductDetails";
import Contact from "./pages/Contact/Contact";
import Registration from "./pages/Registration/Registration";
import Forgotpassword from "./pages/Forgotpassword/Forgotpassword";
import Resetpassword from "./pages/Resetpassword/Resetpassword";
import Login from "./pages/Login/Login";
// import StlViewer from 'components/StlViewer/StlViewer';
import StlLoader from "pages/StlLloader/StlLoader";
import DXF from "./pages/DXF/DXF";
import Addressdetails from "./pages/Addressdetails/Addressdetails";
import Profile from "pages/Profile/Profile";
import Shop from "pages/Shop/Shop";
import ShopBanners from "pages/Shop/ShopBanners/ShopBanners";
import AppContext from "contexts/AppContext";
import ServiceDetails from "pages/ServiceDetails/ServiceDetails";
import Checkoutpage from "pages/Checkoutpage/Checkoutpage";
//import Payment from "pages/Payment/Payment";
import Sluggable from "components/DynamicPage/Sluggable";
import ScrollToTop from "ScrollToTop";
import Thankyou from "pages/Thankyou/Thankyou";
import ReactGA from 'react-ga';
import config from 'services/config';
import GARouter from "utils/GARouter";

class App extends Component {

  static context = AppContext;
  setCartItems = (cartItems) => {
    this.setState({ cartItems });
  };

  constructor(props) {
    super(props);
    document.title = "Realtime-7";
    this.state = {
      cartItems: this.context,
      setCartItems: this.setCartItems,
    };
    ReactGA.initialize(config.GOOGLE_ANALYTICS);
  }

  componentDidMount() {
    var populateMissingContextData = JSON.parse(localStorage.getItem("cartItems")) != null
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [];
    this.setState({ cartItems: populateMissingContextData })
    //console.log(populateMissingContextData)
  }


  render() {
    return (
      <AppContext.Provider value={this.state}>
        <Router history={history}>
          <ScrollToTop />
          <div>
            <GARouter />
            <Topbar history={history} />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/page/:slug" component={Sluggable} />
              <Route path="/fabricationservice" component={FabricationService} />
              <Route exact path="/shop/:id" component={Shop} />
              <Route path="/products" component={Shop} />
              <Route exact path="/designservice/:id" component={Shop} />
              <Route path="/designservice" component={DesignService} />
              <Route path="/contact" component={Contact} />
              <Route path="/registration" component={Registration} />
              <Route path="/forgotpassword" component={Forgotpassword} />
              <Route path="/resetpassword" component={Resetpassword} />
              <Route path="/password-reset/:uidb64/:token/" component={Resetpassword} />
              <Route path="/login" component={Login} />
              <Route path="/product-details/:id" component={ProductDetails} />
              <Route path="/shop/:cid/product-details/:pid" component={ProductDetails} />
              <Route path="/design/:cid/product-details/:pid" component={ProductDetails} />
              <Route path="/stlloader" component={StlLoader} />
              <Route path="/dxf" component={DXF} />
              <Route path="/addressdetails" component={Addressdetails} />
              <Route path="/profile" component={Profile} />
              <Route path="/category/:slug" component={ShopBanners} />
              <Route path="/servicedetails/:id" component={ServiceDetails} />
              <Route path="/checkout" component={Checkoutpage} />
              <Route path="/thankyou" component={Thankyou} />
            </Switch>
            <Footer />
          </div>
        </Router>

      </AppContext.Provider>
    );
  }
}

export default App;
