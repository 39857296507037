import React, { Component } from 'react';

class Steps extends Component {

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="form-steps">
                        <h4 className={"form-step-list " + (this.props.step === 1 ? 'active' : '')}>Shipping Address</h4> 
                        <p><strong>All fields marked with * are required</strong></p>
                    </div>
                </div>
                {/* <div className={"col-md-4 " + (this.props.step === 2 ? 'active' : '')}>
                    Personal</div>
                <div className={"col-md-4 " + (this.props.step === 3 ? 'active' : '')}>
                    Payment</div> */}

            </div>
        )
    }
}

export default Steps;
