import { homeConstants } from './../constants';
export function services(state = {}, action) {
    switch (action.type) {
        case homeConstants.SERVICES_REQUEST:
            return {
                loading: true
            }
        case homeConstants.SERVICES_SUCCESS:
            return {
                loading: false,
                items: action.services
            }

        case homeConstants.SERVICES_FAILURE:
            return {
                error: action.error
            }
            default:
                return state;
    }
}