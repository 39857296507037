import React, { Component } from "react";
import "./Resetpassword.css";
import { connect } from "react-redux";
import { userActions } from "../../store/actions";
import { FACEBOOK_APP_ID, GOOGLE_APP_ID } from "./../../store/constants/index";

class Resetpassword extends Component {
  constructor(props) {
    super(props);
    document.title = "Dwight | Resetpassword";
    this.state = {
      name: "",
      email: "",
      company: "",
      phone_number: "",
      street: "",
      city: "",
      password: "",
      rePassword: "",
      submitted: false,
      ulib: "", // initialize ulib state
      token: "", // initialize token state
    };
    this.nodes = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const ulib = searchParams.get("ulib");
    const token = searchParams.get("token");
    console.log(token)
    this.setState({ ulib, token }); // set ulib and token in state
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { password } = this.state;
    // console.log(ulib);
    // console.log(ulib);

    if (password) {
      const { ulib, token } = this.state;
      this.props.dispatch(userActions.resetpassword(password, ulib, token)); // pass ulib and token to the resetpassword action
    }
  }

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node;
    }
  }

  render() {
    const { email, submitted } = this.state;

    return (
      <section>
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-lg-6 border p-4">
              <div className="inner contact-right">
                <h4 className="mb-2">Forgot Password !</h4>
                <p className="card-text">
                  Enter your email address below and we'll send you a link to reset your password.
                </p>
                <form action="#" method="post" onSubmit={this.handleSubmit} className="signin-form">
                  <div className="input-grids">
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Your password*"
                        className="contact-input"
                        required=""
                        onChange={(e) => this.handleChange(e)}
                      />
                      {submitted && !password && <div className="help-block">Please enter a valid email address</div>}
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="btn btn-primary theme-button" onClick={this.handleSubmit}>
                      Reset Link
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner ">
                <img src="assets/images/signup-bg.png" alt="" className="w-100" />
                <hr />
              </div>
            </div>
          </div>
        </div>
      </section>
   

    );
  }
}

const mapStateToProps=(state)=>{
  return { user:state.user };
}

export default connect(mapStateToProps)(Resetpassword);
