export const fetchWithDelay = (url) => {
  const promise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(fetch(url, {
        method: 'GET',
      })
        .then((response) => response.json()));
    }, 3000)
  });

  return promise;
}

export const postWithDelay = (url, postData) => {
  console.log(postData)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(postData)
  };
  const promise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(fetch(url, requestOptions)
        .then((response) =>
          response.json()
        ));
      reject((error) => error)
    }, 3000)
  });

  return promise;
}