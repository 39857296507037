import React, { createContext } from 'react'

// const CurrencyContext = createContext({
//     currentCurrency: localStorage.getItem('currency') != null ? localStorage.getItem('currency') : 'CAD',
//     setCurrency: () => { },
// });

const CurrencyContext = createContext(JSON.parse(localStorage.getItem('currency')) != null ? JSON.parse(localStorage.getItem('currency')).value : 'CAD');

export default CurrencyContext
