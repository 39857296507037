import Ordersummary from 'components/Ordersummary/Ordersummary';
import Shipingaddress from 'components/Shipingaddress/Shipingadress';
import React, { Component } from 'react';
import './Addressdetails.css';

class Addressdetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        document.title = "Dwight | Address Details";
    }

    // componentWillMount(){}
    // componentDidMount(){}
    // componentWillUnmount(){}

    // componentWillReceiveProps(){}
    // shouldComponentUpdate(){}
    // componentWillUpdate(){}
    // componentDidUpdate(){}

    render() {
        return (
            <section>
                <div className="container mt-4 mb-3">
                    <div className="row">
                        <nav aria-label="breadcrumb ">
                            <ol className="breadcrumb bg-transparent">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Address &amp; Details</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="mt-5 mb-4">
                        {/*Grid row*/}
                        <div className="row">
                            <Shipingaddress />
                            <Ordersummary/>
                        </div>
                    </section>
                </div>
            </section>
        );
    }
}

export default Addressdetails;