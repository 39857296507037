import React, { Component } from "react";
import "./ServiceDetails.css";
import { connect } from 'react-redux';
import { homeActions } from 'store/actions';
import Loader from "layout/Loader/Loader";
class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    document.title = "Dwight | Service Detailed Page";
    // this.props.dispatch(homeActions.getServices()) ; 
    // console.log(this.props.location.state.id)
    // var a = new URLSearchParams(this.props.location.search).get("id");
    const serviceId = this.props.match.params.id;
    // var urlSegment = window.location.pathname;
    // const serviceId = urlSegment.substring(urlSegment.lastIndexOf('/') + 1)
    this.props.dispatch(homeActions.getServiceDetails(serviceId));
  }

  render() {
    if (this.props.serviceDetails.loading) return (<Loader />);
    return (
      <section>
        {this.props.serviceDetails && this.props.serviceDetails.items && this.props.serviceDetails.items[0] &&
        <>
           <div className="shopBanner">
            <img src={this.props.serviceDetails.items[0].image}
              className="w-100" alt="img"
            />
            <h1>{this.props.serviceDetails.items[0].service_name}</h1>
          </div>
          <div className="container-fluid mb-3">
              <div className="row no-gutters">              
                
                <div className="col-md-12 bg-light pt-md-5">
                   <div className="serviceDetailsInfo pl-md-4 pr-md-4">
                       <h3 className="mb-3">{this.props.serviceDetails.items[0].service_name}</h3>
                       <div className="serviceDescripition">
                            <p dangerouslySetInnerHTML={{ __html: this.props.serviceDetails.items[0].service_description }} />
                       </div>
                   </div>
                </div>
              </div>
            </div>
            </>
        }
      </section>

    );
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(ServiceDetails);
// export default ServiceDetails;
