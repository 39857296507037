import { homeConstants } from './../constants';

export function home(state = {}, action) {
    switch (action.type) {
        case homeConstants.SLIDER_REQUEST:
            return {
                loading: true
            }
        case homeConstants.SLIDER_SUCCESS:
            return {
                loading: false,
                items: action.sliders
            }

        case homeConstants.SLIDER_FAILURE:
            return {
                error: action.error
            }
        default:
            return state;
    }

}