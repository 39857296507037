import AppContext from 'contexts/AppContext';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
//import { Nav } from 'react-bootstrap';
//import { NavLink } from 'react-router-dom';
import { history } from 'store/helpers';
import './CartItemsList.css';
import ReactGA from "react-ga";
import config from 'services/config';
class CartItemsList extends Component {
    static context = AppContext;
    constructor(props, context) {

        super(props, context);
        this.state = {
            cartItems: '',
            subTotal: 0,
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            this.setState({
                cartItems: JSON.parse(localStorage.getItem("cartItems")) != null
                    ? JSON.parse(localStorage.getItem("cartItems"))
                    : []
            })
            // if (JSON.parse(localStorage.getItem("cartItems")) !== null) {
            //     let cartData = JSON.parse(localStorage.getItem("cartItems"));
            //     console.log(typeof (cartData));
            //     cartData.items = [];
            //     cartData.subTotal = 0;
            //     cartData.total = 0;
            //     cartData.items.push(cartData[0]);

            //     this.setState({
            //         cartItems: cartData !== null
            //             ? cartData
            //             : []
            //     })
            //     console.log(cartData.items)
            // }

        }
    }

    render() {

        return (
            <AppContext.Consumer>
                {({ cartItems, setCartItems }) => (
                    <section className="cartQuickView" >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12">

                                    {/* {cartItems = (cartItems === undefined || cartItems === null) ? this.state.cartItems : this.state.cartItems} */}

                                    {this.state.cartItems && this.state.cartItems !== null && cartItems.items && (cartItems.items.map((item, index) => (
                                        <>
                                            {/* {console.log("inside iffffffffff")} */}
                                            <div className="CartItems d-flex flex-column">
                                                <div className="ItemBox d-flex mt-2 border-bottom pb-2 justify-content-between">
                                                    <div className="item-img pr-2">  
                                                        {/* <img src={item.item.logo} width="75px" className="mr-2" alt="logo" /> */}
                                                        <img
                                                            className="mr-2"
                                                            src={config.img_url + item.item.logo}
                                                            alt="logo"
                                                        />
                                                    </div>
                                                    <div className="item-cont">
                                                        {/* {console.log(item)} */}

                                                        {(item.addedFrom === 'productpage' && item.selectedItem !== '') ?
                                                            <div>
                                                                <p><Link to={`/shop/software/product-details/${item.item.slug}`}>{item.item.name}</Link>
                                                                    <span className="item-count">
                                                                        &nbsp;{item.count}</span> x <span>${item.selectedItem.price}
                                                                    </span>
                                                                </p>
                                                                <p> Material : {item.selectedItem.material} </p>
                                                                <p> Size : {item.selectedItem.size}</p>
                                                            </div> :
                                                            <>
                                                                {(item.addedFrom === 'shoppage' && item.isVariation === true) ?
                                                                    <div>
                                                                        <p><Link to={`/shop/software/product-details/${item.item.slug}`}>{item.item.name}</Link>
                                                                            <span className="item-count">
                                                                                &nbsp;{item.count}</span> x <span>${item.selectedItem.price}
                                                                            </span>
                                                                        </p>
                                                                        <p> Material : {item.selectedItem.material} </p>
                                                                        <p> Size : {item.selectedItem.size}</p>
                                                                    </div> :
                                                                    <div>
                                                                        <p><Link to={`/shop/software/product-details/${item.item.slug}`}>{item.item.name}</Link>
                                                                            <span className="item-count">
                                                                                &nbsp;{item.count}</span> x <span>${item.item.unitPrice}
                                                                            </span>
                                                                        </p>
                                                                    </div>}
                                                            </>
                                                        }
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-times" name={index}
                                                            onClick={(e) => {
                                                                let itemIndex = e.target.attributes.name.nodeValue;
                                                                cartItems.items.splice(itemIndex, 1);
                                                                this.setState({
                                                                    cartItems: cartItems
                                                                });
                                                                let subTotal = 0;
                                                                let total = 0;
                                                                for (var i = 0; i < cartItems.items.length; i++) {
                                                                    // subTotal += cartItems.items[i].count * cartItems.items[i].item.unitPrice;
                                                                    subTotal += cartItems.items[i].count * cartItems.items[i].productPrice;
                                                                    total = subTotal;
                                                                    cartItems.subTotal = subTotal;
                                                                    cartItems.total = total;
                                                                }
                                                                localStorage.setItem("total", total)

                                                                if (cartItems.items.length == 0) {
                                                                    cartItems.subTotal = 0;
                                                                    cartItems.total = 0;
                                                                }
                                                                localStorage.setItem('cartItems', JSON.stringify(cartItems));
                                                                setCartItems(cartItems)
                                                            }}></i>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )))
                                    }
                                </div>
                            </div>
                            {
                                cartItems && cartItems.items && cartItems.items.length > 0 ?
                                    <>
                                        <span style={{ visibility: 'hidden' }}>
                                            {cartItems.subTotal = 0}
                                        </span>
                                        {cartItems.items.forEach(element => {
                                            cartItems.subTotal += element.count * element.productPrice;
                                        })}
                                        <span style={{ visibility: 'hidden' }}>
                                            {cartItems.total = cartItems.subTotal}
                                            {localStorage.setItem("total", cartItems.total)}
                                        </span>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="inner text-dark totalSubtotal">
                                                    <div className="subTotal d-flex justify-content-end p-2 white-border-bottom">
                                                        <div className="mr-3">Sub-Total : </div>
                                                        <div className="">$ {cartItems.subTotal}</div>
                                                    </div>
                                                    <div className="cartTotal d-flex justify-content-end p-2">
                                                        <div className="mr-3">Total</div>
                                                        <div className="">$ {cartItems.total}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row pt-4 d-flex align-items-center bg-light-blue">
                                            <div className="col-sm-12 order-md-2 text-right">
                                                <Link to={{
                                                    pathname: '/checkout',
                                                    state: { cartItems: cartItems }
                                                }} onClick={(e) => {
                                                    ReactGA.event({
                                                        category: 'User',
                                                        action: 'Clicked on Checkout button'
                                                      });
                                                    history.push({
                                                        pathname: '/checkout/',
                                                        state: { cartItems: this.cartItems }
                                                    })
                                                }}>
                                                    <span className="btn btn-primary mb-4 btn-md btn-block">
                                                        <i className="fa fa-shopping-cart mr-2"> </i> Checkout
                                                    </span>
                                                </Link>

                                            </div>
                                        </div>
                                    </> :
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="inner text-dark totalSubtotal">
                                                <div className="subTotal d-flex justify-content-end p-2 white-border-bottom">
                                                    No item(s) in cart
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </section>

                )}
            </AppContext.Consumer>

        );
    }

}

export default CartItemsList;