import { homeConstants, promotionsConstants } from './../constants';
import { homeService } from '../../services';

export const homeActions = {
    getSliders,
    getServices,
    getBanners,
    getLogo,
    getFooter,
    newsletter,
    getServiceDetails,
    getCurrencies
}

function getSliders() {
    return dispatch => {
        dispatch(request());
        homeService.getSliders().then(sliders => {
                dispatch(success(sliders));
            },
                error => {
                    dispatch(failure(error.toString()));
                })
    }

    function request() {
        return {
            type: homeConstants.SLIDER_REQUEST,
        }
    }

    function success(sliders) {
        return {
            type: homeConstants.SLIDER_SUCCESS,
            sliders
        }
    }

    function failure(error) {
        return {
            type: homeConstants.SLIDER_FAILURE,
            error
        }
    }
}

function getServices() {
    return dispatch => {
        dispatch(request());
        homeService.getServices()
            .then(services => {
                dispatch(success(services));
            },
                error => {
                    dispatch(failure(error.toString()));
                })
    }

    function request() {
        return {
            type: homeConstants.SERVICES_REQUEST,
        }
    }

    function success(services) {
        return {
            type: homeConstants.SERVICES_SUCCESS,
            services
        }
    }

    function failure(error) {
        return {
            type: homeConstants.SERVICES_FAILURE,
            error
        }
    }
}

function getBanners() {
    return dispatch => {
        dispatch(request());
        homeService.getBanners()
            .then(banners => {
                dispatch(success(banners));
            },
                error => {
                    dispatch(failure(error.toString()));
                })
    }

    function request() {
        return {
            type: promotionsConstants.PROMOTIONS_REQUEST,
        }
    }

    function success(banners) {
        return {
            type: promotionsConstants.PROMOTIONS_SUCCESS,
            banners
        }
    }

    function failure(error) {
        return {
            type: promotionsConstants.PROMOTIONS_FAILURE,
            error
        }
    }
}

function getLogo() {
    return dispatch => {
        dispatch(request());
        homeService.getLogo()
            .then(logo => {
                dispatch(success(logo));
            },
                error => {
                    dispatch(failure(error.toString()));
                })
    }

    function request() {
        return {
            type: homeConstants.LOGO_REQUEST,
        }
    }

    function success(logo) {
        return {
            type: homeConstants.LOGO_SUCCESS,
            logo
        }
    }

    function failure(error) {
        return {
            type: homeConstants.LOGO_FAILURE,
            error
        }
    }
}

function getFooter() {
    return dispatch => {
        dispatch(request());
        homeService.getFooter()
            .then(menu => {
                dispatch(success(menu));
            },
                error => {
                    dispatch(failure(error.toString()));
                })
    }

    function request() {
        return {
            type: homeConstants.FOOTER_REQUEST,
        }
    }

    function success(menu) {
        return {
            type: homeConstants.FOOTER_SUCCESS,
            menu
        }
    }

    function failure(error) {
        return {
            type: homeConstants.FOOTER_FAILURE,
            error
        }
    }
}

function newsletter(email, is_active = true) {
    return dispatch => {
        dispatch(request(email, is_active));
        homeService.newsletter(email, is_active)
            .then(newsletter => {
                dispatch(success(newsletter));
            },
                error => {
                    dispatch(failure(error.toString()));
                })
    }

    function request() {
        return {
            type: homeConstants.NEWSLETTER_REQUEST,
        }
    }

    function success(newsletter) {
        return {
            type: homeConstants.NEWSLETTER_SUCCESS,
            newsletter
        }
    }

    function failure(error) {
        return {
            type: homeConstants.NEWSLETTER_FAILURE,
            error
        }
    }
}


function getServiceDetails(id) {
    return dispatch => {
        dispatch(request(id));
        homeService.getServices()
            .then(services => {
                var id_filter = id;
                var filtered = services.results.filter(function (item) {
                    return id_filter.indexOf(item.id) !== -1;
                });
                dispatch(success(filtered));
            },
                error => {
                    dispatch(failure(error.toString()));
                })
    }


    function request() {
        return {
            type: homeConstants.SERVICE_DETAILS_REQUEST,
        }
    }

    function success(service) {
        return {
            type: homeConstants.SERVICE_DETAILS_SUCCESS,
            service
        }
    }

    function failure(error) {
        return {
            type: homeConstants.SERVICE_DETAILS_FAILURE,
            error
        }
    }
}

function getCurrencies() {
    return dispatch => {
        dispatch(request());
        homeService.getCurrencies()
            .then(currencies => {
                dispatch(success(currencies));
            },
                error => {
                    dispatch(failure(error.toString()));
                })
    }

    function request() {
        return {
            type: homeConstants.CURRENCY_REQUEST,
        }
    }

    function success(currencies) {
        return {
            type: homeConstants.CURRENCY_SUCCESS,
            currencies
        }
    }

    function failure(error) {
        return {
            type: homeConstants.CURRENCY_FAILURE,
            error
        }
    }
}