import React, { Component } from "react";
import "./Topbar.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import {
//   Nav,
//   Navbar,
//   NavDropdown /*, MenuItem,  Tabs, ButtonToolbar, Button, Table, ButtonGroup, Row, Col, Grid, Panel, FormGroup, FormControl*/,
// } from "react-bootstrap";
// import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { homeActions, userActions } from "../../store/actions";
import Header from "layout/Header/Header";
import CartItemsList from "../../components/CartItemsList/CartItemsList";
import AppContext from "contexts/AppContext";
import CurrencyContext from "contexts/CurrencyContext";
import Loader from "layout/Loader/Loader";
import Currency from "components/currency/Currency";
const animatedComponents = makeAnimated();
class Topbar extends Component {
  static contextType = CurrencyContext;
  constructor(props) {
    super(props);
    this.props.dispatch(homeActions.getLogo());
    this.props.dispatch(userActions.login());
    this.state = {
      cartItems: JSON.parse(localStorage.getItem('cartItems')),
    }

    this.props.dispatch(homeActions.getCurrencies());
    // console.log(CurrencyContext);
    // console.log(CurrencyContext._currentValue.currentCurrency);
  }

  generateCurrencyDropdown = () => {
    this.currencyDropdown = [];
    if (this.props.currencies) {
      if (this.props.currencies.currencies) {

        for (var i = 0; i < this.props.currencies.currencies.results.length; i++) {
          this.currencyDropdown.push({
            value: this.props.currencies.currencies.results[i].name,
            label:
              <div className="crFlg">
                <img
                  src={this.props.currencies.currencies.results[i].icon}
                  height="15px"
                  width="20px"
                  alt={this.props.currencies.currencies.results[i].name}
                />
                <span className="selectedCr">{this.props.currencies.currencies.results[i].name}</span>
              </div>
          })
        }
      }
    }
  }

  logout = () => {
    console.log("clicked logout");
    this.props.dispatch(userActions.logout());

    // if (logged && currentProvider) {
    //   this.nodes[currentProvider].props.triggerLogout();
    // }
  };



  render() {
    if (this.props.footerMenu.loading || this.props.logo.loading) return (<Loader />);

    return (
      <AppContext.Consumer>
        {({ cartItems, setCartItems }) => (
          <header className="w3l-top-header-strip">
            {this.props.currencies && this.props.currencies.currencies && this.generateCurrencyDropdown()}
            <div className="hero-header-11">
              <div className="hero-header-11-content">
                <div className="main-header">
                  <div className="container-fluid">
                    <div className="d-grid grid-columns-auto">
                      <div className="right-grid align-left d-grid">
                        <div className="topStrip">
                          <div className="brand">
                            <a className="navbar-brand" href="/">
                              {this.props.logo && this.props.logo.items && <img
                                src={this.props.logo.items[0].logo}
                                width="178px"
                                alt={this.props.logo.items[0].logo.title}
                              />}

                            </a>
                          </div>
                          <Header />
                          <div className="d-flex align-items-center">

                            <Currency
                              currencyDropdown={this.currencyDropdown}
                              components={animatedComponents}
                              currencies={this.props.currencies && this.props.currencies.currencies ? this.currencyDropdown[1] : ''}
                            />


                            {/* <div
                              className="selectCn"
                              style={{ zIndex: 1000, width: "100px" }}
                            >
                              <Select
                                placeholder="Currency"
                                defaultValue={this.props.currencies && this.props.currencies.currencies ? this.currencyDropdown[1] : ''}
                                options={this.currencyDropdown}
                                components={animatedComponents}
                                onChange={this.updateCurrency}
                              />
                            </div> */}

                            <div className="rightContent d-flex">
                              <div className="addToCart position-relative">
                                <div className="loggedInDropDown">
                                  <Link to="#">
                                    <span className="p20 text-dark ">
                                      <b>{cartItems && cartItems.items && cartItems.items.length}</b> Item(s)
                                      <i className="fa fa-shopping-cart"></i>
                                    </span>
                                  </Link>
                                  <div className="loggedInDropDown-content">
                                    <CartItemsList />
                                  </div>
                                </div>
                                <b className="itemsCount">{cartItems && cartItems.items && cartItems.items.length > 0 ? cartItems.items.length : 0}</b>
                              </div>

                              <div className="myAccount mr-2">
                                <div className="loggedInDropDown">
                                  <Link to="#" className="accountIcon">
                                    <span className="p20 ">
                                      <i className="fa fa-user"></i>
                                    </span>
                                  </Link>
                                  <div className="loggedInDropDown-content signiSignUp">
                                    <ul className="mt-2">
                                      {!this.props.user.loggedIn && (
                                        <>
                                          <li>
                                            <Link to="/login">
                                              <i className="fa fa-sign-in mr-2"></i>
                                          Sign In
                                        </Link>
                                          </li>
                                          <li>
                                            <Link to="/registration">
                                              <i className="fa fa-user-plus mr-2"></i>
                                          Register
                                        </Link>
                                          </li>
                                        </>
                                      )}
                                      {this.props.user.loggedIn && (
                                        <>
                                          <li>
                                            <Link to="/profile">
                                              <i className="fa fa-user-plus mr-2"></i>
                                          Profile
                                        </Link>
                                          </li>
                                          <li onClick={this.logout}>
                                            <Link to="#">
                                              <i className="fa fa-sign-out mr-2"></i>
                                          Logout
                                        </Link>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

        )}
      </AppContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Topbar);
