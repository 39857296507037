import { combineReducers } from "redux";
import { users } from './users.reducer';
import {alert } from './alert.reducer';
import {product} from './product.reducer';
import {home} from './home.reducer'
import {services } from './services.reducer';
import {shopProducts} from './shopProducts.reducers';
import {shopCategory} from './shop-category.reducer';
import { shopBanners } from "./shopBanners.reducers";
import { profile } from "./profile.reducer";
import { promotions } from "./promotions.reducers";
import {logo} from './logo.reducer';
import { menu } from "./menu.reducers";
import { subscribers } from "./subscribers.reducers";
import {enquiry} from "./enquiry.reducers";
import {contact} from "./contact.reducers";
import {serviceDetails} from "./serviceDetails.reducers";
import {currencies} from './currencies.reducer';
import {productDetails} from './productDetails.reducers'

const PaginationReducer = (pageId = null, action) => {
  if (action.type === "PRODUCT_LIST_PAGINATION") {
    return action.payload;
  }
  return action;
};


// const PromotionsReducer = () => {
//   return [
//     {
//       heading: "About Us",
//       description:
//         "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus voluptatum, nostrum sed alias accusamus earum.",
//       buttonText: "About Us",
//       buttonLink: "about.html",
//       img: "assets/images/img4.jpg",
//       backgroundClass: "img-one",
//       btnClass: "btn-light",
//     },
//     {
//       heading: "Our Services",
//       description:
//         "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus voluptatum, nostrum sed alias accusamus earum.",
//       buttonText: "Our Services",
//       buttonLink: "services.html",
//       img: "assets/images/img2.jpg",
//       backgroundClass: "img-info",
//       btnClass: "btn-primary",
//     },
//     {
//       heading: "Contact Us",
//       description:
//         "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus voluptatum, nostrum sed alias accusamus earum.",
//       buttonText: "Contact Us",
//       buttonLink: "contact.html",
//       img: "assets/images/img2.jpg",
//       backgroundClass: "img-info",
//       btnClass: "btn-primary",
//     },
//   ];
// };

const RecommendationReducer = () => {
  return [
    {
      recName: "Product Name 1",      
      altText:'Product Name 1',
      imgURL:'https://dummyimage.com/200x200/000/fff',  
      dimension:'20x80x100',
      recPrice:'$700',
    },
    {
        recName: "Product Name 2",      
        altText:'Product Name 2',
        imgURL:'https://dummyimage.com/200x200/000/fff',
        dimension:'20x80x100', 
        recPrice:'$800',
    },
    {
        recName: "Product Name 3",     
        altText:'Product Name 3',
        imgURL:'https://dummyimage.com/200x200/000/fff', 
        dimension:'20x80x100',
        recPrice:'$600',
    },
    {
        recName: "Product Name 4",
        altText:'Product Name 4',
        imgURL:'https://dummyimage.com/200x200/000/fff', 
        dimension:'20x80x100',
        recPrice:'$500',
    },
  ];
};



const rootReducer = combineReducers({
  product: product,
  pageId: PaginationReducer,
  home: home,
  categories:shopCategory,
  shopProducts:shopProducts,
  recommendation:RecommendationReducer,
  promotions: promotions,
  services: services,
  // registration,
  user:users,
  subscribers,
  enquiry,
  shopBanners:shopBanners,
  profile: profile,
  footerMenu: menu,
  logo : logo,
  alert,
  contact,
  serviceDetails,
  currencies,
  productDetails
});

export default rootReducer;