import React, { Component } from "react";
import "./Shop.css";
import * as $ from "jquery";
import 'jquery-ui-bundle';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  ProductListPagination,
  shopActions,
} from "store/actions";
import PropTypes from 'prop-types';
import CategoryAside from "components/CategoryAside/CategoryAside";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "contexts/AppContext";
import InquiryModal from "pages/Product/Modal/InquiryModal";
import EnquiryForm from "components/forms/EnquiryForm/EnquiryForm";
import { store } from "store/helpers";
import Loader from "layout/Loader/Loader";
import config from "services/config";
import CurrencyContext from "contexts/CurrencyContext";
toast.configure();

let pageNumberToCall = 1;
const productsPerPage = 4;
class Shop extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    document.title = "Dwight | Shop";
    this.calledFor = 'default';
    this.pageNumbers = [];
    this.id = this.props.match.params.id;
    this.props.dispatch(shopActions.getCategoryProducts(this.id));
    //let currency = JSON.parse(localStorage.getItem("currency"));
    this.state = {
      show: false,
      currentProducts: [],
      shopProducts: this.props.shopProducts,
      pageName: null,
      subcategoryName: null,
      //selectedCurrency: currency !== null ? currency.value : 'CAD'
    };
  }

  animateToCartItem = (thisRef) => {
    var cart = $(".itemsCount");
    var imgtodrag = $(thisRef).parent(".pBox").find("img").eq(0);

    if (imgtodrag) {
      var imgclone = imgtodrag
        .clone()
        .offset({
          top: imgtodrag.offset() ? imgtodrag.offset().top : 0,
          left: imgtodrag.offset() ? imgtodrag.offset().left : 0,
        })
        .css({
          opacity: "0.5",
          position: "absolute",
          "min-height": "150px",
          width: "150px",
          "z-index": "100",
        })
        .appendTo($("body"))
        .animate(
          {
            top: cart.offset().top + 10,
            left: cart.offset().left + 10,
            width: "75px",
            "min-height": "75px",
          },
          1000,
          "easeInOutExpo"
        );

      setTimeout(function () {
        cart.effect(
          "shake",
          {
            times: 2,
          },
          200
        );
      }, 1500);

      imgclone.animate(
        {
          width: 0,
          "min-height": 0,
        },
        function () {
          $(this).detach();
        }
      );
      window.scrollTo(0, 0);
    }
  }

  handleShopProducts = () => {
    if (
      this.state.shopProducts.shopProducts &&
      this.state.shopProducts.shopProducts.length > 0
    ) {

      //if(this.state.shopProducts.shopProducts[0].parent)
      this.setState({ pageName: this.state.shopProducts.shopProducts[0].category.categoryName });
      //this.setState({ pageName: this.state.shopProducts.shopProducts[0].category.category_items });
      if (this.pageNumberToCall !== undefined) {
        this.getCurrentProducts(this.pageNumberToCall);
        this.nowShowing = this.pageNumberToCall * productsPerPage;
      } else {
        this.getCurrentProducts(pageNumberToCall);
        this.nowShowing = pageNumberToCall * productsPerPage;
      }

      // Pagination needs to be handled via store
      this.setState({ totalCount: this.state.shopProducts.shopProducts.length });
      this.pageNumbers = [];
      for (
        let i = 1;
        i <=
        Math.ceil(
          this.state.shopProducts.shopProducts.length / productsPerPage
        );
        i++
      ) {
        this.pageNumbers.push(i);
      }
      this.setState({ shopProducts: this.props.shopProducts });
    }
  }

  componentDidMount() {
    const thisRef = this;
    if (this.state.shopProducts) {
      this.handleShopProducts();
    }

    $(".btn-addToCart").on("click", function () {
      thisRef.animateToCartItem(this);
    });
  }

  showModal = (e) => {
    this.setState({
      show: !this.state.show,
    });
  };

  closeModal = () => {
    this.setState({ show: false })
  }

  componentDidUpdate(nextProps) {
    // if (this.state.selectedCurrency !== JSON.parse(localStorage.getItem("currency")).value) {
    //   this.setState({ selectedCurrency: JSON.parse(localStorage.getItem("currency")).value })
    //   alert(`currecncy updated ${this.state.selectedCurrency}`);
    // }
    const { shopProducts } = this.props;
    if (nextProps.location.pathname !== nextProps.history.location.pathname) {
      store.dispatch(shopActions.getCategoryProducts(this.id));
    }
    if (nextProps.shopProducts !== shopProducts) {
      if (shopProducts) {
        this.setState({ shopProducts: shopProducts });
        this.getCurrentProducts(pageNumberToCall);
        if (
          this.state.shopProducts.shopProducts &&
          this.state.shopProducts.shopProducts.length > 0
        ) {
          let obj = this.state.shopProducts.shopProducts[0].parent;
          if (obj && Object.keys(obj).length === 0 && obj.constructor === Object) {
            this.setState({ subcategoryName: '' })
            //console.log("yessssss")
          }
          else {
            this.setState({ subcategoryName: obj.categoryName })
            // console.log("noooooo")
          }
          this.setState({
            pageName: this.state.shopProducts.shopProducts[0].category.categoryName,
            //pageName: this.state.shopProducts.shopProducts[0].category.category_items,
            totalCount: this.state.shopProducts.shopProducts.length
          });
          this.pageNumbers = [];
          for (
            let i = 1;
            i <=
            Math.ceil(
              this.state.shopProducts.shopProducts.length / productsPerPage
            );
            i++
          ) {
            this.pageNumbers.push(i);
          }
        }
      }
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      shopProducts: nextProps.shopProducts,
    };
  }

  renderCategoryBanner() {
    if (this.props.shopProducts) {
      if (
        this.props.shopProducts.shopProducts &&
        this.props.shopProducts.shopProducts.length > 0
      ) {
        return (
          <div className="shopBanner">
            <img
              src={
                this.props.shopProducts &&
                this.props.shopProducts.shopProducts &&
                this.props.shopProducts.shopProducts[0].logo
              }
              className="w-100"
              alt="logo"
            />
            <h1>{this.props.shopProducts.shopProducts[0].category}</h1>
          </div>
        );
      } else {
      }
    }
  }

  getCurrentProducts = (currentPage) => {
    if (this.state.shopProducts) {
      if (this.state.shopProducts.shopProducts) {
        const indexOfLastProduct = currentPage * productsPerPage;
        const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
        this.nowShowingFirstProductId = indexOfFirstProduct + 1;
        this.nowShowingLastProductId =
          indexOfLastProduct > this.state.totalCount
            ? this.state.totalCount
            : indexOfLastProduct;

        let products = this.state.shopProducts.shopProducts;
        this.currentProducts = products.slice(
          indexOfFirstProduct,
          indexOfLastProduct
        );

        this.setState({
          currentProducts: this.currentProducts,
          nowShowingFirstProductId: this.nowShowingFirstProductId,
          nowShowingLastProductId: this.nowShowingLastProductId,
        })
      }
    }
  };

  handleClick = (event) => {
    this.pageNumberToCall = event.target.id;
    this.props.dispatch(ProductListPagination(this.pageNumberToCall));
    this.getCurrentProducts(this.pageNumberToCall);
  };

  compareValues = (key, order = 'asc') => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  sortByType = (e) => {
    if (e.target.value === 'DESC') {
      this.state.shopProducts.shopProducts.sort(this.compareValues('unitPrice', 'desc'));
      this.setState({ currentProducts: this.state.shopProducts.shopProducts });
    } else {
      this.state.shopProducts.shopProducts.sort(this.compareValues('unitPrice'));
      this.setState({ currentProducts: this.state.shopProducts.shopProducts });
    }
    this.getCurrentProducts(1);
  }

  flyToCart = (event) => {
    //console.log(event.target);
    this.animateToCartItem(event.target);
    //if (this.calledFor === 'default') {
    //this.animateToCartItem(event.target);
    //this.calledFor = 'cart';
    //}
    //this.componentDidMount();
  }

  handleLinkClick = (product) => {
    let breadcrumb = {
      level1: 'Home',
      level3: this.state.pageName,
      level4: product.name
    }
    if (this.state.subcategoryName != null && this.state.subcategoryName !== '') {
      breadcrumb.level2 = this.state.subcategoryName;
    }

    // let breadcrumb = {
    //   level1: 'Home',
    //   parent: this.state.subcategoryName != null && this.state.subcategoryName !== '' ? this.state.subcategoryName : undefined,
    //   child: product.category.categoryName,
    //   level4: product.name
    // }
    localStorage.setItem('breadcrumb', JSON.stringify(breadcrumb));
    //alert(`${breadcrumb.level1}/${breadcrumb.level2}/${breadcrumb.level3}/${breadcrumb.level4}`)
  }

  render() {
    if (this.props.shopProducts.loading) return (<Loader />);
    const { match } = this.props;
    this.id = match.params.id;

    return (
      <AppContext.Consumer>
        {({ cartItems, setCartItems }) => (
          <section>
            <nav className="d-flex justify-content-between px-3" aria-label="breadcrumb ">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>

                {this.state.subcategoryName !== null && this.state.subcategoryName !== '' ?
                  <>
                    <li className="breadcrumb-item">
                      <Link
                        to={this.state.subcategoryName.replace(/\s+/g, '-').toLowerCase()}>{this.state.subcategoryName}</Link>
                    </li>
                  </> : ''}

                <li className="breadcrumb-item active" aria-current="page">
                  {this.state.pageName}
                </li>
              </ol>

              <div className="d-flex align-items-center justify-content-between pb-2 results">
                {this.state.shopProducts && (
                  <div>
                  </div>
                )}
                <div>
                  <span>sort by price: </span>
                  <select
                    name="price"
                    id="price"
                    className="p-1 pl-2 pr-2 sorting"
                    onChange={(e) => { this.sortByType(e) }}
                  >
                    <option value="ASC" defaultValue> Ascending </option>
                    <option value="DESC">Descending</option>

                  </select>
                </div>
              </div>
            </nav>

            <div className="product-container">
              <div className="row">
                <div className="col-md-3">
                  <CategoryAside />
                </div>
                <div className="col-md-9">
                  <div className="w-100 mt-2">
                    <div className="inner p-2 bg-light">
                      <div className="row"></div>
                      {this.state.shopProducts &&
                        this.state.shopProducts.shopProducts &&
                        this.state.shopProducts.shopProducts.length > 0 ? (
                        <>
                          <div className="row">
                            {this.state.shopProducts &&
                              this.state.shopProducts.shopProducts &&
                              this.currentProducts &&
                              this.state.shopProducts.shopProducts.length > 0 &&
                              this.state.currentProducts.map((product, index) => (

                                <div className="col-xl-3 col-lg-4 col-md-6 offset-md-0 offset-sm-1 col-sm-10 offset-sm-1  my-2 mb-3" key={index}>
                                  <div className="card pBox">
                                    <Link className="pNameEllipsis"
                                      onClick={(event) => this.handleLinkClick(product)}
                                      to={{
                                        pathname: product.category.category_items === config.CATEGORY_DESIGN_PRODUCT ?
                                          `/design/${this.props.shopProducts.categoryId}/product-details/${product.slug}`
                                          : `/shop/${this.props.shopProducts.categoryId}/product-details/${product.slug}`
                                      }} >
                                      <img
                                        className="card-img-top"
                                        src={config.img_url + product.logo}
                                        alt={product.name}
                                      />
                                    </Link>
                                    <div className="card-body p-1">
                                      <div className="d-flex align-items-start justify-content-between border-bottom mb-1 product-name-box">
                                        <div className="d-flex flex-column">
                                          <div className="h6 font-weight-bold">
                                            {/* <Link
                                              to={`/product-details/${product.id}`}
                                              params={{ testvalue: "hello" }}
                                              className="pNameEllipsis"> */}

                                            <Link className="pNameEllipsis1" to={{ pathname: `/shop/${this.props.shopProducts.categoryId}/product-details/${product.slug}` }}>
                                              {product.name}
                                            </Link>
                                          </div>
                                          <CurrencyContext.Consumer>
                                            {(currentCurrency) => (
                                              <div>
                                                {(product.unitPrice) ?
                                                  <div className="text-muted">
                                                    ${product.unitPrice}
                                                  </div> : null}
                                                {/* <p>{currentCurrency}</p> */}
                                              </div>
                                            )}
                                          </CurrencyContext.Consumer>
                                        </div>
                                        {/* <div className="btn pt-0">
                                          <span className="fa fa-heart" />
                                        </div> */}
                                      </div>
                                    </div>
                                    {/* <div className="row"></div> */}

                                    {product.is_shop && product.unitPrice &&

                                      <button
                                        p-id={product.id}
                                        className="btn btn-addToCart btn-info cart w-100"
                                        onClick={(e) => {
                                          this.flyToCart(e);

                                          let productId = e.target.getAttribute('p-id');
                                          let productExists = false;
                                          let arrIndex = null;
                                          let subTotal = 0;
                                          let total = 0;
                                          let newCartItems = { ...cartItems };
                                          if (cartItems.items && cartItems.items.length > 0) {
                                            for (var i = 0; i < cartItems.items.length; i++) {
                                              if (parseInt(cartItems.items[i].item.id) === parseInt(productId)) {
                                                productExists = true;
                                                arrIndex = i;
                                                break;
                                              }
                                            }
                                          }

                                          let myItem = {
                                            item: product,
                                            count: 1,
                                            productPrice: 0,
                                            addedFrom: 'shoppage',
                                            isVariation: false,
                                            selectedItem: { material: '', size: '', price: 0 }
                                          }

                                          if (product.variation_stat === true && product.variations.length > 0) {
                                            product.variations.forEach(element => {
                                              if (element.default_material === true) {
                                                myItem.isVariation = true;
                                                myItem.selectedItem.material = element.material.material_name;
                                                myItem.selectedItem.size = element.size.size_name;
                                                myItem.selectedItem.price = element.price;
                                                myItem.productPrice = element.price;
                                              }
                                            });
                                          }

                                          if (myItem.productPrice === 0) {
                                            myItem.productPrice = product.unitPrice
                                          }

                                          if (cartItems.items && cartItems.items.length > 0) {
                                            if (productExists) {
                                              newCartItems.items[arrIndex].count++;
                                            } else {
                                              newCartItems.items.push(myItem)
                                            }
                                          } else {
                                            newCartItems.items = [];
                                            newCartItems.subTotal = 0;
                                            newCartItems.total = 0;
                                            newCartItems.items.push(myItem)
                                          }

                                          for (var i = 0; i < newCartItems.items.length; i++) {
                                            // if (myItem.isVariation === true && parseInt(cartItems.items[i].item.id) === parseInt(myItem.item.id)) {
                                            //   subTotal += cartItems.items[i].count * cartItems.items[i].selectedItem.price;
                                            // }
                                            // else {
                                            //   subTotal += cartItems.items[i].count * cartItems.items[i].item.unitPrice;
                                            // }
                                            subTotal += newCartItems.items[i].count * newCartItems.items[i].item.productPrice;
                                            total = subTotal;
                                            newCartItems.subTotal = subTotal;
                                            newCartItems.total = total;
                                          }
                                          localStorage.setItem(
                                            "cartItems",
                                            JSON.stringify(newCartItems)
                                          );
                                          setCartItems(newCartItems)
                                        }}
                                      >
                                        <i className="fa fa-shopping" /> Add to Cart
                                    </button>
                                    }
                                    {product.is_enquiry &&
                                      <button
                                        onClick={(e) => {
                                          this.showModal(e);
                                        }}
                                        className="btn btn-primary btn-inquiry cart w-100"
                                      >
                                        Make Inquiry
                                        </button>
                                    }


                                    <Modal size="lg" show={this.state.show} onHide={this.showModal}>
                                      <InquiryModal headerText="Enter Inquiry Details" onClose={this.showModal} show={this.state.show}>
                                        <EnquiryForm close={this.closeModal} />
                                      </InquiryModal>
                                    </Modal>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <ul className="pagination">
                            {
                              this.pageNumbers.map((number) => (
                                <li
                                  className="page-item"
                                  key={number}
                                  onClick={this.handleClick}
                                >
                                  <div
                                    className="page-link"
                                    id={number}
                                    href="#"
                                  >
                                    {number}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </>
                      ) : (
                        <div>
                          <h1 className="text-dark">No products in this category</h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </AppContext.Consumer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { state, shopProducts: state.shopProducts };
};

export default connect(mapStateToProps)(Shop);