import { userConstants } from "store/constants";
let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};
export function profile(state = initialState, action) {
    switch (action.type) {
        case userConstants.PROFILE_REQUEST:
            return {
              loggedIn: true,
            };
          case userConstants.PROFILE_SUCCESS:
            return {
              loggedIn: true,
              profile: action.profileData
            };
          case userConstants.PROFILE_FAILURE:
            if (user) {
              return {
                loggedIn: true,
              };
            } else {
              return {
                loggedIn: false
              };
            }
        default:
            return state;
    }
}
  