export const productConstants = {
    PRODUCT_LISTING_REQUEST: 'PRODUCT_LISTING_REQUEST',
    PRODUCT_LISTING_SUCCESS: 'PRODUCT_LISTING_SUCCESS',
    PRODUCT_LISTING_FAILURE: 'PRODUCT_LISTING_FAILURE',

    PRODUCT_DETAIL_REQUEST:'PRODUCT_DETAIL_REQUEST',
    PRODUCT_DETAIL_SUCCESS:'PRODUCT_DETAIL_SUCCESS',
    PRODUCT_DETAIL_FAILURE:'PRODUCT_DETAIL_FAILURE',

    CATEGORY_LISTING_REQUEST: 'CATEGORY_LISTING_REQUEST',
    CATEGORY_LISTING_SUCCESS: 'CATEGORY_LISTING_SUCCESS',
    CATEGORY_LISTING_FAILURE: 'CATEGORY_LISTING_FAILURE',

    CATEGORY_LISTING_DATA_REQUEST: 'CATEGORY_LISTING_DATA_REQUEST',
    CATEGORY_LISTING_DATA_SUCCESS: 'CATEGORY_LISTING_DATA_SUCCESS',
    CATEGORY_LISTING_DATA_FAILURE: 'CATEGORY_LISTING_DATA_FAILURE',

    ENQUIRY_POST_REQUEST:'ENQUIRY_POST_REQUEST',
    ENQUIRY_POST_SUCCESS:'ENQUIRY_POST_SUCCESS',
    ENQUIRY_POST_FAILURE:'ENQUIRY_POST_FAILURE',
};
