import AppContext from "contexts/AppContext";
import Multistepform from "pages/Multistepform/Multistepform";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { history } from "store/helpers";
import "./Checkoutpage.css";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { trackPromise } from 'react-promise-tracker';
import { checkoutAPI } from '../../services/checkout.service';
import { Spinner } from "components/spinner/Spinner";
import MyBackdrop from "components/spinner/MyBackdrop";
import PayPal from "components/payments/PayPal";
import PayPalDialog from "components/payments/PayPalDialog";
import BankPODialog from "components/payments/BankPODialog";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga';
import config from 'services/config';

class Checkoutpage extends Component {

  static context = AppContext;

  constructor(props, context) {
    super(props);

    this.cartItems = ''
    this.backdropElement = React.createRef();
    if (props.location && props.location.state)
      this.cartItems = props.location.state.cartItems;

    console.log(context)

    this.state = {
      cartItems: '',
      paymentMethod: 'paypal',
      isShippingNotAvailable: true,
      shippingWays: null,
      shippingAddress: '',
      bankPoData: '',
      bankPOError: 'This field is required',
      shippingAmount: 0,
      shippingId: '',
      showPaypal: false,
      showBankPO: false,
      totalPrice: this.cartItems.total,
      totalWithCoupon: this.cartItems.total,
      coupon: null,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({
        cartItems: JSON.parse(localStorage.getItem("cartItems")) != null
          ? JSON.parse(localStorage.getItem("cartItems"))
          : []
      })
    }
  }


  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangePaymentMethod = (event) => {
    this.setState({ paymentMethod: event.target.value })
  }

  onChangeShippingMethod = (event) => {
    this.setState({ shippingAmount: event.target.value });
    let total = parseFloat(localStorage.getItem("total")) - parseFloat(localStorage.getItem("shipAmt")) + parseFloat(event.target.value)
    localStorage.setItem("shipAmt", parseFloat(event.target.value));
    localStorage.setItem("total", parseFloat(total));
  }

  onTypeBankPO = (event) => {
    this.setState({ bankPoData: event.target.value })
  }

  closeBackdrop = () => {
    if (this.backdropElement.current !== undefined) {
      this.backdropElement.current.handleToggle();
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    e.target.className += " was-validated";
    ReactGA.event({
      category: 'User',
      action: 'Clicked on "Make Purchase" button'
    });
    this.initiatePayement();
  };

  getShippingCharges = (shippingDetails) => {
    window.scrollTo(0, 200);

    trackPromise(
      checkoutAPI.submitData(shippingDetails)
        .then((responseData) => {

          let shippingWays = [];
          if (responseData != null && responseData.status === 200 && responseData.getRate.length > 0) {

            responseData.getRate.map((data, index) => {
              shippingWays.push({
                id: index,
                name: data.service_name,
                value: data.service_name,
                amount: parseFloat(data.price)
              })
            })
            localStorage.setItem("shipAmt", parseFloat(responseData.getRate[0].price));
            let total = parseFloat(responseData.getRate[0].price) + parseFloat(localStorage.getItem("total"))
            localStorage.setItem("total", parseFloat(total));
            this.setState({
              isShippingNotAvailable: !this.state.isShippingNotAvailable,
              shippingWays: shippingWays,
              shippingAddress: shippingDetails,
              shippingId: responseData.shipping_id,
              shippingDetails
            })
          }
          else {
            if (responseData !== null && responseData.hasOwnProperty('error')) {
              alert(responseData.error[0])
            }
          }
        })
    );
  }

  initiatePayement = async () => {
    if (this.state.paymentMethod === 'bankpo') {
      if (this.state.bankPoData.length < 1) {
        alert("Please enter correct company po number.")
        return;
      }
    }

    console.log("Payment Initiated..");
    if (this.state.paymentMethod === 'paypal')
      this.setState({ showPaypal: false });
    else
      this.setState({ showBankPO: false })
    this.closeBackdrop();

    this.productInfo = await this.createRequestBody();
    console.log(this.productInfo);

    if (this.state.paymentMethod === 'bankpo') {
      checkoutAPI.submitPayment({ ...this.productInfo, shippingDetails: this.state.shippingDetails })
        .then((response) => {
          console.log(response);
          this.closeBackdrop();
          localStorage.setItem('transaction_id',this.productInfo.transaction_id);
          localStorage.removeItem("cartItems");
          window.location.href = "/thankyou";
        })
    }
    else { // paypal
      setTimeout(() => {
        this.setState({ showPaypal: true });
        this.closeBackdrop();
      }, 4000);
    }


    /*checkoutAPI.submitPayment(this.productInfo)
      .then((response) => {
        console.log(response);
        this.closeBackdrop();
        if (this.state.paymentMethod === 'paypal')
          this.setState({ showPaypal: true });
        else {
          // this.setState({ showBankPO: true })
          // history.push('/thankyou');
          // window.location.reload();
          window.location.href = "/thankyou";
        }
      })*/
  }

  applyCoupon = () => {
      fetch( `${config.apiUrl}/customer/check-coupon?code=${this.state.coupon ?? ''}`)
      .then(r => {
        if(r.status !== 404){
          return r.json();
        }
      })
      .then((d) => {
        console.log("d", d)
        this.setState({
          ...this.state,
          totalWithCoupon: this.state.totalPrice - d.amount,
        })
      })
    }

  createRequestBody = async () => {
  
    const productInfo = {
      payment_method: this.state.paymentMethod,
      bank_po_number: this.state.paymentMethod === 'bankpo' ? this.state.bankPoData : "",
      user: "",//0 for guest
      email: this.state.shippingAddress.email,
      shipping_charge: this.state.shippingAmount !== 0 ? this.state.shippingAmount : this.state.shippingWays[0].amount,
      shipping_id: this.state.shippingId,
      paypalTransId: '',
      transaction_id: uuidv4(),
      coupon: this.state.coupon,
    };

    if (this.cartItems && this.cartItems.items) {

      productInfo.product_list = [];
      this.cartItems.items.forEach(item => {
        productInfo.product_list.push({
          added_from: item.addedFrom,
          count: item.count,
          is_variation: item.isVariation,
          product_price: item.productPrice,
          product_id: item.item.id
        })
      });
      productInfo.total_amount = this.cartItems.total;
    }
    else {
      productInfo.product_list = [];
      productInfo.total_amount = 0;
    }
    return productInfo;
  }

  render() {

    return (
      <AppContext.Consumer>
        {({ cartItems, setCartItems }) => (

          <section>
            <div className="container mt-4 mb-3">
              <div className="row">
                <nav aria-label="breadcrumb ">
                  <ol className="breadcrumb bg-transparent">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Checkout
                    </li>
                  </ol>
                </nav>
              </div>
              <section className="mt-2 mb-4">

                <div className="row">
                  <div className="col-lg-8">
                    <Multistepform toggleShipping={this.getShippingCharges} />
                  </div>
                  <div className="col-lg-4">
                    <form
                      className="needs-validation signin-form"
                      onSubmit={this.submitHandler}
                      noValidate
                      action="https://www.paypal.com/cgi-bin/webscr"
                      method="post"
                    >
                      <div className="card mb-4">
                        {/* {cartItems = (cartItems === undefined || cartItems === null) ? this.cartItems : cartItems} */}
                        {/* {this.state.cartItems && this.state.cartItems !== null && cartItems.items && (cartItems.items.map((item, index) => ( */}
                        {cartItems && cartItems.items && (cartItems.items.map((item, index) => (
                          <>

                            {/* <div className="CartItems d-flex flex-column">
                              <div className="ItemBox d-flex mt-2 border-bottom pb-2 justify-content-between">
                                <img src={item.item.logo} width="75px" className="mr-2" alt="logo" />
                                <p>
                                  <Link to={`/product-details/${item.item.id}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      history.push({
                                        pathname: "/product-details/" + item.item.id,
                                        state: { data: item },
                                      });
                                    }}>
                                    {item.item.name}
                                  </Link>
                                </p>
                                <p className="ml-2 mr-2"><span>{item.count}</span> x <span>${item.item.unitPrice}</span></p>
                              </div>
                            </div> */}

                            <div className="CartItems d-flex flex-column px-2">
                              <div className="ItemBox d-flex mt-2 border-bottom pb-2">
                                <div className="item-box-img">
                                  {/* <img src={item.item.logo} width="85px" className="mr-2" alt="logo" /> */}
                                  <img
                                      className="mr-2"
                                      src={config.img_url + item.item.logo}
                                      width="85px"
                                      alt="logo"
                                  />
                                </div>

                                {(item.addedFrom === 'productpage' && item.selectedItem !== '') ?
                                  <div className="item-box-detail">
                                    <ul>
                                      <li>
                                        <Link to={`shop/bullion/product-details/${item.item.slug}`} className="font-weight-bold">{item.item.name}</Link>
                                        <span className="d-block">
                                          <span className="item-count">
                                            {item.count}
                                          </span> x <span>${item.selectedItem.price}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <strong>Material :</strong> <span>{item.selectedItem.material}</span>
                                      </li>
                                      <li>
                                        <strong>Size :</strong> <span>{item.selectedItem.size}</span>
                                      </li>
                                    </ul>
                                  </div> :
                                  <>
                                    {(item.addedFrom === 'shoppage' && item.isVariation === true) ?
                                      <div>
                                        <ul>
                                          <li>
                                            <Link to={`shop/bullion/product-details/${item.item.slug}`} className="font-weight-bold">{item.item.name}</Link>
                                            <span className="d-block">
                                              <span className="item-count">
                                                {item.count}
                                              </span> x <span>${item.selectedItem.price}
                                              </span>
                                            </span>
                                          </li>
                                          <li>
                                            <strong>Material :</strong> <span>{item.selectedItem.material}</span>
                                          </li>
                                          <li>
                                            <strong>Size :</strong> <span>{item.selectedItem.size}</span>
                                          </li>
                                        </ul>
                                      </div> :
                                      <div className="item-box-detail">
                                        <ul>
                                          <li>
                                            <Link to={`shop/bullion/product-details/${item.item.slug}`} className="font-weight-bold">{item.item.name}</Link>
                                            <span className="d-block">
                                              <span className="item-count">
                                                {item.count}</span> x <span>${item.item.unitPrice}
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    }
                                  </>
                                }
                              </div>
                            </div>
                          </>
                        )))
                        }
                        {cartItems && cartItems.items && cartItems.items.length > 0 &&
                          <div className="card-body">
                            <h5 className="mb-3">The total amount of</h5>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                                Temporary amount
                                <span>$ {this.state.totalWithCoupon}</span>
                              </li>
                              <Spinner />
                              <li className={this.state.isShippingNotAvailable ? "hide-shipping-item" : "list-group-item d-flex justify-content-between align-items-center px-0"}>
                                Shipping
                              {/* <span>Gratis</span> */}
                                <span>
                                  <select onChange={this.onChangeShippingMethod} className="custom-select d-block w-100" id="country" required>
                                    {this.state.shippingWays != null && [... new Set(this.state.shippingWays)].map((item) =>
                                      <>
                                        <option key={item.id} value={item.amount}>{`${item.name} $${item.amount}`}</option>
                                      </>
                                    )}
                                  </select>
                                </span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                                <div>
                                  <strong>The total amount of</strong>
                                  <strong>
                                    <p className="mb-0">(including Taxes)</p>
                                  </strong>
                                </div>
                                <span>
                                  {/* <strong>$ {cartItems.total}</strong> */}
                                  {/* <strong>$ {this.state.totalPrice}</strong> */}
                                  {<strong>$ {this.state.totalWithCoupon}</strong>}
                                </span>
                              </li>
                            </ul>
                            <h5 className="mb-3">Payment method</h5>
                            <RadioGroup aria-label="payment" name="payment" value={this.state.paymentMethod} onChange={this.onChangePaymentMethod}>
                              <FormControlLabel value="bankpo" control={<Radio />} label="Company PO" />
                              {this.state.paymentMethod === 'bankpo' ?
                                <>
                                  <input
                                    type="text"
                                    id="bankpoid"
                                    value={this.state.bankPoData}
                                    onChange={this.onTypeBankPO}
                                    className="form-control font-weight-light"
                                    placeholder="Enter Company PO number"
                                  />
                                  {!this.state.bankPoData ?
                                    <span className='error'>{this.state.bankPOError}</span> : ''}
                                  </> : ''}
                              <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                            </RadioGroup>
                            <a
                              className="dark-grey-text d-flex justify-content-between"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="true"
                              aria-controls="collapseExample"
                            >
                              Add a discount code (optional)
                            <span>
                                <i className="fa fa-chevron-down pt-1" />
                              </span>
                            </a>
                            <div
                              className="collapse show"
                              id="collapseExample"
                            >
                              <div className="mt-3">
                                <div className="input-group mb-3">
                                  <input type="text" id="discount-code"
                                    className="form-control font-weight-light"
                                    placeholder="Enter discount code" aria-label="Enter discount code"
                                    aria-describedby="basic-addon2" 
                                    onChange={(e) => this.setState({ ...this.state, coupon: e.target.value })} />
                                  <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={this.applyCoupon}>Apply</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              type="submit"
                              disabled={this.state.isShippingNotAvailable}
                              // onClick={this.initiatePayement}
                              className="btn btn-primary btn-block waves-effect waves-light">
                              Make purchase
                            </button>
                          </div>
                        }
                      </div>
                      {(this.state.showPaypal === true)
                        ? <div /*className="payment-div"*/>
                           <PayPalDialog productInfo={{ ...this.productInfo, shippingDetails: this.state.shippingDetails }} shippingInfo={this.state.shippingAddress} total={parseFloat(localStorage.getItem("total"))} />
                        </div>
                        : null
                      }

                      {(this.state.showBankPO === true)
                        ? <div>
                          {/* <BankPODialog /> */}
                          {this.props.history.push("/thankyou")}
                        </div>
                        : null
                      }

                    </form>
                  </div>
                </div>

              </section>
            </div>
            <MyBackdrop ref={this.backdropElement} />
          </section>
        )}
      </AppContext.Consumer>
    );
  }
}
export default Checkoutpage;