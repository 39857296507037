import React, { useEffect, useState } from 'react';
import "./Currency.css"
import Select from "react-select";
import CurrencyContext from 'contexts/CurrencyContext';

const Currency = (props) => {
    const { currencyDropdown, animatedComponents } = props;
    let defaultCurrency;
    //console.log(currencyDropdown);
    //console.log(JSON.parse(localStorage.getItem('currency'));
    //const savedCurrency = currencyDropdown && currencyDropdown.length > 0 ? currencyDropdown[1] : { value: 'CAD', label: 'CAD' };

    //const [defaultCurrency, setDefaultCurrency] = useState(savedCurrency);
    if (localStorage.getItem('currency') === null) {
        defaultCurrency = currencyDropdown && currencyDropdown.length > 0 ? currencyDropdown[1] : { value: 'qwerty', label: { props: { childern: [], className: 'crFlg' } } }
    }
    else {
        defaultCurrency = JSON.parse(localStorage.getItem('currency'));
    }

    const updateCurrency = (event) => {
        let obj = { label: event.value, value: event.value }
        localStorage.setItem("currency", JSON.stringify(obj));
        // setDefaultCurrency(event.value);
       // alert(defaultCurrency);
        //console.log(defaultCurrency)
    }

    // useEffect(() => {
    //     if (props.currencies && props.currencies.currencies) {
    //         defaultCurrency = currencyDropdown[1];
    //     }
    // }, []);

    return (
        <CurrencyContext.Provider value={defaultCurrency}>
            <div
                className="selectCn"
                style={{ zIndex: 1000, width: "100px" }}>
                <Select
                    placeholder="Currency"
                    defaultValue={currencyDropdown && currencyDropdown.length > 0 ? currencyDropdown[0] : 'CAD'}
                    options={currencyDropdown}
                    components={animatedComponents}
                    onChange={updateCurrency}
                />
            </div>
        </CurrencyContext.Provider>
    )
}

export default Currency
