import React from "react";
//Component import
import GoogleButton from "components/SocialButton/GoogleButton";
import FacebookButton from "components/SocialButton/FacebookButton";
//Redux configurations imports
import { connect } from "react-redux";
//Redux action imports
import { userActions } from "../../store/actions";
import Home from "../Home/Home";



import {FACEBOOK_APP_ID,GOOGLE_APP_ID} from './../../store/constants/index';
class Login extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    // this.props.logout();
    this.state = {
      email: "",
      password: "",
      submitted: false,
    };
    this.nodes = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
    this.onLogoutSuccess = this.handleLogoutSuccess.bind(this);
    this.onLogoutFailure = this.handleLogoutFailure.bind(this);
   

    this.logout = this.logout.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
   
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if (email && password) {
      this.props.dispatch(userActions.login(email, password));
     
      
    }
    
  }

  handleSocialLogin = (user) => {
   
    let accessToken = user.token.accessToken;
    if(user._provider === "google"){
      console.log("pressed")
      this.props.dispatch(userActions.googleLogin(accessToken));
    }else{
      this.props.dispatch(userActions.facebookLogin(accessToken));
    }

    //
    // this.props.facebookLogin(accessToken);
  };

  handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  triggerLogin = (e) => {
    console.log(e);
  };

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node;
    }
  }

  // onLoginSuccess(user) {
  //   console.log(user);

  //   this.setState({
  //     logged: true,
  //     currentProvider: user._provider,
  //     user,
  //   });
  // }

  // onLoginFailure(err) {
  //   console.error(err);

  //   this.setState({
  //     logged: false,
  //     currentProvider: "",
  //     user: {},
  //   });
  // }

  handleLogoutSuccess() {
   
    this.setState({
      logged: false,
      currentProvider: "",
      user: {},
    });
  }

  handleLogoutFailure(err) {
    console.error(err);
  }

  logout() {
    const { logged, currentProvider } = this.state;

    if (logged && currentProvider) {
      this.nodes[currentProvider].props.triggerLogout();
    }
  }



  // handleForgotPassword = (e) => {
  //   e.preventDefault();
  //   const { email } = this.state;
  //   this.setState({ submitted: true });
  //   this.props.dispatch(userActions.requestResetEmail(email));
  // };
  

  render() {
    const { error } = this.props;
    const { loggingIn, email, password, submitted } = this.state;
    return (
      <section>
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-lg-6 border p-4">
              <div className="inner contact-right">
                <h4 className="mb-3">Login Account!</h4>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <form
                  action="#"
                  method="post"
                  className="signin-form"
                  onSubmit={this.handleSubmit}
                >
                  <div className="input-grids">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email*"
                        className="contact-input"
                        required=""
                        value={email}
                        onChange={this.handleChange}
                      />
                      {submitted && !email && (
                        <div className="help-block">Email is required</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group d-flex">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      className="contact-input"
                      value={password}
                      onChange={this.handleChange}
                    />
                  </div>
                  {submitted && !password && (
                    <div className="help-block">Password is required</div>
                  )}
                  <div className="form-group d-flex align-items-center justify-content-between">
                    <label htmlFor="keepMeSignedIn" className="keepMeSignedIn">
                      <input
                        type="checkbox"
                        name="keepMeSignedIn"
                        id="keepMeSignedIn"
                        className="contact-input"
                        onChange={this.handleChange}
                      />
                      Keep me Signed In
                    </label>
                    <a href="/registration">Register Account</a>
                  </div>
                  <div className="text-center">
                    <a href="/forgotpassword">Forgot Password</a>
                    <br />
                    <br />
                    <button
                      className="btn btn-primary theme-button"
                      disabled={loggingIn}
                    >
                      {loggingIn ? 'Logging in...' : 'Login Now'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
}

const mapStateToProps = (state) => {
  return state;
};


export default connect(mapStateToProps)(Login);
