import React from 'react';
import CategoryAside from 'components/CategoryAside/CategoryAside';
import './ShopBanners.css';
import { Link } from 'react-router-dom';
import config from 'services/config';
import PropTypes from 'prop-types';
import { shopActions } from 'store/actions';
import { store } from 'store/helpers';
import Loader from 'layout/Loader/Loader';
const { connect } = require("react-redux");

class ShopBanners extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.slug = this.props.match.params.slug;
        this.props.dispatch(shopActions.getCategory(this.slug));
        this.state = {
            shopBanners: this.props.shopBanners
        };
    }

    componentDidMount() {
        console.log(this.props.shopBanners)
        if (this.props.shopBanners) {
            this.setState({ shopBanners: this.props.shopBanners });
        }
    }

    componentDidUpdate(nextProps) {
        if (this.props.location.pathname === nextProps.location.pathname &&
            this.props.location.search === nextProps.location.search
        ) {
            // this means that no change in the path, so we don't need to refire the
            // action
            return;
        }

        store.dispatch(shopActions.getCategory(this.slug));
        const { shopBanners } = this.props
        if (nextProps.shopBanners !== shopBanners) {
            if (shopBanners) {
                this.setState({ shopBanners: shopBanners });
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            shopBanners: nextProps.shopBanners,
        };
    }

    renderBanners = () => {
        console.log(this.state.shopBanners)
        if (this.state.shopBanners) {
            if (this.state.shopBanners.categories) {
                var filterByContent = [this.slug];
                var contentArray = this.state.shopBanners.categories.filter(function (itm) {
                    return filterByContent.indexOf(itm.category_items) > -1;
                });
                contentArray = { content: contentArray };
                var filteredShopBanners = contentArray.content;
                const renderedBanners = filteredShopBanners.map(
                    (category, index) => {
                        return (
                            <div className="col-md-6 topBanners mb-3" key={index}>
                                <div className="ïnner ïnner position-relative hoverEffect">
                                    <Link to={category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT || category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT ? `/shop/${category.slug}` : `/designservice/${category.slug}`}>
                                        {/* <img src={category.logo} className="w-100" alt="img" /> */}
                                        <img src={`${config.apiUrl}/media/${category.logo}`} className="w-100" alt="img" />
                                        <div className="CategoryName">{category.categoryName}</div>
                                    </Link>
                                </div>
                            </div>
                        );
                    }
                );
                return <>{renderedBanners}</>;
            }
        }
    };

    render() {

        if (this.props.shopBanners.loading) return (<Loader />);
        const { match } = this.props;
        this.slug = match.params.slug;
        return (

            <section>
                <div className="container-fluid mb-3">
                    <div className="row no-gutters">
                        <div className="col-md-3">
                            <CategoryAside />
                        </div>

                        <div className="col-md-9">
                            <div className="inner p-5 bg-light">
                                <div className="row">
                                    {this.renderBanners()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return { shopBanners: state.shopBanners };
}
export default connect(mapStateToProps)(ShopBanners);