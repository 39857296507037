import { productConstants } from "store/constants";
let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};
export function enquiry(state = initialState, action) {
    switch (action.type) {
        case productConstants.ENQUIRY_POST_REQUEST:
            return {
              loggedIn: true,
            };
          case productConstants.ENQUIRY_POST_SUCCESS:
              console.log(action);
            return {
              // loggedIn: true,
              enquiry:action.enquiry.enquiry
            };
          case productConstants.ENQUIRY_POST_FAILURE:
            if (user) {
              return {
                loggedIn: true,
                // user: action.user
              };
            } else {
              return {
                loggedIn: false
              };
            }
        default:
            return state;
    }
}
  