export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',



    FORGOTPASSWORD_REQUEST: 'USERS_FORGOTPASSWORD_REQUEST',
    FORGOTPASSWORD_SUCCESS: 'USERS_FORGOTPASSWORD_SUCCESS',
    FORGOTPASSWORD_FAILURE: 'USERS_FORGOTPASSWORD_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    

    GOOGLE_SIGNIN_REQUEST : 'GOOGLE_SIGNIN_REQUEST',
    GOOGLE_SIGNIN_SUCCESS : 'GOOGLE_SIGNIN_SUCCESS',
    GOOGLE_SIGNIN_FAILURE : 'GOOGLE_SIGNIN_FAILURE',

    FACEBOOK_SIGNIN_REQUEST : 'FACEBOOK_SIGNIN_REQUEST',
    FACEBOOK_SIGNIN_SUCCESS : 'FACEBOOK_SIGNIN_SUCCESS',
    FACEBOOK_SIGNIN_FAILURE : 'FACEBOOK_SIGNIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    PROFILE_REQUEST: 'PROFILE_REQUEST',
    PROFILE_SUCCESS: 'PROFILE_SUCCESS',
    PROFILE_FAILURE: 'PROFILE_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE'    
};
