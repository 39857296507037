import { homeConstants } from 'store/constants';
import { contactService } from '../../services';

export const contactActions = {
    postContact
}

function postContact(data) {

    let formatted_Data = {
        name: data.name.value,
        email: data.email.value,
        phone_number: data.phone_number.value,
        subject: data.subject.value,
        message: data.message.value
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(formatted_Data));
            contactService.contact(formatted_Data).then(contact => {
                dispatch(success(contact));
                resolve(contact);
            },
                error => {
                    dispatch(failure(error.toString()));
                })
        });
    }

    function request() {
        return {
            type: homeConstants.CONTACT_REQUEST,
        }
    }

    function success(contact) {
        return {
            type: homeConstants.CONTACT_SUCCESS,
            contact
        }
    }

    function failure(error) {
        return {
            type: homeConstants.CONTACT_FAILURE,
            error
        }
    }
}