import ContactUsForm from 'components/forms/ContactUsForm/ContactUsForm';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from 'services/config';
import { homeActions } from 'store/actions';
import './Contact.css';


class Contact extends Component {
  constructor(props){
    super(props);
    this.state = {};
    document.title = "Realtime-7 | Contact Us";
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    this.pageData = null;
    this.props.dispatch(homeActions.getFooter());
  }
  
  getFooterMenuItemsFromAll = () => {
    var pageContentFilter = [config.CONTACT_US_SLUG];
    var pageContentArray = this.props.menuItems.filter(function (itm) {
      return pageContentFilter.indexOf(itm.page_slug) > -1;
    });
    pageContentArray = { content: pageContentArray };
    this.pageData = pageContentArray.content;
  }

  render() {
    return (
    <section className="w3l-contact">
    {this.props.menuItems && this.getFooterMenuItemsFromAll()}
    <div className="contact-infubd py-5">
      <div className="container py-lg-3">
        <div className="contact-grids row">
          <div className="col-lg-6 contact-left">
          {this.props.menuItems && (this.pageData.map((item) => (
                  <div key={item.page_slug} dangerouslySetInnerHTML={{ __html: item.content }} />
                )))}
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5 contact-right">
           <ContactUsForm/>
          </div>
        </div>
          <div className="map mt-5 pt-md-5">
            <h5>Our location</h5>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d93169.07630463324!2d-80.350735145034!3d43.135322340689875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c68a65f27af83%3A0x67149bc22ce1fc8c!2s27%20Catharine%20Ave%2C%20Brantford%2C%20ON%20N3T%201X5%2C%20Canada!5e0!3m2!1sen!2sin!4v1619531540636!5m2!1sen!2sin" style={{border:0}} allowfullscreen="" loading="lazy" title="Map"></iframe>
          </div>
          <div className="form mt-5 pt-md-5">
          </div>
        </div>
      </div>
</section>
    );
  }
}

const mapStateToProps =(state)=>{
  return { state, menuItems: state.footerMenu && state.footerMenu.menu ? state.footerMenu.menu : null }
}

export default connect(mapStateToProps)(Contact);