import { shopConstants } from "store/constants/shop.constants";

export function shopBanners(state = {}, action) {
    switch (action.type) {
        case shopConstants.SHOP_SINGLE_CATEGORY_REQUEST:
            return {
                loading: true,
            };
        case shopConstants.SHOP_SINGLE_CATEGORY_SUCCESS:
            return {
                loading: false,
                //categories: action.categories.results,
                categories: action.categories,
            };

        case shopConstants.SHOP_SINGLE_CATEGORY_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
