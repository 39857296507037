import config from "./config";
import { authHeader } from "../store/helpers";

export const productService = {
  getProducts,
  getProductDetails,
  getCategory,
  getCategoryData
};

function getProducts(pageNumber) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${config.apiUrl}/customer/products?page=` + pageNumber,
    requestOptions
  ).then(handleResponse);
}


function getProductDetails(productId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }

  return fetch(`${config.apiUrl}/customer/product-details/${productId}/`, requestOptions)
    .then(handleResponse)
    .then(product => {
      //console.log(product);
      if (product && product.category) { // added raghwendra in api on 03/05/2021 unnecessary so I had to delete
        delete product.category;
      }

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      return product;
    });
}

function getCategory() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrl}/eadmin/list-category/`, requestOptions)
    .then(handleResponse)
    .then(categories => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      return categories;
    });
}

function getCategoryData(productId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }

  return fetch(`${config.apiUrl}/customer/product-details/${productId}/`, requestOptions)
    .then(handleResponse)
    .then(category => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      return category;
    });
}

function handleResponse(response) {
  // console.log(response);return 0;
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      console.log(response);
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
