import { productConstants } from '../constants';
import { productService } from '../../services';
import { alertActions } from '.';

export const productActions = {
    allProducts,
    getProductDetails,
    getCategory,

};

function allProducts(pageNumber) {
    return dispatch => {
        dispatch(request({ pageNumber }));
        productService.getProducts(pageNumber)
            .then(
                products => {
                    dispatch(success(products));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(products) { return { type: productConstants.PRODUCT_LISTING_REQUEST, products } }
    function success(products) { return { type: productConstants.PRODUCT_LISTING_REQUEST, products } }
    function failure(error) { return { type: productConstants.PRODUCT_LISTING_REQUEST, error } }
}

function getProductDetails(productId) {
    return dispatch => {
        dispatch(request(productId));

        productService.getProductDetails(productId)
            .then(
                productData => dispatch(success(productData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: productConstants.PRODUCT_DETAIL_REQUEST } }
    function success(productData) { return { type: productConstants.PRODUCT_DETAIL_SUCCESS, productData } }
    function failure(error) { return { type: productConstants.PRODUCT_DETAIL_FAILURE, error } }
}


function getCategory() {
    return dispatch => {
        dispatch(request());

        productService.getCategory()
            .then(
                categories => dispatch(success(categories)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: productConstants.CATEGORY_LISTING_REQUEST } }
    function success(categories) { return { type: productConstants.CATEGORY_LISTING_SUCCESS, categories } }
    function failure(error) { return { type: productConstants.CATEGORY_LISTING_FAILURE, error } }
}


