import React, { Component } from 'react';

class Personal extends Component {

    render() {
        return (
            <div className="form-card">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label class="col-md-4 col-form-label">First Name</label>
                            <div className="col-md-8">
                                <input type="text" id="fname" placeholder="First Name*" className="form-control" value={this.props.fname}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.fnameError}</small></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label class="col-md-4 col-form-label">Last Name</label>
                            <div className="col-md-8">
                                <input type="text" id="lname" placeholder="Last Name*" className="form-control" value={this.props.lname}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.lnameError}</small></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <label class="col-md-2 col-form-label">Address</label>
                    <div className="col-md-10">
                        <input type="text" placeholder="Address" className="form-control" id="address" value={this.props.address}
                            onChange={this.props.onChange} required />
                        <span className="pull-right text-danger"><small>{this.props.addressError}</small></span>
                    </div>
                </div>

                <div className="form-group row">
                    <label class="col-md-2 col-form-label">Country</label>
                    <div className="col-md-10">
                        <input type="text" id="country" placeholder="Country" className="form-control" value={this.props.country}
                            onChange={this.props.onChange} required />
                        {/* <select className="form-control">
                            <option>
                                Select Country
                                    </option>
                            <option>
                                US
                                    </option>
                            <option>
                                India
                                    </option>
                        </select> */}

                        <span className="pull-right text-danger"><small>{this.props.countryError}</small></span>
                    </div>

                    {/* <div className="col-md-12">
                        <div className="form-group">
                            
                        </div>
                    </div> */}
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group row">
                            <label class="col-md-3 col-form-label">State</label>
                            <div className="col-md-9">
                                <input type="text" id="state" placeholder="State*" className="form-control" value={this.props.state}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.stateError}</small></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group row">
                            <label class="col-md-3 col-form-label">City</label>
                            <div className="col-md-9">
                                <input type="text" id="city" placeholder="City*" className="form-control" value={this.props.city}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.cityError}</small></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group row">
                            <label class="col-md-3 col-form-label">Zip</label>
                            <div className="col-md-9">
                                <input type="text" placeholder="Zip*" className="form-control" id="zip" value={this.props.zip}
                                    onChange={this.props.onChange} required />
                                <span className="pull-right text-danger"><small>{this.props.zipError}</small></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-md-6">
                        <button className="btn btn-secondary"
                            onClick={this.props.prev}>Prev</button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-primary pull-right"
                            onClick={this.props.next}>Next</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Personal;
