import config from "./config";
//import { authHeader } from "./../store/helpers";

export const shopService = {
  getCategory,
  getAllCategories,
  getCustomerProducts,
  getEnterpriseProducts
};

function getAllCategories(type) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${config.apiUrl}/website/category/`,requestOptions)
    .then(handleResponse)
    .then((categories) => {
      return categories;
    });
}

function getCategory(type) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${config.apiUrl}/website/category?category_item=${type}`,requestOptions)
    .then(handleResponse)
    .then((categories) => {
      //console.log(categories);
      return categories;
    });
}

function getCustomerProducts(categoryId) {
  /*const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };*/

  return fetch(`${config.apiUrl}/customer/products/?category=${categoryId}`)
    .then(handleResponse)
    .then((categoryProducts) => {
      return categoryProducts;
    });
}

function getEnterpriseProducts(categoryId) {
  /*const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };*/

  return fetch(`${config.apiUrl}/customer/products/?category=${categoryId}`)
    .then(handleResponse)
    .then((categoryProducts) => {
      return categoryProducts;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
