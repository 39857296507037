import { promotionsConstants } from './../constants';

export function promotions(state = {}, action) {
    switch (action.type) {
        case promotionsConstants.PROMOTIONS_REQUEST:
            return {
                loading: true
            }
        case promotionsConstants.PROMOTIONS_SUCCESS:
            return {
                loading: false,
                items: action.banners.results
            }

        case promotionsConstants.PROMOTIONS_FAILURE:
            return {
                error: action.error
            }
        default:
            return state;
    }

}