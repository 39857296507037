import React, {createRef } from "react";
import * as THREE from "three/build/three.module.js";
import "./dxf.css";
import DxfParser from "dxf-parser";
import * as $ from "jquery";
import * as ThreeDxf from "three-dxf";
//let progress = document.getElementById("file-progress-bar");
//let progressClass = $(".progress");
//let scene = new THREE.Scene();
const DXF_URL = "./../../assets/dxf/LaserTestEx.dxf";
class DXF extends React.Component {
  constructor(props) {
    super(props);
    document.title = "Dwight | DXF 2D Viewer";
    this.el = createRef();
  }

  componentDidMount() {
    this.autoLoadFile();
  }

  autoLoadFile() {
    fetch(DXF_URL)
      .then((response) => response.blob())
      .then((file) => {
        var reader = new FileReader();
        reader.onprogress = this.updateProgress;
        reader.onloadend = this.onSuccess;
        reader.onabort = this.abortUpload;
        reader.onerror = this.errorHandler;
        reader.readAsText(file);
      })
      .catch((err) => console.log(err));
  }
  componentWillUnmount() { }

  handleFileSelect(evt) {
    let progress = document.getElementById("file-progress-bar");
    let progressClass = $(".progress");
    progress.style.width = "0%";
    progress.textContent = "0%";

    let file = evt.target.files[0];
    let output = [];
    output.push(
      "<li><strong>",
      encodeURI(file.name),
      "</strong> (",
      file.type || "n/a",
      ") - ",
      file.size,
      " bytes, last modified: ",
      file.lastModifiedDate
        ? file.lastModifiedDate.toLocaleDateString()
        : "n/a",
      "</li>"
    );
    document.getElementById("file-description").innerHTML =
      "<ul>" + output.join("") + "</ul>";

    progressClass.addClass("loading");

    var reader = new FileReader();
    reader.onprogress = this.updateProgress;
    reader.onloadend = this.onSuccess;
    reader.onabort = this.abortUpload;
    reader.onerror = this.errorHandler;
    reader.readAsText(file);
  }

  updateProgress(evt) {
    let progress = document.getElementById("file-progress-bar");
    console.log("progress");
    console.log(Math.round((evt.loaded / evt.total) * 100));
    if (evt.lengthComputable) {
      var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
      if (percentLoaded < 100) {
        progress.style.width = percentLoaded + "%";
        progress.textContent = percentLoaded + "%";
      }
    }
  }

  onSuccess(evt) {
    let fileReader = evt.target;
    let dxfContentEl = $("#dxf-content")[0];
    let progress = document.getElementById("file-progress-bar");
    let progressClass = $(".progress");
    if (fileReader.error) return console.log("error onloadend!?");
    progress.style.width = "100%";
    progress.textContent = "100%";
    setTimeout(function () {
      progressClass.removeClass("loading");
    }, 2000);
    var parser = new DxfParser();
    var dxf = parser.parseSync(fileReader.result);

    if (dxf) {
      dxfContentEl.innerHTML = JSON.stringify(dxf, null, 2);
    } else {
      dxfContentEl.innerHTML = "No data.";
    }
    // Three.js changed the way fonts are loaded, and now we need to use FontLoader to load a font
    //  and enable TextGeometry. See this example http://threejs.org/examples/?q=text#webgl_geometry_text
    //  and this discussion https://github.com/mrdoob/three.js/issues/7398
    var font;
    const width = 380;
    const height = 350;

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );
    // this.camera.lookAt(0, 0, 5);
    this.camera.position.y = 5;
    let alight = new THREE.AmbientLight(0xffffff);

    this.scene.add(alight);
    this.mesh = new THREE.Mesh();

    var loader = new THREE.FileLoader();

    loader.load(
      "./../../assets/fonts/helvetiker_regular.typeface.json",
      function (response) {
        font = response;
        new ThreeDxf.Viewer(
          dxf,
          document.getElementById("cad-view"),
          width,
          height,
          font
        );
      }
    );
  }
  render() {
    return (
      <React.Fragment>
        <section>
          <div className="productView">
            <div className="inner">
              <div className="productTop p-3">
                <div className="row">
                  <div className="col-lg-3">
                    <div>
                      <div className="metricfilter">
                        <button className="btn-metric">Metric</button>
                        <select name id="unit-select" className="p-1">
                          <option value="mm" defaultValue>
                            mm
                          </option>
                          <option value="in">inches</option>
                        </select>
                      </div>

                      {/* <div className="slidecontainer" style={{ margin: "5px" }}>
                        <p>Change Volume</p>
                        <input
                          type="range"
                          id="in-dim-percent"
                          min={0}
                          max={100}
                          defaultValue={100}
                        />
                        <span
                          id="op-dim-percent"
                          style={{ color: "white", margin: "5px" }}
                        >
                          100%
                        </span>
                      </div> */}
                      <table className="mt-sm-5">
                        <tbody>
                          <tr id="model-dimension" className="theme-border">
                            <td>Width:</td>
                            <td>0</td>
                            <td>Height:</td>
                            <td>0</td>
                            <td>Depth:</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="service tooltip2 mt-5 w-75">
                        <div className="progress progress-striped">
                          <div id="file-progress-bar" className="progress-bar progress-bar-success" role="progressbar" style={{ width: 0 }}> </div>
                        </div>
                        <div id="file-description" className="help-block text-white"></div>
                        <p className="text-white mt-4">
                          While hovering over the canvas: Right click to pan. Mouse
                          wheel to zoom in an out.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div id="cad-view" className="overflow-auto text-center" ></div>
                    <div id="dxf-content-container" className="d-none">
                      <pre id="dxf-content"></pre>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="table-responsive">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th />
                            <th>Units</th>
                            <th>Line Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Machine Time</td>
                            <td>4hrs</td>
                            <td>240.00</td>
                          </tr>
                          <tr>
                            <td>Material Copper</td>
                            <td>122g</td>
                            <td>12.54</td>
                          </tr>
                          <tr>
                            <td>Shipping</td>
                            <td />
                            <td>22.00</td>
                          </tr>
                          <tr>
                            <td>Tax</td>
                            <td />
                            <td>00.00</td>
                          </tr>
                          <tr>
                            <td />
                            <td>USD$</td>
                            <td>274.54</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="text-center mt-5">
                      <button className="btn btn-primary w-100">
                        {" "}
                        <i className="fa fa-shopping-cart mr-2"> </i>Purchase Now</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="productFilter p-3 row m-0">
                <div className="service col-lg-3 tooltip2">
                  <label> Services</label>
                  <select name="service" id className="form-control">
                    <option value="service 1">Service 1</option>
                    <option value="service 1">Service 2</option>
                  </select>
                  <span className="tooltiptext">Tooltip text</span>
                </div>
                <div className="material col-lg-3 tooltip2">
                  <label> Material</label>
                  <select
                    name="material"
                    id="material"
                    className="form-control"

                  >
                    <option value>Select Material</option>
                    <option value="m1">Copper</option>
                    <option value="m2">Ceramic</option>
                    <option value="m3">Almunium</option>
                  </select>
                  <span className="tooltiptext">Tooltip text</span>
                </div>
                <div className="shipping col-lg-3 tooltip2">
                  <label> Shipping</label>
                  <select name="countryShipping" id className="form-control">
                    <option value="m1">India</option>
                    <option value="m2">Australia</option>
                    <option value="m2">The United States</option>
                  </select>
                  <span className="tooltiptext">Tooltip text</span>
                </div>
                <div className="uploadCustom col-lg-3 tooltip2">
                  <label> Upload Custom File</label>
                  <input
                    type="file"
                    accept=".dxf"
                    id="dxf"
                    name="file"
                    onChange={(e) => this.handleFileSelect(e)}
                  />
                  <span className="tooltiptext">Tooltip text</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default DXF;
