import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from 'classnames';
import { contactActions } from "store/actions";
import { validateFields } from '../../../utils/Validation';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const initialState = {
  email: {
    value: '',
    validateOnChange: false,
    error: ''
  },
  name: {
    value: '',
    validateOnChange: false,
    error: ''
  },
  phone_number: {
    value: '',
    validateOnChange: false,
    error: ''
  },
  subject: {
    value: '',
    validateOnChange: false,
    error: ''
  },
  message: {
    value: '',
    validateOnChange: false,
    error: ''
  },
  submitCalled: false,
  allFieldsValidated: false
};

class ContactUsForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  submitHandler = e => {
    e.preventDefault();
    e.target.className += " was-validated";
    //e.target.reset();
    this.props.dispatch(contactActions.postContact(this.state));
  };

  handleSubmit(evt) {
    evt.preventDefault();
    // validate all fields
    const { email, name, phone_number, subject, message } = this.state;

    const emailError = validateFields.validateEmail(email.value);
    const nameError = validateFields.validateName(name.value);
    const phoneError = validateFields.validatePhone(phone_number.value);
    const subjectError = validateFields.validateSubject(subject.value);
    const messageError = validateFields.validateMessage(message.value);

    if ([emailError, nameError, phoneError, subjectError, messageError].every(e => e === false)) {
      this.setState({ ...initialState, allFieldsValidated: true });
      this.props.dispatch(contactActions.postContact(this.state)).then((result) => {
        if (result)
          toast("Your response has been recorded successfully",{position: "top-center"});
      });
      this.showAllFieldsValidated();
    } else {
      // update the state with errors
      this.setState(state => ({
        email: {
          ...state.email,
          validateOnChange: true,
          error: emailError
        },
        name: {
          ...state.name,
          validateOnChange: true,
          error: nameError
        },
        phone_number: {
          ...state.phone_number,
          validateOnChange: true,
          error: phoneError
        },
        subject: {
          ...state.subject,
          validateOnChange: true,
          error: subjectError
        },
        message: {
          ...state.message,
          validateOnChange: true,
          error: messageError
        }
      }));
    }
  }

  showAllFieldsValidated() {
    setTimeout(() => {
      this.setState({ allFieldsValidated: false });
    }, 1500);
  }

  handleBlur(validationFunc, evt) {
    const field = evt.target.name;
    if (
      this.state[field]['validateOnChange'] === false &&
      this.state.submitCalled === false
    ) {
      this.setState(state => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value)
        }
      }));
    }
    return;
  }

  handleChange(validationFunc, evt) {
    const field = evt.target.name;
    const fieldVal = evt.target.value;
    this.setState(state => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]['validateOnChange'] ? validationFunc(fieldVal) : ''
      }
    }));
  }

  render() {
    const { email, name, phone_number, subject, message, allFieldsValidated } = this.state;

    return (
      <form
        className="needs-validation signin-form"
        //noValidate
        onSubmit={evt => this.handleSubmit(evt)}
      >
        <div className="input-grids">

          {/* Name field */}
          <div className="form-group">
            <input
              type="text"
              name="name"
              autoComplete="off"
              value={name.value}
              placeholder="Your Name*"
              className={classnames(
                'form-control',
                { 'is-valid': name.error === false },
                { 'is-invalid': name.error }
              )}
              onChange={evt =>
                this.handleChange(validateFields.validateName, evt)
              }
              onBlur={evt =>
                this.handleBlur(validateFields.validateName, evt)
              }
            />
            <div className="invalid-feedback">{name.error}</div>
          </div>

          {/* Email field */}
          <div className="form-group">
            <input
              type="email"
              name="email"
              value={email.value}
              placeholder="Your Email*"
              className={classnames(
                'form-control',
                { 'is-valid': email.error === false },
                { 'is-invalid': email.error }
              )}
              onChange={evt =>
                this.handleChange(validateFields.validateEmail, evt)
              }
              onBlur={evt =>
                this.handleBlur(validateFields.validateEmail, evt)
              }
            />
            <div className="invalid-feedback">{email.error}</div>
          </div>

          {/* Phone field */}
          <div className="form-group">
            <input
              type="text"
              name="phone_number"
              placeholder="Phone*"
              // minLength="10"
              maxLength="10"
              pattern="\d*"
              value={phone_number.value}
              className={classnames(
                'form-control',
                { 'is-valid': phone_number.error === false },
                { 'is-invalid': phone_number.error }
              )}
              onChange={evt =>
                this.handleChange(validateFields.validatePhone, evt)
              }
              onBlur={evt =>
                this.handleBlur(validateFields.validatePhone, evt)
              }
            />
            <div className="invalid-feedback">{phone_number.error}</div>
          </div>

          {/* Subject field */}
          <div className="form-group">
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Subject*"
              value={subject.value}
              className={classnames(
                'form-control',
                { 'is-valid': subject.error === false },
                { 'is-invalid': subject.error }
              )}
              onChange={evt =>
                this.handleChange(validateFields.validateSubject, evt)
              }
              onBlur={evt =>
                this.handleBlur(validateFields.validateSubject, evt)
              }
            />
            <div className="invalid-feedback">{subject.error}</div>
          </div>
        </div>

        {/* Message field */}
        <div className="form-group">
          <textarea
            name="message"
            id="message"
            className="form-control"
            placeholder="Type your message here*"
            value={message.value}
            className={classnames(
              'form-control',
              { 'is-valid': message.error === false },
              { 'is-invalid': message.error }
            )}
            onChange={evt =>
              this.handleChange(validateFields.validateMessage, evt)
            }
            onBlur={evt =>
              this.handleBlur(validateFields.validateMessage, evt)
            }
          />
          <div className="invalid-feedback">{message.error}</div>
        </div>

        <div className="text-right">
          <button
            className="btn btn-primary theme-button"
            onMouseDown={() => this.setState({ submitCalled: true })}
          >Send Message</button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}
export default connect(mapStateToProps)(ContactUsForm);