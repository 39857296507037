import React, { Component } from 'react';
import CarouselSlider from 'react-carousel-slider';
import './DxfItems.css';
import jsonData from './../DfxItems/DxfItems.json';

class DxfItems extends Component {
    /*constructor(props) {
        super(props);        
      }*/
    // componentWillMount(){}
    // componentDidMount(){}
    // componentWillUnmount(){}

    // componentWillReceiveProps(){}
    // shouldComponentUpdate(){}
    // componentWillUpdate(){}
    // componentDidUpdate(){}
 
    dxfDetails(e) {        
        console.log(e.target.currentSrc);
      }
    render() { 
        
        let iconItemsStyle = {
            padding: "0px",
            background: "transparent",
            margin: "0 5px",
            height: "50%"
        };

        let iconsSlides = jsonData.icons.dxfFiles.map((item, index) =>
            <div key={index} >
                <img src={item.imgSrc}  onClick={this.dxfDetails.bind(this)} alt="img"/> 
                {/* <p style={{ width: "60%", top: "70%", fontSize: "10px" }} >{item.des}</p> */}
            </div>
        );

        let dxfFiles = (<CarouselSlider
            sliderBoxStyle={{ height: "auto", width: "100%", background: "transparent" }}
            accEle={{ dots: false }}
            slideCpnts={iconsSlides}
            itemsStyle={iconItemsStyle}
            buttonSetting={{ placeOn: 'middle-outside' }}
        />);

        return (<div className="dxfFilesSlider" style={{ position: "relative", margin: "0 auto", width: "100%" }} >
            {dxfFiles}
        </div>);
    }
}
export default DxfItems;
