import React, { Component } from 'react';
import './Header.css';
/*import {
  NavLink
} from "react-router-dom";*/
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { shopActions } from 'store/actions';
import { connect } from 'react-redux';
import config from 'services/config';
import AppContext from 'contexts/AppContext';
//import { history } from 'store/helpers';
class Header extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(shopActions.getAllCategories());
    this.aboutItem = null;
  }

  getAboutMenuItemFromAll = () => {
    var itemFilterBy = [config.ABOUT_US_SLUG];//Is a footer menu item
    var filteredArray = this.props.menuItems.filter(function (itm) {
      return itemFilterBy.indexOf(itm.page_slug) > -1;
    });
    filteredArray = { aboutItem: filteredArray };
    this.aboutItem = filteredArray;
  }

  customerMenu = () => {
    if (this.props.categories !== undefined) {
      if (this.props.categories.categories !== undefined) {
        // const customerMenuItems = this.props.categories.categories.results.map((category, index) => {
        const customerMenuItems = this.props.categories.categories.map((category, index) => {
          return (<>
            { category.category_items === config.CATEGORY_NON_ENTERPRISE_PRODUCT ?
              <Nav.Item as="li" key={index}>
                <Link to={`/shop/${category.slug}`} className="menu-link menu-list-link">{category.categoryName}</Link>
              </Nav.Item> : ''}
          </>)
        })
        return (
          <>
            <Nav.Item as="li">
              {/* <Link to={`/category/${config.CATEGORY_NON_ENTERPRISE_PRODUCT}`} className="menu-link mega-menu-link mega-menu-header">{config.NON_ENTERPRISE_PRODUCT_TEXT}</Link> */}
              <ul className="menu menu-list">
                {customerMenuItems}
              </ul>
            </Nav.Item>
          </>
        )
      }
    }
  }

  designMenu = () => {
    if (this.props.categories !== undefined) {
      if (this.props.categories.categories !== undefined) {
        //const designMenuItems = this.props.categories.categories.results.map((category, index) => {
        const designMenuItems = this.props.categories.categories.map((category, index) => {
          return (
            <>
              { category.category_items === config.CATEGORY_DESIGN_PRODUCT ? <Nav.Item as="li" key={index}>
                <Link to={`/designservice/${category.slug}`} className="menu-link menu-list-link">{category.categoryName}</Link>
              </Nav.Item> : ''}
            </>)
        })

        return (
          <>
            {designMenuItems}
          </>
        )
      }
    }

  }

  

  partnersMenu = () => {
    if (this.props.categories !== undefined) {
      if (this.props.categories.categories !== undefined) {
        // const customerMenuItems = this.props.categories.categories.results.map((category, index) => {
        const partnersMenuItems = this.props.categories.categories.map((category, index) => {
          return (<>
            { category.category_items === config.CATEGORY_PARTNERS ?
              <Nav.Item as="li" key={index}>
                <Link to={`/designservice/${category.slug}`} className="menu-link menu-list-link">{category.categoryName}</Link>
              </Nav.Item> : ''}
          </>)
        })
        return (
          <>
            <Nav.Item as="li">
              {/* <Link to={`/category/${config.CATEGORY_NON_ENTERPRISE_PRODUCT}`} className="menu-link mega-menu-link mega-menu-header">{config.NON_ENTERPRISE_PRODUCT_TEXT}</Link> */}
              <ul className="menu menu-list">
                {partnersMenuItems}
              </ul>
            </Nav.Item>
          </>
        )
      }
    }
  }



  enterpriseMenu = () => {
    if (this.props.categories !== undefined) {
      if (this.props.categories.categories !== undefined) {
        //console.log(this.props.categories);
        // const enterpriseMenuItems = this.props.categories.categories.results.map((category, index) => {
        const enterpriseMenuItems = this.props.categories.categories.map((category, index) => {
          return (<>
            { category.category_items === config.CATEGORY_ENTERPRISE_PRODUCT ? <Nav.Item as="li" key={index}>
              <Link to={`/shop/${category.slug}`} className="menu-link menu-list-link">{category.categoryName}</Link>
            </Nav.Item> : ''}
          </>)
        })
        return (
          <>
            <Nav.Item as="li">
              {/* <Link to={`/category/${config.CATEGORY_ENTERPRISE_PRODUCT}`} className="menu-link mega-menu-link mega-menu-header">{config.ENTERPRISE_PRODUCT_TEXT}</Link> */}
              <ul className="menu menu-list">
                {enterpriseMenuItems}
              </ul>
            </Nav.Item>
          </>
        )
      }
    }
  }

  dynamicMenu = () => {
    if (this.props.categories !== undefined) {
      if (this.props.categories.categories !== undefined) {
        return (
          <ul className="mega-menu mega-menu--flat">
            {this.enterpriseMenu()}
            {this.customerMenu()}
            <Nav.Item as="li" className="mobile-menu-back-item">
              <Link to="/#" className="menu-link mobile-menu-back-link">Back</Link>
            </Nav.Item>
          </ul>
        )
      }
    }
  }


  dynamicMenu1 = () => {
    if (this.props.categories !== undefined) {
      if (this.props.categories.categories !== undefined) {
        return (
          <ul className="mega-menu mega-menu--flat">
            
            {this.customerMenu()}
            <Nav.Item as="li" className="mobile-menu-back-item">
              <Link to="/#" className="menu-link mobile-menu-back-link">Back</Link>
            </Nav.Item>
          </ul>
        )
      }
    }
  }

  dynamicMenu2 = () => {
    if (this.props.categories !== undefined) {
      if (this.props.categories.categories !== undefined) {
        return (
          <ul className="mega-menu mega-menu--flat">
            {this.enterpriseMenu()}
            <Nav.Item as="li" className="mobile-menu-back-item">
              <Link to="/#" className="menu-link mobile-menu-back-link">Back</Link>
            </Nav.Item>
          </ul>
        )
      }
    }
  }

  dynamicMenu3 = () => {
    if (this.props.categories !== undefined) {
      if (this.props.categories.categories !== undefined) {
        return (
          <ul className="mega-menu mega-menu--flat">
            {this.partnersMenu()}
            <Nav.Item as="li" className="mobile-menu-back-item">
              <Link to="/#" className="menu-link mobile-menu-back-link">Back</Link>
            </Nav.Item>
          </ul>
        )
      }
    }
  }


  render() {
    return (
      <AppContext.Consumer>
        {({ cartItems, setCartItems }) => (
          <header className="w3l-header mob-bars" id="forClick">
            <div className="hero-header-11">
              <div className="hero-header-11-content">
                <div className="container-fluid">
                  <div className="nav">
                    <Navbar>
                      <Link to="/" className="mobile-menu-trigger"><i className="fa fa-bars"></i></Link>
                      <Nav className="menu menu-bar" as="ul" id="megaMenu">
                        <Nav.Item as="li"><Link to="/" className="menu-link menu-bar-link">Home</Link></Nav.Item>


                        <Nav.Item as="li">
                          <Link to={`/category/${config.CATEGORY_NON_ENTERPRISE_PRODUCT}`} className="menu-link menu-bar-link" aria-haspopup="true">Products</Link>
                          {this.dynamicMenu1()}
                        </Nav.Item>

                       




                        <Nav.Item as="li">
                          {/* <Link to="/#" className="menu-link menu-bar-link" aria-haspopup="true">Products</Link> */}
                          <ul className="mega-menu mega-menu--multiLevel"  >
                            <Nav.Item as="li">
                              {/* <Link to="/#" className="menu-link mega-menu-link" aria-haspopup="true">2D Services</Link> */}
                              {/* { <Link to="/vipercam" className="menu-link mega-menu-link" >Viper CAM</Link> } */}
                              <Nav as="ul" className="menu menu-list">
                                <Nav.Item as="li">
                                  {/* <Link to="/dxf" className="menu-link menu-list-link">DXF Viewer</Link> */}
                                </Nav.Item>
                                {/* <Nav.Item as="li">
                                  <Link to="/#" className="menu-link menu-list-link" aria-haspopup="true">1.1.2 Flyout link</Link>
                                  <Nav as="ul" className="menu menu-list">
                                    <Nav.Item as="li">
                                      <Link to="/products" className="menu-link menu-list-link">1.1.2.1 Page link</Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                      <Link to="/products" className="menu-link menu-list-link">1.1.2.2 Page link</Link>
                                    </Nav.Item>
                                  </Nav>
                                </Nav.Item> */} 
                              </Nav>
                            </Nav.Item>

                            <Nav.Item as="li">
                              {/* <Link to="/#" className="menu-link mega-menu-link" aria-haspopup="true">3D Services</Link> */}
                               {/* {<Link to="/#" className="menu-link mega-menu-link" >IMX SOM BOARDS</Link> } */}
                              <Nav as="ul" className="menu menu-list">
                                <Nav.Item as="li">
                                  {/* <Link to="/stlloader" className="menu-link menu-list-link">STL Loader</Link> */}
                                </Nav.Item>
                                {/* <Nav.Item as="li">
                              <Link to="/products" className="menu-link menu-list-link">3D Services Inner Link</Link>
                            </Nav.Item> */}
                              </Nav>
                            </Nav.Item>

                            <Nav.Item as="li" className="mobile-menu-back-item">
                              <Link to="/#" className="menu-link mobile-menu-back-link">Back</Link>
                            </Nav.Item>

                        <Nav.Item as="li">
                              {/* <Link to="/#" className="menu-link mega-menu-link" aria-haspopup="true">3D Services</Link> */}
                               {/* {<Link to="/#" className="menu-link mega-menu-link" >BASE BOARDS</Link> } */}
                              <Nav as="ul" className="menu menu-list">
                                <Nav.Item as="li">
                                  {/* <Link to="/stlloader" className="menu-link menu-list-link">STL Loader</Link> */}
                                </Nav.Item>
                                {/* <Nav.Item as="li">
                              <Link to="/products" className="menu-link menu-list-link">3D Services Inner Link</Link>
                            </Nav.Item> */}
                              </Nav>
                            </Nav.Item>
                            <Nav.Item as="li" className="mobile-menu-back-item">
                              <Link to="/#" className="menu-link mobile-menu-back-link">Back</Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              {/* <Link to="/#" className="menu-link mega-menu-link" aria-haspopup="true">3D Services</Link> */}
                               {/* {<Link to="/#" className="menu-link mega-menu-link" >CAMERA BOARDS</Link> } */}
                              <Nav as="ul" className="menu menu-list">
                                <Nav.Item as="li">
                                  {/* <Link to="/stlloader" className="menu-link menu-list-link">STL Loader</Link> */}
                                </Nav.Item>
                                {/* <Nav.Item as="li">
                              <Link to="/products" className="menu-link menu-list-link">3D Services Inner Link</Link>
                            </Nav.Item> */}
                              </Nav>
                            </Nav.Item>
                            <Nav.Item as="li" className="mobile-menu-back-item">
                              <Link to="/#" className="menu-link mobile-menu-back-link">Back</Link>
                            </Nav.Item>
                          </ul>
                        </Nav.Item>


                        
                        
                        <Nav.Item as="li">
                          <Link to={`/category/${config.CATEGORY_DESIGN_PRODUCT}`} className="menu-link menu-bar-link" aria-haspopup="true">{config.DESIGN_PRODUCT_TEXT}</Link>
                          <ul className="mega-menu mega-menu--flat " >
                            <Nav.Item as="li">
                              <ul className="menu menu-list">
                                {this.designMenu()}
                              </ul>
                            </Nav.Item>



                         
                            
                            {/* <Nav.Item as="li" className="mega-menu-content">
                              <p className="mega-menu-header mt-3">3.2 Page link header</p>
                              <p>This is just static content. You can add anything here. Images, text, buttons, your grandma's
                          secrect recipe.</p>
                            </Nav.Item> */}
                            <Nav.Item as="li" className="mobile-menu-back-item">
                              <Link to="/#" className="menu-link mobile-menu-back-link">Back</Link>
                            </Nav.Item>
                          </ul>
                        </Nav.Item>
                        {/* <Nav.Item as="li"><Link to="/software" className="menu-link menu-bar-link">Softwares</Link></Nav.Item> */}
                        {/* <Nav.Item as="li">
                      <Link to="/#" className="menu-link menu-bar-link" aria-haspopup="true">Pages</Link>
                      <ul className="mega-menu mega-menu--flat " >
                        <Nav.Item as="li">
                          <ul className="menu menu-list">
                            <Nav.Item as="li">
                              <Link to="/addressdetails" className="menu-link menu-list-link">
                               Address & Details
                              </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <Link to="/checkout" className="menu-link menu-list-link">
                                Checkout
                              </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <Link to="/receipt" className="menu-link menu-list-link">
                                Reciept
                              </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <Link to="/thankyou" className="menu-link menu-list-link">
                                Thank you
                              </Link>
                            </Nav.Item>
                          </ul>
                        </Nav.Item>
                      </ul>
                    </Nav.Item> */}
                    
                    <Nav.Item as="li">
                          <Link to={`/category/${config.CATEGORY_PARTNERS}`} className="menu-link menu-bar-link" aria-haspopup="true">{config.CATEGORY_PARTNERS_TEXT}</Link>
                          {this.dynamicMenu3()}
                        </Nav.Item>

                    <Nav.Item as="li">
                          <Link to={`/category/${config.CATEGORY_ENTERPRISE_PRODUCT}`} className="menu-link menu-bar-link" aria-haspopup="true">Shop</Link>
                          {this.dynamicMenu2()}
                        </Nav.Item>

                        
                       {/* <Nav.Item as="li"><Link to="#" className="menu-link menu-bar-link" aria-haspopup="true">Shop</Link>
                          {this.dynamicMenu()}
                        </Nav.Item> */}
                        <Nav.Item as="li"><Link to={`/page/${config.ABOUT_US_SLUG}`} className="menu-link menu-bar-link">About Us</Link></Nav.Item>
                        <Nav.Item as="li"><Link to={`${config.CONTACT_PAGE_URL}`} className="menu-link menu-bar-link">Contact Us</Link></Nav.Item>

                        {/* <Nav.Item as="li" className="mobile-menu-header"><Link to="/" className> <span>Home</span></Link>
                    </Nav.Item> */}
                      </Nav>
                    </Navbar>
                  </div>
                </div>
              </div>
            </div>
          </header>

        )}
      </AppContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return { categories: state.categories, menuItems: state.footerMenu && state.footerMenu.menu ? state.footerMenu.menu : null };
}
export default connect(mapStateToProps)(Header);