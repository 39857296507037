//Action Creator

//Named export
// export const ProductList = (product) => {
//   //Return an action
//   return {
//     type: "PRODUCT_LIST",
//     payload: product,
//   };
// };

export const ProductListPagination = (pageId) => {
  //Return an action
  return {
    type: "PRODUCT_LIST_PAGINATION",
    payload: pageId,
  };
};

export * from './alert.actions';
export * from './user.actions';
export * from './home.actions'
export * from './product.actions';
export * from './shop.actions';
export * from './contact.actions';