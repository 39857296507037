import React, {Suspense, Component } from 'react';
import {connect} from 'react-redux';
// import { homeActions } from 'store/actions/home.actions';
import './Home.css';
//import Features from "../../components/Features/Features";
//import Slider from "../../components/Slider/Slider";

//import React, { Suspense } from 'react';

const Features = React.lazy(() => import('../../components/Features/Features'));
const Slider = React.lazy(() => import('../../components/Slider/Slider'));
const Promotions = React.lazy(() => import('../../components/Promotions/Promotions'))
// const Whoweare = React.lazy(() => import('../../components/Whoweare/Whoweare'))
const Newsletter = React.lazy(() => import('../../components/Newsletter/Newsletter'))
// const Brands = React.lazy(() => import('../../components/Brands/Brands'))
class Home extends Component {
  constructor(props){
    super(props);
    document.title = "Realtime-7 | Home";
    
  }  

  // componentDidUpdate(){
  //   if(this.props.users.loggedIn){
  //     this.props.history.push("/");
  //   }
  // }
  // componentWillUnmount(){}
  // componentDidMount(){} 
  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Slider/>
          <Features/>
          <Promotions/>
          {/* <Whoweare/> */}
          <Newsletter/>
          {/* <Brands/> */}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps=(state)=>{
  return state;
}
export default connect(mapStateToProps)(Home);