import React from 'react';
import { render } from 'react-three-fiber';
import spinner from '../../../src/assets/images/loader.gif';
import './Loader.css'
export default class Loader extends React.Component {
  render() {
    return (
      <div className="loader-bg">
          <img
          src={spinner}
          className="loader"
          alt="Loading..."
        />
      </div>
    );
  }
}